import React, { useEffect, useState } from "react";
import useQuery from "../../helpers/hooks/useQuery";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";

import RedeemInviteFormComponent from "./RedeemInviteFormComponent";

import { InviteDetailResponse } from "../../model/invite/InviteDetailResponse";
import FetchInviteDetailsComponent from "./FetchInviteDetailsComponent";

const RedeemInviteComponent: React.VoidFunctionComponent = () => {
  let query = useQuery();
  let queryInviteToken = query.get("token");
  const [inviteToken, setInviteToken] = useState<string>("");

  const [inviteDetails, setInviteDetails] = useState<
    InviteDetailResponse | undefined
  >(undefined);

  const hasAllDetails =
    inviteDetails !== undefined &&
    Object.values(inviteDetails).every((x) => x !== null || x !== "");

  useEffect(() => {
    if (
      queryInviteToken &&
      /{?\w{8}-?\w{4}-?\w{4}-?\w{4}-?\w{12}}?/i.test(queryInviteToken)
    ) {
      setInviteToken(queryInviteToken);
    }
  }, [queryInviteToken]);

  return (
    <>
      <Typography variant="h4" sx={{ mt: 8 }}>
        <strong>Redeem Invite</strong>
      </Typography>
      <Box
        sx={{
          mt: 2,
          mb: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {hasAllDetails ? (
          <RedeemInviteFormComponent data={inviteDetails} />
        ) : (
          <FetchInviteDetailsComponent
            data={inviteToken}
            setResponse={setInviteDetails}
          />
        )}
        {/*         <Link href="/" variant="body2" sx={{ mt: 1 }}>
          click here to return home
        </Link> */}
      </Box>
    </>
  );
};

export default RedeemInviteComponent;
