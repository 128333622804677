import * as React from "react";

interface Props {
  leftAverageSwing: number;
  leftAverageStance: number;
  rightAverageSwing: number;
  rightAverageStance: number;
  doubleSupport: number;
  measurement: string;
}

export const AverageSwingAverageStancePanel: React.FC<Props> = ({
  leftAverageSwing,
  leftAverageStance,
  rightAverageSwing,
  rightAverageStance,
  doubleSupport,
  measurement,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="725"
      height="200"
      viewBox="0 0 725 200"
    >
      <g
        id="Group_2678"
        data-name="Group 2678"
        transform="translate(-1265 -7070)"
      >
        <text
          id="_Expressed_as_of_the_gait_cycle_time"
          data-name="**Expressed as % of the gait cycle time"
          transform="translate(1711 7252)"
          fill="#101820"
          fontSize="14"
          fontFamily="Montserrat-Regular, Montserrat"
        >
          <tspan x="0" y="14">
            *Expressed as % of the gait cycle time
          </tspan>
        </text>
        <rect
          id="Rectangle_65"
          data-name="Rectangle 65"
          width="571"
          height="40"
          rx="20"
          transform="translate(1412 7070)"
          fill="#c8e4e7"
        />
        <rect
          id="Rectangle_66"
          data-name="Rectangle 66"
          width="571"
          height="40"
          rx="20"
          transform="translate(1412 7198)"
          fill="#c8e4e7"
        />
        <rect
          id="Rectangle_67"
          data-name="Rectangle 67"
          width="132"
          height="40"
          transform="translate(1618 7134)"
          fill="#28666c"
        />
        <text
          id="Left_foot"
          data-name="Left foot"
          transform="translate(1265 7080)"
          fill="#2b2b2b"
          fontSize="16"
          fontFamily="Montserrat-SemiBold, Montserrat"
          fontWeight="600"
        >
          <tspan x="0" y="15">
            Left foot
          </tspan>
        </text>
        <text
          id="Right_foot"
          data-name="Right foot"
          transform="translate(1265 7207)"
          fill="#2b2b2b"
          fontSize="16"
          fontFamily="Montserrat-SemiBold, Montserrat"
          fontWeight="600"
        >
          <tspan x="0" y="15">
            Right foot
          </tspan>
        </text>
        <text
          id="Double_support"
          data-name="Double support"
          transform="translate(1265 7142)"
          fill="#2b2b2b"
          fontSize="16"
          fontFamily="Montserrat-SemiBold, Montserrat"
          fontWeight="600"
        >
          <tspan x="0" y="15">
            Double{" "}
          </tspan>
          <tspan x="0" y="35">
            support
          </tspan>
        </text>
        <path
          id="Rectangle_69"
          data-name="Rectangle 69"
          d="M0,0H345a20,20,0,0,1,20,20v0a20,20,0,0,1-20,20H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
          transform="translate(1618 7070)"
          fill="#28666c"
        />
        <path
          id="Rectangle_70"
          data-name="Rectangle 70"
          d="M20,0H338a0,0,0,0,1,0,0V40a0,0,0,0,1,0,0H20A20,20,0,0,1,0,20v0A20,20,0,0,1,20,0Z"
          transform="translate(1412 7198)"
          fill="#28666c"
        />
        <text
          id="_37.86_Swing"
          data-name="37.86% Swing"
          transform="translate(1520 7079)"
          fill="#28666c"
          fontSize="19"
          fontFamily="Montserrat-Bold, Montserrat"
          fontWeight="700"
        >
          <tspan x="-67.697" y="18">
            {leftAverageSwing}
            {measurement} Swing
          </tspan>
        </text>
        <text
          id="_37.86_Swing-2"
          data-name="37.86% Swing"
          transform="translate(1870 7207)"
          fill="#28666c"
          fontSize="19"
          fontFamily="Montserrat-Bold, Montserrat"
          fontWeight="700"
        >
          <tspan x="-67.697" y="18">
            {rightAverageSwing}
            {measurement} Swing
          </tspan>
        </text>
        <text
          id="_62.14_Stance"
          data-name="62.14% Stance"
          transform="translate(1830 7079)"
          fill="#fff"
          fontSize="19"
          fontFamily="Montserrat-Bold, Montserrat"
          fontWeight="700"
        >
          <tspan x="-69.312" y="18">
            {leftAverageStance}
            {measurement} Stance
          </tspan>
        </text>
        <text
          id="_62.14_Stance-2"
          data-name="62.14% Stance"
          transform="translate(1520 7207)"
          fill="#fff"
          fontSize="19"
          fontFamily="Montserrat-Bold, Montserrat"
          fontWeight="700"
        >
          <tspan x="-69.312" y="18">
            {rightAverageStance}
            {measurement} Stance
          </tspan>
        </text>
        <text
          id="_24.28_"
          data-name="24.28%"
          transform="translate(1700 7143)"
          fill="#fff"
          fontSize="19"
          fontFamily="Montserrat-Bold, Montserrat"
          fontWeight="700"
        >
          <tspan x="-34.941" y="18">
            {doubleSupport}
            {measurement}
          </tspan>
        </text>
        <line
          id="Line_33"
          data-name="Line 33"
          y2="24"
          transform="translate(1618 7110)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeWidth="1"
          strokeDasharray="4 4"
        />
        <line
          id="Line_78"
          data-name="Line 78"
          y2="24"
          transform="translate(1618 7174)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeWidth="1"
          strokeDasharray="4 4"
        />
        <line
          id="Line_76"
          data-name="Line 76"
          y2="24"
          transform="translate(1750 7110)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeWidth="1"
          strokeDasharray="4 4"
        />
        <line
          id="Line_77"
          data-name="Line 77"
          y2="24"
          transform="translate(1750 7174)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeWidth="1"
          strokeDasharray="4 4"
        />
      </g>
    </svg>
  );
};
