import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Patient } from "../../../model/patient/patient";
import { ActiveType } from "../../../model/activeType";
import BackButton from "../../../components/BackButton";
import PatientDetailsHeader from "../../../components/Account/PatientDetailsHeader";
import MultiDatePicker from "../../../components/documented/MultiDatePicker/MultiDatePicker";
import { useSelector } from "react-redux";
import { useAppThunkDispatch } from "../../../redux/configureStore";
import { DateRange } from "../../../model/dateRange";
import {
  convertDate,
  getDateRanges,
  getEnumKeyByEnumValue,
} from "../../../helpers/utilityFunctions/utilityFunctions";
import {
  activeTestDates,
  activeTestSelectedDate,
  latestOverviewData,
} from "../../../redux/selectors/activeSelectors";
import {
  clearActiveTestDates,
  clearActiveTestData,
  getActiveTestData,
  getActiveTestDatesByRange,
  setActiveTestDate,
} from "../../../redux/actions/activeActions";
import { selectedTestDateTime } from "../../../redux/selectors/patientSelectors";
import { ActiveTestOverview } from "../../../model/active/activeTestOverview";

interface Props {
  patientId?: string;
  testType: ActiveType;
}

export function getActiveTestMetricDate(
  activeType: ActiveType,
  overview: ActiveTestOverview
): string | undefined {
  switch (activeType) {
    case ActiveType._TUG:
      return overview.latestTUG.date;
    case ActiveType._6MWT:
      return overview.latest6MWT.date;
    case ActiveType._10MWT:
      return overview.latest10MWT.date;
    case ActiveType._BT:
      return overview.latestBT.date;
    case ActiveType._CTEST:
      return overview.latestCustom.dateTime;
    default:
      return undefined; // Handle unknown ActiveType values
  }
}

export const TestDetailHeader: React.FC<Props> = ({ patientId, testType }) => {
  const dispatch = useAppThunkDispatch();
  const dates = useSelector(activeTestDates);
  const savedDate = useSelector(activeTestSelectedDate);

  const latestOverViewData = useSelector(latestOverviewData);
  const testDateTime = useSelector(selectedTestDateTime);
  const [selectedDate, setSelectedDate] = React.useState<Date>();
  const [selectedMonth, setSelectedMonth] = React.useState<Date>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedPatient, setSelectedPatient] = useState<Patient>();

  const processedType = getEnumKeyByEnumValue(ActiveType, testType)!.substring(
    1
  );
  const processedTypeWithUnderscore = getEnumKeyByEnumValue(
    ActiveType,
    testType
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //Derived state
  const testSelected = testDateTime !== "";

  useEffect(() => {
    const latestDate = savedDate
      ? savedDate
      : latestOverViewData
      ? getActiveTestMetricDate(testType, latestOverViewData)
      : undefined;

    const date = latestDate ? new Date(latestDate) : new Date();
    setSelectedDate(date);
    setSelectedMonth(date);

    return () => {
      dispatch(clearActiveTestDates());
      dispatch(clearActiveTestData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (testSelected) return;
    if (selectedMonth === undefined) return;
    setIsLoading(true);
    const dateRange: DateRange = getDateRanges(selectedMonth, true);
    dispatch(
      getActiveTestDatesByRange(
        patientId || "",
        processedType,
        dateRange.startDate,
        dateRange.endDate
      )
    ).then(() => {
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth]);

  useEffect(() => {
    if (selectedDate === undefined || dates.length === 0) return;

    //save the date selected
    dispatch(setActiveTestDate(selectedDate));

    const formattedSelectedDate = selectedDate.toISOString().split("T")[0];

    if (
      dates.some((date: Date) => {
        const dateString = date.toString().split("T")[0];
        return dateString === formattedSelectedDate;
      })
    ) {
      dispatch(
        getActiveTestData(
          patientId || "",
          processedTypeWithUnderscore || "",
          convertDate(selectedDate)
        )
      );
    } else {
      dispatch(clearActiveTestData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, dates]);

  return (
    <Box>
      <PatientDetailsHeader selectedPatient={selectedPatient} />
      <BackButton />
      <Box sx={{ display: "flex", flexDirection: "row", mt: 1 }}>
        <Typography variant="h5" sx={{ mt: 1, pr: 2 }}>
          <strong>
            {(() => {
              switch (testType) {
                case ActiveType._10MWT:
                  return "10 Metre Walk Test";
                case ActiveType._TUG:
                  return "Timed Up and Go Test";
                case ActiveType._CTEST:
                  return "Custom Test";
                default:
                  return "Active Test";
              }
            })()}
          </strong>
        </Typography>

        {testSelected ? (
          <Typography variant="h5" sx={{ mt: 1, pr: 2 }}>
            {new Date(testDateTime).toLocaleString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            })}
          </Typography>
        ) : (
          <MultiDatePicker
            dates={dates}
            selectedDate={selectedDate}
            isLoading={isLoading}
            setSelectedDate={setSelectedDate}
            setSelectedMonth={setSelectedMonth}
          />
        )}
      </Box>
    </Box>
  );
};

export default TestDetailHeader;
