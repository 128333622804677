import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Box, CardContent } from "@mui/material";
import { ParameterCard } from "../../../documented/ParameterCard";
import { AverageVariabilityCardData } from "./averageVariabilityCardData";
import { isEmpty } from "../../../../helpers/utilityFunctions/utilityFunctions";

interface AverageVariabilityCardProps {
  data?: AverageVariabilityCardData;
  onClick: (arg: string) => void;
}

const CardDetailTypography = styled(Typography)(() => ({
  fontSize: 14,
  padding: "0px 0px 0px 16px !important",
}));

const AverageVariabilityCardMedia = styled(CardContent)(() => ({
  display: "flex !important",
  flexDirection: "column",
  flexGrow: "1 !important",
}));

const PanelDisplayBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
}));

const AverageVariabilityCard: React.FC<AverageVariabilityCardProps> = ({
  data,
  onClick,
}: AverageVariabilityCardProps) => {
  return (
    <ParameterCard
      title="Average Variability"
      iconName="TotalSteps"
      popoverContent="A variability value of 0 indicates consistent gait, and is the most positive clinical outcome. Increased variability is associated with reduced performance"
      onClick={() => onClick("_10MWT")}
      enabled={!isEmpty(data)}
    >
      <CardDetailTypography>
        A measure of variation between the duration of the gait cycle of each
        foot
      </CardDetailTypography>
      <AverageVariabilityCardMedia>
        {data && data.value ? (
          <PanelDisplayBox>
            <Typography sx={{ fontWeight: "bold", fontSize: 57 }}>
              {data ? Number(data.value) : 0}
            </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: 14 }}>
              {data ? data.unit : "%"}
            </Typography>
          </PanelDisplayBox>
        ) : null}
      </AverageVariabilityCardMedia>
    </ParameterCard>
  );
};

export default AverageVariabilityCard;
