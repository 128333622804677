import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { Outlet } from "react-router";

import NavigationBar from "./NavigationBar";
import { useAppThunkDispatch } from "../../redux/configureStore";
import { getSettings } from "../../redux/actions/settingsActions";
import { selectAccountId } from "../../redux/selectors/accountSelectors";

const DashboardPage: React.VoidFunctionComponent = () => {
  const dispatch = useAppThunkDispatch();
  const accoundGuid = useSelector(selectAccountId);

  useEffect(() => {
    //load the company wide settings
    dispatch(getSettings(accoundGuid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item xs={3}>
        <NavigationBar />
      </Grid>
      <Grid item sx={{ mt: "64px", ml: "240px" }} xs={true}>
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default DashboardPage;
