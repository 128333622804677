import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { Alert, Box, Snackbar } from "@mui/material";

import { toastListeners } from "../../services/ToastService/toastService";

type Severity = "error" | "info" | "success" | "warning";

interface Snack {
  message: string;
  key: number;
  severity: Severity;
}

interface ToastContextType {
  addToast: (message: string, severity: Severity) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const ToastProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [snackPack, setSnackPack] = useState<Snack[]>([]);

  const addToast = (message: string, severity: Severity) => {
    setSnackPack((prev) => [
      ...prev,
      { message, key: new Date().getTime(), severity },
    ]);
  };

  useEffect(() => {
    const listener = (message: string, severity: Severity) => {
      addToast(message, severity);
    };
    toastListeners.addListener(listener);
    return () => {
      toastListeners.removeListener(listener);
    };
  }, []);

  const handleClose =
    (key: number) =>
    (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      setSnackPack((prev) => prev.filter((snack) => snack.key !== key));
    };

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          right: 0,
          display: "flex",
          flexDirection: "column-reverse",
          alignItems: "flex-end",
          zIndex: 1300,
        }}
      >
        {snackPack.map((snack) => (
          <Snackbar
            key={snack.key}
            open={true}
            autoHideDuration={6000}
            onClose={handleClose(snack.key)}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            sx={{ position: "relative", marginBottom: "8px" }}
          >
            <Alert
              onClose={handleClose(snack.key)}
              severity={snack.severity}
              sx={{ width: "100%" }}
            >
              {snack.message}
            </Alert>
          </Snackbar>
        ))}
      </Box>
    </ToastContext.Provider>
  );
};

export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};
