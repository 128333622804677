import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { LoadingButton } from "@mui/lab";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Box, Grid } from "@mui/material";
import { inviteService } from "../../services/inviteService";
import { InviteDetailResponse } from "../../model/invite/InviteDetailResponse";
import { FORM_STATUS } from "../../model/formStatus";
import AlmostDoneComponent from "./AlmostDoneComponent";

const defaultState = {
  invitationCodeGuid: "",
  firstName: "",
  lastName: "",
  speciality: "",
  email: "",
  password: "",
  confirmPassword: "",
};

interface Props {
  data?: InviteDetailResponse;
}

export const RedeemInviteFormComponent: React.FC<Props> = ({ data }) => {
  const [formData, setFormData] = useState(defaultState);
  const [status, setStatus] = useState(FORM_STATUS.IDLE);
  const [touched, setTouched] = useState(defaultState);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlmostDone, setShowAlmostDone] = useState(false);

  useEffect(() => {
    if (data) {
      setFormData((curFormData) => {
        return {
          ...curFormData,
          invitationCodeGuid: data.invitationCodeGuid,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          speciality: data.speciality,
        };
      });
      getErrors(formData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function handleChange(e: any) {
    e.persist();
    setFormData((curFormData) => {
      return {
        ...curFormData,
        [e.target.id]: e.target.value,
      };
    });
  }

  function handleBlur(e: any) {
    e.persist();
    setTouched((cur: any) => {
      return { ...cur, [e.target.id]: true };
    });
  }

  function getErrors(formData: any) {
    const result = {
      invitationCodeGuid: "",
      firstName: "",
      lastName: "",
      speciality: "",
      email: "",
      password: "",
      confirmPassword: "",
    };
    if (status === FORM_STATUS.COMPLETED) return result;

    if (!formData.invitationCodeGuid) {
      result.invitationCodeGuid = "Please enter an invite code.";
    } else if (
      !/{?\w{8}-?\w{4}-?\w{4}-?\w{4}-?\w{12}}?/i.test(
        formData.invitationCodeGuid
      )
    ) {
      result.invitationCodeGuid = "Your invitation code is Invalid.";
    }

    if (!formData.firstName) result.firstName = "Please enter your first name.";
    if (!formData.lastName) result.lastName = "Please enter your last name.";
    if (!formData.speciality)
      result.speciality = "Please enter your speciality.";

    if (!formData.email) {
      result.email = "Please enter your email address.";
    } else if (!/^.+@.+$/i.test(formData.email)) {
      result.email = "Your email address is Invalid.";
    }

    if (!formData.password) {
      result.password = "Please enter a password.";
    }

    if (!formData.confirmPassword)
      result.confirmPassword = "Please confirm your password.";

    if (formData.password !== formData.confirmPassword) {
      result.password = "your passwords does not match";
      result.confirmPassword = "your passwords do not match";
    }

    if (
      !/^(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(
        formData.password
      )
    ) {
      result.password =
        "password must contain 8-40 characters and at least 1 digit, 1 lowercase letter, 1 uppercase letter and a special character";
    }

    return result;
  }

  //Derived state
  const errors = getErrors(formData);
  const isValid = Object.values(errors).every((x) => x === null || x === "");

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setStatus(FORM_STATUS.SUBMITTING);
    if (isValid) {
      setIsLoading(true);
      try {
        inviteService
          .redeemInvite(
            formData.invitationCodeGuid,
            formData.firstName,
            formData.lastName,
            formData.email,
            formData.speciality,
            formData.password,
            formData.confirmPassword
          )

          .then((response: any) => {
            setStatus(FORM_STATUS.COMPLETED);
            setFormData(defaultState);
            setIsLoading(false);
            setShowAlmostDone(true);
          });
      } catch (e) {
        //This is not the exception you are looking for
        setIsLoading(false);
      }
    } else {
      setStatus(FORM_STATUS.SUBMITTED);
    }
  };

  return (
    <>
      {showAlmostDone ? (
        <AlmostDoneComponent />
      ) : (
        <>
          <Box component="form" sx={{ mt: 1 }} onSubmit={onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="invite-code"
                  name="invitationCodeGuid"
                  required
                  fullWidth
                  id="invitationCodeGuid"
                  label="Invite Code"
                  value={formData.invitationCodeGuid}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={status === FORM_STATUS.COMPLETED ? true : false}
                  helperText={
                    (touched.invitationCodeGuid ||
                      status === FORM_STATUS.SUBMITTED) &&
                    errors.invitationCodeGuid
                  }
                  error={
                    (touched.invitationCodeGuid !== "" ||
                      status === FORM_STATUS.SUBMITTED) &&
                    errors.invitationCodeGuid !== ""
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  value={formData.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={status === FORM_STATUS.COMPLETED ? true : false}
                  helperText={
                    (touched.firstName || status === FORM_STATUS.SUBMITTED) &&
                    errors.firstName
                  }
                  error={
                    (touched.firstName !== "" ||
                      status === FORM_STATUS.SUBMITTED) &&
                    errors.firstName !== ""
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  value={formData.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={status === FORM_STATUS.COMPLETED ? true : false}
                  helperText={
                    (touched.lastName || status === FORM_STATUS.SUBMITTED) &&
                    errors.lastName
                  }
                  error={
                    (touched.lastName !== "" ||
                      status === FORM_STATUS.SUBMITTED) &&
                    errors.lastName !== ""
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  autoComplete="speciality"
                  name="speciality"
                  required
                  fullWidth
                  id="speciality"
                  label="Speciality"
                  value={formData.speciality}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={status === FORM_STATUS.COMPLETED ? true : false}
                  helperText={
                    (touched.speciality || status === FORM_STATUS.SUBMITTED) &&
                    errors.speciality
                  }
                  error={
                    (touched.speciality !== "" ||
                      status === FORM_STATUS.SUBMITTED) &&
                    errors.speciality !== ""
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={formData.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={status === FORM_STATUS.COMPLETED ? true : false}
                  helperText={
                    (touched.email || status === FORM_STATUS.SUBMITTED) &&
                    errors.email
                  }
                  error={
                    (touched.email !== "" ||
                      status === FORM_STATUS.SUBMITTED) &&
                    errors.email !== ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  value={formData.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={status === FORM_STATUS.COMPLETED ? true : false}
                  helperText={
                    (touched.password || status === FORM_STATUS.SUBMITTED) &&
                    errors.password
                  }
                  error={
                    (touched.password !== "" ||
                      status === FORM_STATUS.SUBMITTED) &&
                    errors.password !== ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  id="confirmPassword"
                  autoComplete="confirm-new-password"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={status === FORM_STATUS.COMPLETED ? true : false}
                  helperText={
                    (touched.confirmPassword ||
                      status === FORM_STATUS.SUBMITTED) &&
                    errors.confirmPassword
                  }
                  error={
                    (touched.confirmPassword !== "" ||
                      status === FORM_STATUS.SUBMITTED) &&
                    errors.confirmPassword !== ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox required name="acceptTerms" color="primary" />
                  }
                  label="I accept the terms and conditions."
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={status === FORM_STATUS.COMPLETED ? true : false}
                />
              </Grid>
            </Grid>

            <LoadingButton
              type="submit"
              loading={isLoading}
              variant="contained"
              color="primary"
              //className={classes.submit}
              sx={{ mt: 3 }}
              disabled={status === FORM_STATUS.COMPLETED ? true : false}
            >
              Redeem
            </LoadingButton>
          </Box>
        </>
      )}
    </>
  );
};

export default RedeemInviteFormComponent;
