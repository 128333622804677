import React, { ReactElement } from "react";
import { Outlet } from "react-router-dom";
import { makeStyles, useTheme } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import IconLoader from "../../components/IconLoader";
import background from "../../assets/images/teal-stethoscope-on-wooden-table.png";
import { Typography } from "@mui/material";
import { useClientTheme } from "../../helpers/hooks/useClientTheme";

const useStyles = makeStyles((theme: any) => ({
  container: {
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100%",
  },
}));

interface HomePageProps {}

const HomePage: React.FC<HomePageProps> = (): ReactElement => {
  const classes = useStyles();
  const theme = useTheme();
  const { isDefaultTheme } = useClientTheme();
  const LogoComponent = theme.images.mainLogo;

  return (
    <Grid container sx={{ height: "100vh", overflow: "hidden" }}>
      <Grid
        style={{ maxHeight: "100vh", overflow: "auto" }}
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
      >
        <Box
          sx={{
            my: 4,
            mx: 10,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {isDefaultTheme ? null : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100px",
              }}
            >
              <LogoComponent />
            </Box>
          )}

          <Box sx={isDefaultTheme ? { mt: 8 } : { mt: 0 }}>
            <Outlet />
          </Box>

          <Box
            sx={{
              mt: 3,
            }}
          >
            {isDefaultTheme ? null : (
              <Typography
                sx={{
                  mb: 1,
                }}
              >
                Powered By
              </Typography>
            )}

            <IconLoader name="WalkWithPathLogo" />
          </Box>
        </Box>
      </Grid>
      <Grid className={classes.container} item xs={false} sm={4} md={7} />
    </Grid>
  );
};

export default HomePage;
