import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, IconButton, Theme } from "@mui/material";
import { RadialIndicator } from "../documented/RadialIndicator";
import IconLoader from "../IconLoader";

import InfoIcon from "@mui/icons-material/Info";
import { ActiveTestMetric } from "../../model/active/activeTestMetric";

const useStyles = makeStyles<Theme, DetailStyleProps>((theme: Theme) => ({
  card: {
    height: "381px",
    width: "254px",
    display: "flex",
    position: "relative",
    flexDirection: "column",
    border: "2px solid",
    borderRadius: "10px !important",
    borderColor: ({ showingInfo }) =>
      `${showingInfo ? theme.palette.primary.main : "black"}`,
  },
  cardMedia: {
    display: "flex !important",
    flexDirection: "column",
    flexGrow: "1 !important",
    alignItems: "center",
    justifyContent: "center",
  },
  cardContent: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 0px 0px 16px !important",
    margin: "40px 0px 0px 0px",
  },
  infoIcon: {
    height: 28,
    width: 28,
    color: theme.palette.primary.main,
  },
  expandedInfoIcon: {
    color: "white",
  },
  popOver: {
    position: "absolute",
    zIndex: 2,
    top: 0,
    right: 0,
    height: 0,
    width: 0,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "6px",
    transform: "rotate(0deg)",
    transition: "all 250ms ease-in-out",
  },
  popOverEntering: {
    height: "381px",
    width: "254px",
    transition: "all 250ms ease-in-out",
    transform: "rotate(0deg)",
  },
  popOverContent: {
    display: "flex",
    flexDirection: "row",
    margin: "40px 0px 0px 16px",
    color: "white",
  },
}));

interface DetailStyleProps {
  showingInfo: boolean | undefined;
}

interface IDetailCardProps {
  title: string;
  icon: string;
  popoverContent: string;
  content?: string | ActiveTestMetric;
  onClick: (arg: string) => void;
}

const DetailCard: React.FC<IDetailCardProps> = ({
  title,
  icon,
  popoverContent,
  content,
  onClick,
}: IDetailCardProps) => {
  const [showInfo, setShowInfo] = useState<boolean>(false);

  const styleProps: DetailStyleProps = {
    showingInfo: showInfo,
  };

  const classes = useStyles(styleProps);

  const CardHeader = () => {
    return (
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "row",
          justifyContent: "right",
        }}
      >
        <Box
          className={clsx(classes.popOver, {
            [classes.popOverEntering]: showInfo,
          })}
        >
          <Box className={classes.popOverContent}>
            <IconLoader name={icon} fill="white" />
            <Typography
              sx={{
                ml: 2,
              }}
              variant="h5"
              component="h2"
            >
              <strong>{title}</strong>
            </Typography>
          </Box>
          <Typography
            sx={{
              mt: 2,
              ml: 2,
              mr: 2,
              color: "white",
            }}
            variant="body1"
          >
            {popoverContent}
          </Typography>
        </Box>

        <IconButton
          sx={{ ml: 1, position: "absolute", zIndex: 3 }}
          onClick={() => setShowInfo(!showInfo)}
        >
          <InfoIcon
            className={clsx(classes.infoIcon, {
              [classes.expandedInfoIcon]: showInfo,
            })}
          />
        </IconButton>
      </Box>
    );
  };

  let InnerContent: React.ReactNode;
  if (typeof content === "string") {
    InnerContent = (
      <Typography
        sx={{
          wordWrap: "break-word",
        }}
        align="center"
      >
        {content}
      </Typography>
    );
  } else {
    InnerContent = (
      <>
        <Box
          sx={{
            mt: 2,
          }}
        >
          <RadialIndicator
            size={200}
            minValue={0}
            maxValue={2}
            range={[0, 1, 2]}
            startAngle={40}
            endAngle={320}
            angleType={{
              direction: "cw",
              axis: "-y",
            }}
            value={content?.overview ? content.overview : 0}
            unit={content?.unit ? content.unit : ""}
          />
        </Box>
        <Typography sx={{ textAlign: "center" }}>
          Most recent test:
          {content?.date
            ? new Date(content?.date).toLocaleDateString()
            : "no date specified"}
        </Typography>
      </>
    );
  }

  return (
    <Card className={classes.card}>
      {CardHeader()}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          cursor: "pointer",
        }}
        onClick={() => onClick("_10MWT")}
      >
        <CardContent className={classes.cardContent}>
          <IconLoader name={icon} />
          <Typography
            sx={{
              ml: 2,
            }}
            variant="h5"
            component="h2"
          >
            <strong>{title}</strong>
          </Typography>
        </CardContent>
        <CardMedia className={classes.cardMedia}>{InnerContent}</CardMedia>
      </Box>
    </Card>
  );
};

export default DetailCard;
