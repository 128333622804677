import React from "react";
import { Avatar, Box, Grid, ListItemButton, Typography } from "@mui/material";
import { Alert } from "../../model/passive/alertMoment";
import { PassiveParameterType } from "../../model/passive/passiveParameterType";
import { AlertPressureType } from "../../model/passive/alertPressureType";
import {
  absDifference,
  applyOffsetToTimestamp,
  toFixed,
} from "../../helpers/utilityFunctions/utilityFunctions";
import WarningIcon from "@mui/icons-material/Warning";
import { useClientTheme } from "../../helpers/hooks/useClientTheme";

interface Props {
  type: string;
  data: Alert;
  index: any;
  selected: boolean;
  onClick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => void;
}

const RenderSustainedForText = (type: string) => {
  switch (type) {
    case "constant15min":
      return "15 mins";
    case "constant30min":
      return "30 mins";
    case "constant60min":
      return "60 mins";
    default:
      return "0 Mins";
  }
};

const RenderSustainedFor = (
  type: PassiveParameterType,
  pressureAlertTypeId: number
) => {
  const timeType = AlertPressureType[pressureAlertTypeId];

  return type === PassiveParameterType.AlarmPressure &&
    timeType.includes("constant") ? (
    <Typography>
      Sustained for: <strong>{RenderSustainedForText(timeType)}</strong>
    </Typography>
  ) : null;
};

const RenderTypeDetails = (
  type: string,
  selected: boolean,
  data: Alert,
  metrics: any = undefined
) => {
  switch (type) {
    case PassiveParameterType.AlarmPressure:
      return (
        <>
          <Typography
          // sx={{
          //   color: selected
          //     ? (theme) => theme.palette.common.white
          //     : (theme) => theme.palette.common.black,
          // }}
          >
            {(data.pressureAlertTypeId && data.pressureAlertTypeId === 1) ||
            data.pressureAlertTypeId === 2
              ? "Peak"
              : "Constant"}{" "}
            pressure: <strong>{parseFloat(toFixed(data.bars, 3))} Bars</strong>
          </Typography>
          {RenderSustainedFor(type, data.pressureAlertTypeId!)}
        </>
      );
    case PassiveParameterType.AlarmTemperature:
      return (
        <>
          <Typography
          // sx={{
          //   color: selected
          //     ? (theme) => theme.palette.common.white
          //     : (theme) => theme.palette.common.black,
          // }}
          >
            Temperature Difference:{" "}
            <strong>
              {absDifference(
                JSON.parse(data.sensorDataLeft),
                JSON.parse(data.sensorDataRight)
              )}
              {metrics && metrics.temperature.symbol}
            </strong>
          </Typography>
        </>
      );
    default:
      break;
  }
};

const MomentCard: React.FC<Props> = ({
  type,
  data,
  index,
  selected,
  onClick,
}) => {
  const { theme } = useClientTheme();

  const RenderAlertColor = (alertLevel: number) => {
    switch (alertLevel) {
      case 1:
        return theme.palette.alertLevel1.main;
      case 2:
        return theme.palette.alertLevel2.main;
      case 3:
        return theme.palette.alertLevel3.main;
      default:
        return theme.palette.alertLevel3.main;
    }
  };

  return (
    <ListItemButton
      key={index}
      selected={selected}
      onClick={(event) => onClick(event, index)}
      sx={{
        display: "flex",
        flexDirection: "row",
        padding: "4px 16px 4px 2px",
        borderRadius: "10px",
        border: `2px solid ${
          selected
            ? `rgba(255, 255, 255, 1)`
            : RenderAlertColor(data.alertLevel)
        }`,
        "&.Mui-selected": {
          backgroundColor: RenderAlertColor(data.alertLevel),
          border: `2px solid ${RenderAlertColor(data.alertLevel)}`,
          //color: theme.palette.common.white,
          "& .MuiListItemIcon-root": {
            //color: theme.palette.common.white,
          },
        },
        "&.Mui-selected:hover": {
          backgroundColor: RenderAlertColor(data.alertLevel),
          border: `2px solid ${RenderAlertColor(data.alertLevel)}`,
          //color: RenderAlertColor(data.alertLevel),
          "& .MuiListItemIcon-root": {
            //color: theme.palette.common.white,
          },
        },
        "&:hover": {
          backgroundColor: theme.palette.grey[300],
          //color: theme.palette.common.white,
          "& .MuiListItemIcon-root": {
            //color: theme.palette.common.white,
          },
        },
      }}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          borderRadius: "8px 0px 0px 8px",
          borderLeft: `10px solid ${
            selected
              ? `rgba(255, 255, 255, 1)`
              : RenderAlertColor(data.alertLevel)
          }`,
        }}
      >
        <Grid item xs={1}></Grid>
        <Grid item xs={9}>
          <Box sx={{ ml: 2, mt: 1, mb: 1 }}>
            <Typography
              variant="body1"
              // sx={{
              //   color: selected
              //     ? (theme) => theme.palette.common.white
              //     : (theme) => theme.palette.common.black,
              // }}
            >
              {applyOffsetToTimestamp(data.dateTime, data.utcTimeZone)}
            </Typography>
            <Typography
            // sx={{
            //   color: selected
            //     ? (theme) => theme.palette.common.white
            //     : (theme) => theme.palette.common.black,
            // }}
            >
              Level <strong>{data.alertLevel}</strong>
            </Typography>
            {RenderTypeDetails(type, selected, data, theme.metrics)}
          </Box>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          xs={2}
        >
          <Avatar
            sx={{
              backgroundColor: (theme) => theme.palette.common.white,
              color: (theme) => theme.palette.info.main,
            }}
          >
            <WarningIcon
              sx={{
                color: RenderAlertColor(data.alertLevel),
              }}
            />
          </Avatar>
        </Grid>
      </Grid>
    </ListItemButton>
  );
};

export default MomentCard;
