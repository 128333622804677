import React from "react";
import { useParams } from "react-router-dom";
import { ActiveType } from "../../../model/activeType";
import TestDetailHeader from "./TestDetailHeader";
import TimedUpAndGo from "./TimedUpAndGo";
import { Box } from "@mui/material";
import Custom from "./Custom";
import TenMetreWalk from "./TenMetreWalk";

const TestDetail: React.FC = () => {
  const { patientId, testType } = useParams();
  const typeString = testType as keyof typeof ActiveType;
  const type: ActiveType = ActiveType[typeString];

  return (
    <Box>
      <TestDetailHeader patientId={patientId} testType={type} />
      {(() => {
        switch (type) {
          case ActiveType._10MWT:
            return <TenMetreWalk />;
          case ActiveType._TUG:
            return <TimedUpAndGo />;
          case ActiveType._CTEST:
            return <Custom />;
          default:
            return <>Type page not found</>;
        }
      })()}
    </Box>
  );
};

export default TestDetail;
