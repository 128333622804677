import * as React from "react";
import LockResetIcon from "@mui/icons-material/LockReset";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  Box,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import NoData from "../../../components/NoData";
import PathCircularProgress from "../../../components/PathCircularProgress";
import accountService from "../../../services/AccountService";
import { ThreeDotsMenu } from "../../../components/documented/ThreeDotsMenu";
import { selectAccountId } from "../../../redux/selectors/accountSelectors";
import { useAppThunkDispatch } from "../../../redux/configureStore";
import { selectHcpAccounts } from "../../../redux/selectors/adminSelectors";
import {
  changeAccountRole,
  getAllHcpAccounts,
} from "../../../redux/actions/adminActions";
import { NewRole } from "../../../model/admin/newRole";
import { Role } from "../../../model/account/role";
import { PathAccount } from "../../../model/account/pathAccount";
import {
  getComparator,
  Order,
  StyledTableCell,
  StyledTableRow,
} from "../TableComponents";

const TableDetailContainer = styled(Box)(() => ({
  boxSizing: "border-box",
  height: "fit-content",
  width: "100%",
  padding: "10px 40px 10px 20px",
}));

export interface HeadCell {
  id: keyof PathAccount;
  label: string;
  numeric: boolean;
  toolTip?: string;
}

export interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof PathAccount
  ) => void;
  order: Order;
  orderBy: string;
}

function HealthcareProfessionalAccountsTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof PathAccount) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const headCells: readonly HeadCell[] = [
    {
      id: "firstName",
      numeric: false,
      label: "First Name",
    },
    {
      id: "lastName",
      numeric: false,
      label: "Last Name",
    },
    {
      id: "email",
      numeric: false,
      label: "Email",
    },
    {
      id: "accountGuid",
      numeric: false,
      label: "AccountId",
    },
    {
      id: "role",
      numeric: false,
      label: "Role",
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <Tooltip title={headCell.toolTip || ""}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </Tooltip>
          </StyledTableCell>
        ))}
        <StyledTableCell align="center">
          Two Factor Authentication
        </StyledTableCell>
        <StyledTableCell align="left">User Actions</StyledTableCell>
      </TableRow>
    </TableHead>
  );
}

interface Props {
  isLoading: boolean;
}

export const HealthcareProfessionalAccountsComponent: React.FC<Props> = ({
  isLoading,
}) => {
  const accounts = useSelector(selectHcpAccounts);
  const accountId = useSelector(selectAccountId);
  const dispatch = useAppThunkDispatch();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof PathAccount>("lastName");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof PathAccount
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function handleRoleChanged(newRole: any, account: PathAccount) {
    try {
      //setIsLoading(true);
      accountService
        .switchAdmin(account.email, !newRole)
        .then((response: any) => {
          const data: NewRole = {
            role: newRole,
            accountId: account.accountGuid,
          };
          dispatch(changeAccountRole(data));
        })
        .then((response: any) => {
          //setIsLoading(false);
        });
    } catch (error: any) {
      //hmmm
    }
  }

  function handleDeleteAccount(accountGuid: string) {
    try {
      //setIsLoading(true);
      accountService
        .deleteAccount(accountGuid)
        .then((response: any) => {
          dispatch(getAllHcpAccounts());
        })
        .then((response: any) => {
          //setIsLoading(false);
        });
    } catch (error: any) {
      //hmmm
    }
  }

  function handleReset2fa(account: any) {
    if (account.isTwoFactorAuthenticationEnabled) {
      accountService.resetTwoFactor(account.accountGuid).catch((error: any) => {
        //This is not the error you are looking for;
      });
    }
  }

  return (
    <TableDetailContainer>
      <Typography
        sx={{ padding: "5px 0px 5px 0px" }}
        variant="h5"
        gutterBottom
        component="h2"
      >
        Healthcare Professionals Accounts
      </Typography>
      {!isLoading ? (
        accounts.length ? (
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer sx={{ maxHeight: 490, minWidth: 750 }}>
              <Table stickyHeader aria-labelledby="hcp-accounts-table">
                <HealthcareProfessionalAccountsTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {accounts
                    .slice()
                    .sort(getComparator(order, orderBy))
                    .map((account) => {
                      return (
                        <StyledTableRow
                          hover
                          tabIndex={-1}
                          key={account.accountGuid}
                        >
                          <StyledTableCell align="left">
                            {account.firstName}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {account.lastName}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {account.email}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {account.accountGuid}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <Select
                              sx={{ width: "140px" }}
                              value={account.role}
                              onChange={(event) =>
                                handleRoleChanged(event.target.value, account)
                              }
                              //cant modify self or super admin account types
                              disabled={
                                account.accountGuid === accountId ||
                                account.role === Role.SuperAdmin
                              }
                            >
                              <MenuItem value={0}>Admin</MenuItem>
                              <MenuItem value={1}>User</MenuItem>
                              <MenuItem disabled value={2}>
                                Super Admin
                              </MenuItem>
                            </Select>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <Box>
                              <Tooltip title="Reset 2FA">
                                <IconButton
                                  color="primary"
                                  size="large"
                                  onClick={() => handleReset2fa(account)}
                                >
                                  <LockResetIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell>
                            {
                              //cant delete self or super admin account type
                              account.accountGuid !== accountId &&
                              account.role !== Role.SuperAdmin ? (
                                <ThreeDotsMenu
                                  data={account.accountGuid}
                                  handleAction={handleDeleteAccount}
                                />
                              ) : null
                            }
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        ) : (
          <NoData message="You have no HCP accounts to manage right now" />
        )
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          height="100px"
          justifyContent="center"
          data-testid="loading-indicator"
        >
          <PathCircularProgress />
        </Box>
      )}
    </TableDetailContainer>
  );
};

export default HealthcareProfessionalAccountsComponent;
