import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { selectAccount } from "../../../redux/selectors/accountSelectors";
import { useSelector } from "react-redux";
import { TwoFactorSetupCodeResponse } from "../../../model/account/twoFactorAuthentication/twoFactorSetupCodeResponse";
import accountService from "../../../services/AccountService";
import { useAppThunkDispatch } from "../../../redux/configureStore";
import { twoFactorAuthSetupCompletedAndEnabled } from "../../../redux/actions/accountActions";

const EnableTwoFactorAuthComponent: React.VoidFunctionComponent = () => {
  const account = useSelector(selectAccount);
  const dispatch = useAppThunkDispatch();
  const [setupInfo, setSetupInfo] = useState<TwoFactorSetupCodeResponse | null>(
    null
  );
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    if (
      account.isTwoFactorAuthenticationEnabled &&
      !account.isTwoFactorAuthenticationSetupCompleted
    ) {
      accountService
        .enableTwoFactor(account.accountGuid)
        .then((response: TwoFactorSetupCodeResponse) => {
          setSetupInfo(response);
        })
        .catch((error: any) => {
          //dispatch(loginUserError(error.body.message));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return (
    <>
      <Typography variant="h4">
        <strong>Two Factor Authentication</strong>
      </Typography>
      <Box
        sx={{
          mt: 2,
          mb: 1,
        }}
      >
        <>
          <Typography>
            In order to keep your data secure we have implemented two-factor
            authentication.
          </Typography>
          <Typography>Please follow these steps to get setup:</Typography>
          <Box sx={{ m: 1 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography variant="h5">1.</Typography>
              <Typography
                sx={{
                  mt: "7px",
                  ml: 1,
                }}
              >
                Download an authenticator app on your smartphone, e.g. Google
                Authenticator or Microsoft Authenticator.
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography variant="h5">2.</Typography>
              <Typography
                sx={{
                  mt: "7px",
                  ml: 1,
                }}
              >
                Once you have the app, add an account by scanning the QR code on
                this page. You can do so by clicking on ‘Add an account’ or the
                + button.
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography variant="h5">3.</Typography>
              <Typography
                sx={{
                  mt: "7px",
                  ml: 1,
                }}
              >
                When you are done,{" "}
                <Link
                  sx={{ cursor: "pointer" }}
                  color="inherit"
                  onClick={() =>
                    dispatch(twoFactorAuthSetupCompletedAndEnabled())
                  }
                >
                  click here to continue
                </Link>
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              mt: 1,
              mb: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              sx={{
                height: 250,
                width: 250,
                maxHeight: { xs: 250, md: 250 },
                maxWidth: { xs: 250, md: 250 },
              }}
              alt="qr code"
              src={setupInfo?.qrCodeSetupImageUrl}
            />
          </Box>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography variant="h6">
                What is two-factor authentication?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  rowGap: 1,
                }}
              >
                <Typography>
                  Two-factor authentication (2FA) is an authentication process
                  that strengthens access security by requiring two methods to
                  verify your identity.
                </Typography>
                <Typography>
                  These factors can include something you know — like a username
                  and password — plus something you have — like a smartphone app
                  — to approve authentication requests.
                </Typography>
                <Typography>
                  Because the data you can access via this dashboard is
                  sensitive we are requiring you to get setup with an
                  authenticator app.
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography variant="h6"> Can't scan the barcode?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <>
                <Typography>
                  1. Tap <strong>Menu</strong>, then{" "}
                  <strong>Set up account</strong>
                </Typography>
                <Typography>
                  2. Tap <strong>Enter provided key</strong>
                </Typography>
                <Typography>
                  3. Enter your email address and this key
                </Typography>
                <Box
                  sx={{
                    border: 1,
                    mt: 1,
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{ margin: 10, wordWrap: "break-word" }}
                  >
                    {setupInfo?.manualEntryKey}
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    mt: 1,
                  }}
                >
                  4. Make sure <strong>Time based</strong> is turned on, and tap{" "}
                  <strong>Add</strong> to finish
                </Typography>
              </>
            </AccordionDetails>
          </Accordion>
        </>
      </Box>
      <Link href="/" variant="body2" sx={{ mt: 1 }}>
        click here to return home
      </Link>
    </>
  );
};

export default EnableTwoFactorAuthComponent;
