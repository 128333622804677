import { fetch } from "../helpers/api/api";
import { handleResponse } from "../helpers/handleResponse";
import { SaveUserSetting } from "../model/settings/saveUserSetting";

const baseUrl = `${process.env.REACT_APP_API_URL}/setting`;

export const settingsService = {
  getEnvironment,
  getUser,
  userSave,
};

function getEnvironment() {
  return fetch(`${baseUrl}/all`)
    .then((response: any) => handleResponse(response))
    .catch((error: any) =>
      handleResponse({ response: error.response, body: error.body })
    );
}

function getUser(accountGuid: string) {
  return fetch(`${baseUrl}/${accountGuid}`)
    .then((response: any) => handleResponse(response))
    .catch((error: any) =>
      handleResponse({ response: error.response, body: error.body })
    );
}

function userSave(
  accountId: string,
  settingName: string,
  settingValue: string
) {
  const request: SaveUserSetting = {
    AccountGuid: accountId,
    Name: settingName,
    Value: settingValue,
  };

  const requestOptions = {
    method: "POST",
    body: JSON.stringify(request),
  };

  return fetch(`${baseUrl}`, requestOptions)
    .then((response: any) => {
      if (!response.ok) {
        throw new Error("Bad Request");
      }
      return response;
    })
    .catch(() => {
      return getUser(accountId).then((settings: any) => {
        const setting = settings.find((s: any) => s.name === settingName);
        if (setting) {
          return updateUserSetting(
            setting.id,
            accountId,
            settingName,
            settingValue
          );
        }
      });
    });
}

function updateUserSetting(
  id: number,
  accountId: string,
  settingName: string,
  settingValue: string
) {
  const request = {
    Id: id,
    AccountGuid: accountId,
    Name: settingName,
    Value: settingValue,
  };

  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(request),
  };

  return fetch(`${baseUrl}`, requestOptions).then((response: any) => {
    return response;
  });
}
