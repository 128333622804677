import * as React from "react";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, TableFooter, TableHead, Typography } from "@mui/material";

const useStyles = makeStyles((theme: any) => ({
  tableCellHeader: {
    "&.MuiTableCell-root": {
      height: "48px",
      width: "60px",
      padding: "0px 10px 0px 10px",
      textAlign: "center",
      fontWeight: 700,
    },
  },
  tableCell: {
    "&.MuiTableCell-root": {
      padding: "0px 10px 0px 10px",
      width: "60px",
      height: "24px",
      textAlign: "center",
    },
  },
  emptyCell: {
    "&.MuiTableCell-root": {
      padding: "0px",
      width: "60px",
      height: "40px",
    },
  },
  headerText: {
    color: "white",
  },
}));

const TimeTakenTableComponent: React.VoidFunctionComponent = () => {
  const classes = useStyles();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#28666C",
        borderRadius: "10px",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px",
      }}
    >
      <Typography className={classes.headerText} component="h5" variant="h5">
        Time Taken
      </Typography>

      <TableContainer
        component={Paper}
        sx={{
          borderRadius: "10px",
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableCell className={classes.tableCellHeader}>Decade</TableCell>
            <TableCell className={classes.tableCellHeader}>Men (s)</TableCell>
            <TableCell className={classes.tableCellHeader}>Women (s)</TableCell>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableCell}>40s</TableCell>
              <TableCell className={classes.tableCell}>7.87±1.05</TableCell>
              <TableCell className={classes.tableCell}>8.37±1.02</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>50s</TableCell>
              <TableCell className={classes.tableCell}>7.87±1.05</TableCell>
              <TableCell className={classes.tableCell}>8.37±1.02</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>60-64</TableCell>
              <TableCell className={classes.tableCell}>7.87±1.05</TableCell>
              <TableCell className={classes.tableCell}>8.37±1.02</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>65-69</TableCell>
              <TableCell className={classes.tableCell}>7.87±1.05</TableCell>
              <TableCell className={classes.tableCell}>8.37±1.02</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>70-74</TableCell>
              <TableCell className={classes.tableCell}>7.87±1.05</TableCell>
              <TableCell className={classes.tableCell}>8.37±1.02</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>75-79</TableCell>
              <TableCell className={classes.tableCell}>7.87±1.05</TableCell>
              <TableCell className={classes.tableCell}>8.37±1.02</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>80s</TableCell>
              <TableCell className={classes.tableCell}>7.87±1.05</TableCell>
              <TableCell className={classes.tableCell}>8.37±1.02</TableCell>
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableCell align="center" colSpan={3}>
              <Typography
                sx={{
                  backgroundColor: "error.main",
                  color: "white",
                  borderRadius: "10px",
                }}
              >
                Falls Risk &gt;13 Seconds
              </Typography>
            </TableCell>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TimeTakenTableComponent;
