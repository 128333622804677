import { useContext } from "react";
import PermissionContext from "../../components/Permissions/PermissionContext";
import { Permission } from "../../model/account/permissions";

const usePermission = (permissions: Permission[]) => {
  const { isAllowedTo } = useContext(PermissionContext);
  const result = isAllowedTo(permissions);
  return result;
};

export default usePermission;
