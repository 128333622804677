import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Box, CardContent, Grid } from "@mui/material";
import { ParameterCard } from "../../../documented/ParameterCard";
import { AverageCycleDurationCardData } from "./averageCycleDurationCardData";
import mySvg from "./averageCycleDurationBackground.svg";
import { isEmpty } from "../../../../helpers/utilityFunctions/utilityFunctions";

interface AverageCycleDurationCardProps {
  data?: AverageCycleDurationCardData;
  onClick: (arg: string) => void;
}

const CardDetailTypography = styled(Typography)(() => ({
  fontSize: 14,
  padding: "0px 0px 0px 16px !important",
}));

const AverageCycleDurationCardMedia = styled(CardContent)(() => ({
  display: "flex !important",
  flexDirection: "column",
  flexGrow: "1 !important",
}));

const PanelDisplayBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
}));

const AverageCycleDurationCard: React.FC<AverageCycleDurationCardProps> = ({
  data,
  onClick,
}: AverageCycleDurationCardProps) => {
  return (
    <ParameterCard
      title="Average Cycle Duration"
      iconName="AverageCycleDuration"
      onClick={() => onClick("_10MWT")}
      enabled={!isEmpty(data)}
    >
      <CardDetailTypography>
        The duration of one cycle from the heel strike till the next heel strike
        of the same leg
      </CardDetailTypography>
      <AverageCycleDurationCardMedia>
        <PanelDisplayBox>
          <Box
            sx={{
              backgroundImage: `url(${mySvg})`,
              height: 206.148,
              width: 173,
            }}
          >
            <Grid
              sx={{
                height: "100%",
                width: "100%",
                direction: "column",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              container
            >
              <Grid
                item
                sx={{
                  width: "86.5px",
                  display: "flex",
                  flexDirection: "column",
                  margin: "0px 0px 0px -25px",
                }}
              >
                <Typography
                  sx={{ fontWeight: "bold", fontSize: 20, textAlign: "center" }}
                >
                  {data ? Number(data.left.value) : 0}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: 20,
                    textAlign: "center",
                  }}
                >
                  {data ? data.unit : "0"}
                </Typography>
              </Grid>
              <Grid
                item
                sx={{
                  width: "86.5px",
                  display: "flex",
                  flexDirection: "column",
                  margin: "0px -25px 0px 0px",
                }}
              >
                <Typography
                  sx={{ fontWeight: "bold", fontSize: 20, textAlign: "center" }}
                >
                  {data ? Number(data.right.value) : 0}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: 20,
                    textAlign: "center",
                  }}
                >
                  {data ? data.unit : "0"}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </PanelDisplayBox>
      </AverageCycleDurationCardMedia>
    </ParameterCard>
  );
};

export default AverageCycleDurationCard;
