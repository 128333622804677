import React, { useEffect, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector } from "react-redux";
import { Patient } from "../../../../model/patient/patient";
import { selectPatients } from "../../../../redux/selectors/patientSelectors";
import PatientValue from "../../../../components/Account/PatientValue";
import MultiDatePicker from "../../../../components/documented/MultiDatePicker/MultiDatePicker";
import { useNavigate } from "react-router-dom";

interface Props {
  patientId?: string;
  selectedDateRange: [Date | null, Date | null];
  setSelectedDateRange?:
    | React.Dispatch<React.SetStateAction<[Date | null, Date | null]>>
    | undefined;
}

export const AverageWalkingSpeedDetailHeader: React.FC<Props> = ({
  patientId,
  selectedDateRange,
  setSelectedDateRange,
}) => {
  const navigate = useNavigate();
  const patients = useSelector(selectPatients);
  const [dates] = useState<Date[]>();
  const [selectedPatient, setSelectedPatient] = useState<Patient>();
  const localSelectedPatientData = localStorage.getItem(
    "selectedPatientFromPatientsTable"
  );

  //ignore linting rules for the below for now - needed to make date picker work
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedMonth, setSelectedMonth] = React.useState<Date>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const selectedPatient = patients.find((p) => p.accountGuid === patientId);
    setSelectedPatient(selectedPatient);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId]);

  // useEffect(() => {
  //   //TODO - Try to set selected date to the date from the daily params page, if not just select today
  //   const range = getDateRanges(undefined, true);
  //   passiveParametersService.getDailyParameterDatesByRange(
  //     patientId!,
  //     range.startDate,
  //     range.endDate
  //   );
  //   //setSelectedDate(latestDate);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedMonth]);

  if (!selectedPatient && localSelectedPatientData) {
    setSelectedPatient(JSON.parse(localSelectedPatientData));
  }

  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: "40px 40px 0px 10px",
        margin: "0px 40px 0px 0px",
      }}
    >
      <Box sx={{ margin: "40px 40px 0px 40px" }}>
        <Box>
          <IconButton
            sx={{ width: "40px", height: "40px" }}
            color="primary"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon sx={{ transform: "scale(1.3)" }} />
          </IconButton>
        </Box>
        <PatientValue patient={selectedPatient} patientKey="name" />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
            width: "100%",
          }}
        >
          <Typography variant="h4" component="h4">
            Average Walking Speed
          </Typography>
          <MultiDatePicker
            isRangePicker
            dates={dates}
            isLoading={isLoading}
            setSelectedMonth={setSelectedMonth}
            selectedRange={selectedDateRange}
            setSelectedRange={setSelectedDateRange}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AverageWalkingSpeedDetailHeader;
