import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { Typography, IconButton, Box, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import { makeStyles } from "@mui/styles";

interface Props {
  setIsAuthorisedLoading: (params: any) => any;
  setIsPendingLoading: (params: any) => any;
  onRefreshPatients: () => void;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: 30,
  },
  personActions: {
    display: "flex",
  },
}));

export const PatientsHeader: React.FC<Props> = ({ onRefreshPatients }) => {
  const classes = useStyles();
  let navigate = useNavigate();

  useEffect(() => {
    onRefreshPatients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddPatient = () => {
    navigate("/dashboard/patients/add");
  };
  return (
    <div className={classes.root}>
      <Typography variant="h4" component="h4">
        Patients
      </Typography>

      <Box className={classes.personActions}>
        <Tooltip title="Refresh Patients">
          <IconButton
            aria-label="refresh"
            size="large"
            onClick={onRefreshPatients}
          >
            <RefreshIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Add Patient">
          <IconButton aria-label="add" size="large" onClick={handleAddPatient}>
            <AddIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </Box>
    </div>
  );
};

export default PatientsHeader;
