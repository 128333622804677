import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, IconButton } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/Info";
import IconLoader from "../../IconLoader";
import { shouldForwardProp } from "../../../helpers/utilityFunctions/utilityFunctions";

const ParameterCardContainer = styled(Card, {
  shouldForwardProp: (prop) =>
    shouldForwardProp<IParameterCardStyleProps>(
      ["showingInfo", "large", "larger", "enabled"],
      prop
    ),
})<IParameterCardStyleProps>(
  ({ theme, showingInfo, large, larger, enabled }) => ({
    height: "381px",
    width: `${larger ? "800px" : large ? "530px" : "254px"}`,
    display: "flex",
    position: "relative",
    flexDirection: "column",
    border: "2px solid",
    borderRadius: "10px !important",
    background: `${
      enabled
        ? theme.palette.background.default
        : theme.palette.action.disabledBackground
    }`,

    borderColor: `${
      showingInfo
        ? "black"
        : enabled
        ? theme.palette.primary.main
        : theme.palette.action.disabled
    }`,
  })
);

const ParameterCardMedia = styled(CardContent)(() => ({
  display: "flex !important",
  alignItems: "center",
  flexDirection: "column",
  flexGrow: "1 !important",
  padding: "0px 0px 0px 0px !important",
}));

const ParameterCardContent = styled(CardContent, {
  shouldForwardProp: (prop) =>
    shouldForwardProp<IParameterCardStyleProps>(["popoverContent"], prop),
})<IParameterCardStyleProps>(({ popoverContent }) => ({
  display: "flex",
  flexDirection: "row",
  padding: "0px 0px 0px 16px !important",
  margin: `${popoverContent ? "40px 0px 0px 0px" : "10px 0px 0px 0px"}`,
}));

const InformationIcon = styled(InfoIcon, {
  shouldForwardProp: (prop) =>
    shouldForwardProp<IParameterCardStyleProps>(["showingInfo"], prop),
})<IParameterCardStyleProps>(({ theme, showingInfo }) => ({
  height: 24,
  width: 24,
  color: `${showingInfo ? "white" : theme.palette.primary.main}`,
}));

const Popover = styled(Box, {
  shouldForwardProp: (prop) =>
    shouldForwardProp<IParameterCardStyleProps>(
      ["showingInfo", "large", "larger", "enabled"],
      prop
    ),
})<IParameterCardStyleProps>(({ theme, showingInfo, large, larger }) => ({
  position: "absolute",
  zIndex: 2,
  top: 0,
  right: 0,
  height: `${showingInfo ? "381px" : "0px"}`,
  width: `${
    !showingInfo ? "0px" : larger ? "810px" : large ? "530px" : "254px"
  }`,
  backgroundColor: theme.palette.primary.main,
  borderRadius: "6px",
  transform: "rotate(0deg)",
  transition: "all 250ms ease-in-out",
}));

const PopoverContent = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  margin: "40px 0px 0px 16px",
  color: "white",
}));

type IParameterCardProps = {
  title: string;
  iconName: string;
  onClick: () => void;
  popoverContent?: string;
  large?: boolean;
  larger?: boolean;
  enabled?: boolean;
  children?: React.ReactNode;
};

type IParameterCardStyleProps = {
  showingInfo?: boolean;
  large?: boolean;
  larger?: boolean;
  enabled?: boolean;
  popoverContent?: string;
};

/** The base card component for most of the parameters the system handles */
export const ParameterCard: React.FC<IParameterCardProps> = ({
  title,
  iconName,
  onClick,
  popoverContent,
  large,
  larger,
  enabled = true,
  children,
}: IParameterCardProps) => {
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const CardHeader = () => {
    return (
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "row",
          justifyContent: "right",
        }}
      >
        <Popover showingInfo={showInfo} large={large} larger={larger}>
          <PopoverContent>
            <IconLoader name={iconName} fill="white" />
            <Typography
              sx={{
                ml: 2,
              }}
              variant="h5"
              component="h2"
            >
              <strong>{title}</strong>
            </Typography>
          </PopoverContent>
          <Typography
            sx={{
              mt: 2,
              ml: 2,
              mr: 2,
              color: "white",
            }}
            variant="body1"
          >
            {popoverContent}
          </Typography>
        </Popover>

        <IconButton
          disabled={!enabled}
          sx={{ ml: 1, position: "absolute", zIndex: 3 }}
          onClick={() => setShowInfo(!showInfo)}
        >
          <InformationIcon
            sx={{ color: `${enabled ? "main.primary" : "action.disabled"}` }}
            showingInfo={showInfo}
          />
        </IconButton>
      </Box>
    );
  };

  return (
    <ParameterCardContainer large={large} larger={larger} enabled={enabled}>
      {popoverContent ? CardHeader() : null}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          cursor: `${enabled ? "pointer" : null}`,
        }}
        onClick={enabled ? onClick : undefined}
      >
        <ParameterCardContent popoverContent={popoverContent}>
          <IconLoader
            name={iconName}
            fill={enabled ? "text.primary" : "action.disabled"}
          />
          <Typography
            sx={{
              ml: 2,
              color: `${enabled ? "text.primary" : "action.disabled"}`,
            }}
            variant="h5"
            component="h2"
          >
            <strong>{title}</strong>
          </Typography>
        </ParameterCardContent>

        <ParameterCardMedia>{enabled ? children : null}</ParameterCardMedia>
      </Box>
    </ParameterCardContainer>
  );
};
