import * as React from "react";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, TableHead, Typography } from "@mui/material";

const useStyles = makeStyles((theme: any) => ({
  tableCellHeader: {
    "&.MuiTableCell-root": {
      textAlign: "center",
      fontWeight: 700,
    },
  },
  tableCell: {
    "&.MuiTableCell-root": {},
  },
  emptyCell: {
    "&.MuiTableCell-root": {
      padding: "0px",
    },
  },
  dividerCell: {
    "&.MuiTableCell-root": {
      borderTop: "none",
    },
  },
  headerText: {
    color: "white",
    textAlign: "center",
  },
}));

const PressureInterpretationTableComponent = () => {
  const classes = useStyles();

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "primary.main",
        borderRadius: "10px",
        padding: "5px",
      }}
    >
      <Typography className={classes.headerText} component="h5" variant="h5">
        Interpretation Table
      </Typography>

      <TableContainer
        component={Paper}
        sx={{
          borderRadius: "10px",
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableCell className={classes.emptyCell} />
            <TableCell className={classes.tableCellHeader}>
              <Typography>
                At risk
                <React.Fragment>&nbsp;</React.Fragment>
                <strong>peak</strong>
                <React.Fragment>&nbsp;</React.Fragment>
                pressure event
              </Typography>
            </TableCell>
            <TableCell className={classes.tableCellHeader}>
              <Typography>
                At risk
                <React.Fragment>&nbsp;</React.Fragment>
                <strong>constant</strong>
                <React.Fragment>&nbsp;</React.Fragment>
                pressure event
              </Typography>
            </TableCell>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell rowSpan={2} className={classes.tableCellHeader}>
                <Typography align="center" fontWeight="bold">
                  Level 1
                </Typography>
                <React.Fragment>&nbsp;</React.Fragment>
                <Typography align="left">
                  The wearer is advised to <strong>visually inspect</strong> the
                  specified foot area for any potential issues.
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography>
                  <strong>Entire foot peak pressure:</strong>
                  <React.Fragment>&nbsp;</React.Fragment>
                  the average peak pressure in
                  <React.Fragment>&nbsp;</React.Fragment>
                  <strong>
                    any region of the foot exceeds 5.8 bar for 20 consecutive
                    steps.
                  </strong>
                </Typography>
              </TableCell>
              <TableCell rowSpan={2} className={classes.tableCell}>
                <Typography>
                  A sustained pressure higher than
                  <React.Fragment>&nbsp;</React.Fragment>
                  <strong>0.27 bar</strong>
                  <React.Fragment>&nbsp;</React.Fragment>
                  was detected in a region of the foot
                  <React.Fragment>&nbsp;</React.Fragment>
                  <strong>for at least 15 minutes.</strong>
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>
                <Typography>
                  <strong>Forefoot peak pressure:</strong>
                  <React.Fragment>&nbsp;</React.Fragment>
                  the average peak pressure in
                  <React.Fragment>&nbsp;</React.Fragment>
                  <strong>
                    any region of the forefoot exceeds 3.0 bar for 20
                    consecutive steps.
                  </strong>
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCellHeader}>
                <Typography align="center" fontWeight="bold">
                  Level 2
                </Typography>
                <React.Fragment>&nbsp;</React.Fragment>
                <Typography align="left">
                  The wearer is advised to visually inspect the specified area
                  of the foot again and take action as needed. Wearer is{" "}
                  <strong>
                    advised to contact their clinician if necessary.
                  </strong>
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography>
                  <strong>Entire foot and forefoot peak pressure:</strong>
                  <React.Fragment>&nbsp;</React.Fragment>
                  the same peak pressure was detected on the
                  <React.Fragment>&nbsp;</React.Fragment>
                  <strong>same day more than 30 minutes after</strong>
                  <React.Fragment>&nbsp;</React.Fragment>
                  the initial peak pressure event.
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography>
                  A sustained pressure higher than
                  <React.Fragment>&nbsp;</React.Fragment>
                  <strong>0.27 bar</strong>
                  <React.Fragment>&nbsp;</React.Fragment>
                  was detected
                  <React.Fragment>&nbsp;</React.Fragment>
                  <strong>for at least 30 minutes.</strong>
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCellHeader}>
                <Typography align="center" fontWeight="bold">
                  Level 3
                </Typography>
                <React.Fragment>&nbsp;</React.Fragment>
                <Typography align="left">
                  The wearer is advised to visually inspect the specified area
                  of the foot again and take action as needed. Potential
                  pre-ulcerative state, the
                  <React.Fragment>&nbsp;</React.Fragment>
                  <strong>wearer is advised to contact their clinician.</strong>
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography>
                  <strong>Entire foot and forefoot peak pressure:</strong>
                  <React.Fragment>&nbsp;</React.Fragment>
                  the
                  <React.Fragment>&nbsp;</React.Fragment>
                  <strong>
                    same peak pressure was detected today and at least twice
                    yesterday.
                  </strong>
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography>
                  A sustained pressure higher than
                  <React.Fragment>&nbsp;</React.Fragment>
                  <strong>0.27 bar</strong>
                  <React.Fragment>&nbsp;</React.Fragment>
                  was detected
                  <React.Fragment>&nbsp;</React.Fragment>
                  <strong>for at least 60 minutes.</strong>
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PressureInterpretationTableComponent;
