import React from "react";
import { Permission } from "../../model/account/permissions";
import PermissionContext from "./PermissionContext";
import { useSelector } from "react-redux";
import { AccountTypeId } from "../../model/account/accountTypeId";
import { Role } from "../../model/account/role";
import { selectAccount } from "../../redux/selectors/accountSelectors";

type Props = { children: React.ReactNode };

const PermissionProvider: React.FunctionComponent<Props> = ({ children }) => {
  const account = useSelector(selectAccount);

  const accountPermissions: Permission[] = [
    AccountTypeId[account.accountType.accountTypeId],
    Role[account.role],
  ];

  const isAllowedTo = (permissions: Permission[]): boolean => {
    if (permissions.some((p) => accountPermissions.includes(p))) {
      return true;
    }
    return false;
  };

  return (
    <PermissionContext.Provider value={{ isAllowedTo }}>
      {children}
    </PermissionContext.Provider>
  );
};

export default PermissionProvider;
