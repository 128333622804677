import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";
import IconLoader from "../../../IconLoader";
import background from "./averageSymmetryBackground.svg";
import { AverageAsymmetryCardData } from "../AverageAsymmetry/averageAsymmetryCardData";
import { isEmpty } from "../../../../helpers/utilityFunctions/utilityFunctions";

interface AverageSymmetryCardMiniProps {
  data?: AverageAsymmetryCardData;
}

const ParamHeader = styled(Typography)(() => ({
  display: "inline",
  border: "1px solid",
  borderRadius: "16px",
  padding: "3px",
  margin: "10px",
}));

const ParamHeaderContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
}));

const ParamContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
}));

const CardDetailTypography = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: "bold",
  textAlign: "center",
}));

const AverageSymmetryCardMini: React.FC<AverageSymmetryCardMiniProps> = ({
  data,
}: AverageSymmetryCardMiniProps) => {
  return (
    <Box
      sx={{
        height: "154px",
        width: "265px",
        direction: "column",
        alignItems: "center",
      }}
    >
      <ParamHeaderContainer>
        <IconLoader name="AverageAsymmetry" fill="black" />
        <Typography
          sx={{
            ml: 3,
          }}
          variant="h5"
          component="h2"
        >
          <ParamHeader>Average Symmetry</ParamHeader>
        </Typography>
      </ParamHeaderContainer>
      <ParamContentContainer>
        {isEmpty(data?.value) ? (
          <Grid
            sx={{
              backgroundImage: `url(${background})`,
              height: "100px",
              width: "80px",
              margin: "10px 80px 0px 0px",
            }}
            container
          >
            <Grid
              item
              sx={{
                width: "86.5px",
                display: "flex",
                flexDirection: "column",
                margin: "85px 0px 0px 0px",
              }}
            >
              <CardDetailTypography>
                {data ? data.value : "0"} {data ? data.unit : "%"}
              </CardDetailTypography>
            </Grid>
          </Grid>
        ) : null}
      </ParamContentContainer>
    </Box>
  );
};

export default AverageSymmetryCardMini;
