import { makeStyles } from "@mui/styles";
import React from "react";
import { useParams } from "react-router-dom";
import DailyParametersHomeDetails from "./DailyParameterHomeDetails";
import DailyParametersHomeHeader from "./DailyParameterHomeHeader";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column",
    height: "100vh",
  },
}));

const DailyParametersHomePage: React.FC = () => {
  const classes = useStyles();
  const { patientId } = useParams();

  return (
    <div className={classes.root}>
      <DailyParametersHomeHeader patientId={patientId} />
      <DailyParametersHomeDetails patientId={patientId} />
    </div>
  );
};

export default DailyParametersHomePage;
