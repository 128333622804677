import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import { SettingsState } from "../../model/admin/settingsState";
import { SystemState } from "../../redux/system/SystemState";

interface ControlVisibilityProps {
  value:
    | keyof SettingsState["DailyMetricsVisibility"]
    | keyof SettingsState["DailyParametersVisibility"]
    | keyof SettingsState["PatientTestVisibility"];
  children: ReactNode;
}

const ControlVisibility: React.FC<ControlVisibilityProps> = ({
  value,
  children,
}) => {
  const environmentVisibility = useSelector(
    (state: SystemState) => state.settings.environment
  );
  const userVisibility = useSelector(
    (state: SystemState) => state.settings.user
  );

  const isVisible =
    (value in userVisibility.DailyMetricsVisibility &&
      userVisibility.DailyMetricsVisibility[
        value as keyof typeof userVisibility.DailyMetricsVisibility
      ]) ||
    (value in userVisibility.DailyParametersVisibility &&
      userVisibility.DailyParametersVisibility[
        value as keyof typeof userVisibility.DailyParametersVisibility
      ]) ||
    (value in userVisibility.PatientTestVisibility &&
      userVisibility.PatientTestVisibility[
        value as keyof typeof userVisibility.PatientTestVisibility
      ]) ||
    (!(value in userVisibility.DailyMetricsVisibility) &&
      value in environmentVisibility.DailyMetricsVisibility &&
      environmentVisibility.DailyMetricsVisibility[
        value as keyof typeof environmentVisibility.DailyMetricsVisibility
      ]) ||
    (!(value in userVisibility.DailyParametersVisibility) &&
      value in environmentVisibility.DailyParametersVisibility &&
      environmentVisibility.DailyParametersVisibility[
        value as keyof typeof environmentVisibility.DailyParametersVisibility
      ]) ||
    (!(value in userVisibility.PatientTestVisibility) &&
      value in environmentVisibility.PatientTestVisibility &&
      environmentVisibility.PatientTestVisibility[
        value as keyof typeof environmentVisibility.PatientTestVisibility
      ]);

  return isVisible ? <>{children}</> : null;
};

export default ControlVisibility;
