import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Box, CardContent } from "@mui/material";
import { ParameterCard } from "../../../documented/ParameterCard";
import { AverageSwingAverageStancePanel } from "./AverageSwingAverageStancePanel";
import { AverageSwingAverageStanceCardData } from "./averageSwingAverageStanceCardData";
import { isEmpty } from "../../../../helpers/utilityFunctions/utilityFunctions";

interface AverageSwingAverageStanceCardProps {
  data?: AverageSwingAverageStanceCardData;
  onClick: (arg: string) => void;
}

const CardDetailTypography = styled(Typography)(() => ({
  fontSize: 14,
}));

const AverageSwingAverageStanceCardMedia = styled(CardContent)(() => ({
  display: "flex !important",
  flexDirection: "column",
  flexGrow: "1 !important",
}));

const PanelDisplayBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  margin: "30px 0px 0px 0px !important",
}));

const AverageSwingAverageStanceCard: React.FC<
  AverageSwingAverageStanceCardProps
> = ({ data, onClick }: AverageSwingAverageStanceCardProps) => {
  return (
    <ParameterCard
      larger
      title="Average Swing &amp; Average Stance"
      iconName="AverageSwingAverageStance"
      onClick={() => onClick("_10MWT")}
      enabled={!isEmpty(data)}
    >
      <AverageSwingAverageStanceCardMedia>
        <CardDetailTypography>
          - Swing: The time spent with no contact between the foot and the
          ground.*
        </CardDetailTypography>
        <CardDetailTypography>
          - Stance: The time spent with the foot in contact with the ground.
        </CardDetailTypography>
        <PanelDisplayBox>
          <AverageSwingAverageStancePanel
            leftAverageSwing={data ? Number(data.left.swing) : 0}
            leftAverageStance={data ? Number(data.left.stance) : 0}
            rightAverageSwing={data ? Number(data.right.swing) : 0}
            rightAverageStance={data ? Number(data.right.stance) : 0}
            doubleSupport={data ? Number(data.averageDoubleSupport.value) : 0}
            measurement={data ? data.left.unit : "%"}
          />
        </PanelDisplayBox>
      </AverageSwingAverageStanceCardMedia>
    </ParameterCard>
  );
};

export default AverageSwingAverageStanceCard;
