import * as React from "react";
import {
  angleToPosition,
  AngleDescription,
  valueToAngle,
} from "./circularGeometry";
import { arcPathWithRoundedEnds } from "./svgPaths";

interface Props {
  size: number;
  minValue: number;
  maxValue: number;
  startAngle: number;
  endAngle: number;
  angleType: AngleDescription;
  value: number;
  unit?: string;
  range?: Array<number>;
}

export const RadialIndicator: React.FC<Props> = ({
  size = 200,
  minValue = 0,
  maxValue = 100,
  startAngle = 0,
  endAngle = 360,
  angleType = {
    direction: "cw",
    axis: "-y",
  },
  value = 0,
  unit = "",
  range = [],
}) => {
  const handleSize = 10;
  const trackWidth = 12;
  const trackInnerRadius = size / 2 - trackWidth - 25;
  const handle1Angle = valueToAngle({
    value: value,
    minValue,
    maxValue,
    startAngle,
    endAngle,
  });

  const handle1Position = angleToPosition(
    { degree: handle1Angle, ...angleType },
    trackInnerRadius + trackWidth / 2,
    size
  );

  const arcPath = arcPathWithRoundedEnds({
    startAngle,
    endAngle,
    angleType,
    innerRadius: trackInnerRadius,
    thickness: trackWidth,
    svgSize: size,
    direction: angleType.direction,
  });

  return (
    <svg width={size} height={size}>
      <defs>
        <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor="#D90B0A" />
          <stop offset="25%" stopColor="#D90B0A" />
          <stop offset="50%" stopColor="#E46419" />
          <stop offset="75%" stopColor="#197F2B" />
          <stop offset="100%" stopColor="#197F2B" />
        </linearGradient>
      </defs>

      <path id="arc" d={arcPath} fill="url(#linear)" />

      <g id="range">
        {range &&
          range.map((value, key) => {
            const sliceAngle = valueToAngle({
              value: value,
              minValue,
              maxValue,
              startAngle,
              endAngle,
            });

            const sliceAngleToPosition = angleToPosition(
              { degree: sliceAngle, ...angleType },
              (trackInnerRadius + trackWidth) * 1.23,
              size
            );

            return (
              <g key={key}>
                <text
                  x={sliceAngleToPosition.x}
                  y={sliceAngleToPosition.y}
                  fill="#727577"
                  dominantBaseline="middle"
                  textAnchor="middle"
                >
                  {value}
                </text>
                <line
                  x1={sliceAngleToPosition.x + 11}
                  y1={sliceAngleToPosition.y + 11}
                  x2={sliceAngleToPosition.x + 8}
                  y2={sliceAngleToPosition.y + 8}
                  transform={`rotate(${sliceAngle + 225} ${
                    sliceAngleToPosition.x
                  } ${sliceAngleToPosition.y}) translate(0 0)`}
                  stroke="#727577"
                />
              </g>
            );
          })}
      </g>

      <g id="progressThumb">
        <filter id="handleShadow" x="-80%" y="-80%" width="16" height="16">
          <feOffset result="offOut" in="SourceGraphic" dx="0" dy="0" />
          <feColorMatrix
            result="matrixOut"
            in="offOut"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="5" />
          <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
        </filter>
        <circle
          r={handleSize}
          cx={handle1Position.x}
          cy={handle1Position.y}
          stroke="black"
          strokeWidth="12"
          fill="none"
          filter="url(#handleShadow)"
        />
        <circle
          r={handleSize}
          cx={handle1Position.x}
          cy={handle1Position.y}
          stroke="white"
          strokeWidth="11"
          fill="none"
          filter="url(#handleShadow)"
        />
      </g>

      <g>
        <text
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontWeight="bold"
          fill="black"
          fontSize={size >= 199 ? "2.5em" : "1.5em"}
          fontFamily="Montserrat"
        >
          {value ? value : ""}
        </text>

        <text
          x="50%"
          y="65%"
          dominantBaseline="middle"
          textAnchor="middle"
          fill="black"
          fontSize={size >= 199 ? "1em" : "0.7em"}
          fontFamily="Montserrat"
        >
          {unit ? unit : ""}
        </text>
      </g>
    </svg>
  );
};
