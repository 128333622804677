import React, { Dispatch, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { LoadingButton } from "@mui/lab";
import { Box, Grid } from "@mui/material";
import { inviteService } from "../../services/inviteService";
import { FORM_STATUS } from "../../model/formStatus";
import { InviteDetailResponse } from "../../model/invite/InviteDetailResponse";

const defaultState = {
  invitationCodeGuid: "",
};

interface Props {
  data?: string;
  setResponse: Dispatch<InviteDetailResponse>;
}

export const FetchInviteDetailsComponent: React.FC<Props> = ({
  data,
  setResponse,
}) => {
  const [formData, setFormData] = useState(defaultState);
  const [status, setStatus] = useState(FORM_STATUS.IDLE);
  const [touched, setTouched] = useState(defaultState);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (data) {
      setFormData((curFormData) => {
        return {
          ...curFormData,
          invitationCodeGuid: data,
        };
      });
      getErrors(formData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function handleChange(e: any) {
    e.persist();
    setFormData((curFormData) => {
      return {
        ...curFormData,
        [e.target.id]: e.target.value,
      };
    });
  }

  function handleBlur(e: any) {
    e.persist();
    setTouched((cur: any) => {
      return { ...cur, [e.target.id]: true };
    });
  }

  function getErrors(formData: any) {
    const result = {
      invitationCodeGuid: "",
    };
    if (status === FORM_STATUS.COMPLETED) return result;

    if (!formData.invitationCodeGuid) {
      result.invitationCodeGuid = "Please enter an invite code.";
    } else if (
      !/{?\w{8}-?\w{4}-?\w{4}-?\w{4}-?\w{12}}?/i.test(
        formData.invitationCodeGuid
      )
    ) {
      result.invitationCodeGuid = "Your invitation code is Invalid.";
    }
    return result;
  }

  //Derived state
  const errors = getErrors(formData);
  const isValid = Object.values(errors).every((x) => x === null || x === "");

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setStatus(FORM_STATUS.SUBMITTING);
    if (isValid) {
      setIsLoading(true);
      try {
        inviteService
          .getInviteDetails(formData.invitationCodeGuid)
          .then((response: any) => {
            setStatus(FORM_STATUS.COMPLETED);
            setFormData(defaultState);
            setIsLoading(false);
            setResponse(response);
          });
      } catch (e) {
        setIsLoading(false);
      }
    } else {
      setStatus(FORM_STATUS.SUBMITTED);
    }
  };

  return (
    <Box component="form" sx={{ mt: 1 }} onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            autoComplete="invite-code"
            name="invitationCodeGuid"
            required
            fullWidth
            id="invitationCodeGuid"
            label="Invite Code"
            value={formData.invitationCodeGuid}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={status === FORM_STATUS.COMPLETED ? true : false}
            helperText={
              (touched.invitationCodeGuid ||
                status === FORM_STATUS.SUBMITTED) &&
              errors.invitationCodeGuid
            }
            error={
              (touched.invitationCodeGuid !== "" ||
                status === FORM_STATUS.SUBMITTED) &&
              errors.invitationCodeGuid !== ""
            }
          />
        </Grid>
      </Grid>

      <LoadingButton
        type="submit"
        loading={isLoading}
        variant="contained"
        color="primary"
        //className={classes.submit}
        sx={{ mt: 3 }}
        disabled={status === FORM_STATUS.COMPLETED ? true : false}
      >
        Find
      </LoadingButton>
    </Box>
  );
};

export default FetchInviteDetailsComponent;
