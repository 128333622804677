import { SystemState } from "../system/SystemState";

export const environmentDailyMetricsVisibilitySettings = (state: SystemState) =>
  state.settings.environment.DailyMetricsVisibility;
export const environmentDailyParametersVisibilitySettings = (
  state: SystemState
) => state.settings.environment.DailyParametersVisibility;
export const environmentPatientTestVisibilitySettings = (state: SystemState) =>
  state.settings.environment.PatientTestVisibility;

export const userDailyMetricsVisibilitySettings = (state: SystemState) =>
  state.settings.user.DailyMetricsVisibility;
export const userDailyParametersVisibilitySettings = (state: SystemState) =>
  state.settings.user.DailyParametersVisibility;
export const userPatientTestVisibilitySettings = (state: SystemState) =>
  state.settings.user.PatientTestVisibility;
