import { SystemState } from "../system/SystemState";

export const selectHcpAccounts = (state: SystemState) =>
  state.admin.hcpAccounts;

export const selectPatientAccounts = (state: SystemState) =>
  state.admin.patientAccounts;

export const selectInvites = (state: SystemState) =>
  state.admin.invitationCodes;
