import React from "react";
import { Grid, Typography } from "@mui/material";
import CustomTestTableComponent from "./CustomTestTableComponent";

const Custom: React.FC = () => {
  return (
    <Grid container direction="column" mt={1} spacing={2} alignItems="stretch">
      <Grid item xs={8}>
        <Typography>
          Here are all the tests conducted on this day. You can download the raw
          files associated with the test.
        </Typography>
      </Grid>
      <Grid item mr={4}>
        <CustomTestTableComponent />
      </Grid>
    </Grid>
  );
};

export default Custom;
