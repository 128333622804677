import {
  Box,
  CircularProgress,
  List,
  ListSubheader,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import MomentCard from "../../Cards/MomentCard";
import { Alert, AlertMoment } from "../../../model/passive/alertMoment";
import { makeStyles } from "@mui/styles";
import { PassiveParameterType } from "../../../model/passive/passiveParameterType";

const useStyles = makeStyles((theme: any) => ({
  listSubHeaderRoot: {
    lineHeight: "24px !important",
    backgroundColor: "#E5E5E5 !important",
    borderRadius: "5px !important",
    color: "#252525 !important",
  },
}));

const CentredBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  width: "385px",
  justifyContent: "center",
  alignItems: "center",
});

interface AlertListProps {
  alertType: PassiveParameterType;
  selectedAlertData: AlertMoment[];
  selectedMomentId: number;
  isLoading: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement>, id: number) => void;
}

/** A list component for showing path insight alerts. */
export const AlertList: React.FC<AlertListProps> = ({
  alertType,
  selectedAlertData,
  selectedMomentId,
  isLoading,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        border: "1px solid",
        borderColor: "black",
        borderRadius: "10px",
        pt: 1,
        pl: 2,
        pr: 2,
        rowGap: "10px",
        //small hack to position the list. This will need to change as more content gets added to to this component
        maxHeight: "calc(100vh - 180px)",
        overflow: "auto",
      }}
    >
      <Typography variant="h6" component="h6">
        <strong>At Risk {alertType} Moments</strong>
      </Typography>

      {isLoading ? (
        <CentredBox>
          <CircularProgress size={64} />
        </CentredBox>
      ) : selectedAlertData.length ? (
        selectedAlertData &&
        selectedAlertData.map((section, index) => {
          return (
            <List
              key={index}
              subheader={
                <ListSubheader
                  disableGutters
                  classes={{ root: classes.listSubHeaderRoot }}
                >
                  <Typography
                    sx={{
                      ml: 1,
                    }}
                    variant="body1"
                  >
                    {section.header}
                  </Typography>
                </ListSubheader>
              }
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: 1,
              }}
            >
              {section.data &&
                section.data.map((alert: Alert, index: any) => (
                  <MomentCard
                    key={index}
                    index={alert.id}
                    type={alertType}
                    data={alert}
                    selected={selectedMomentId === alert.id}
                    onClick={onClick}
                  />
                ))}
            </List>
          );
        })
      ) : (
        <Typography
          sx={{
            textAlign: "center",
            mb: "10px",
          }}
          variant="h6"
          component="h6"
        >
          No Alerts to Show
        </Typography>
      )}
    </Box>
  );
};
