import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import useQuery from "../../helpers/hooks/useQuery";
import PathCircularProgress from "../PathCircularProgress";
import { Box } from "@mui/material";
import accountService from "../../services/AccountService";

const VerifyEmailComponent = () => {
  let query = useQuery();
  let accountToken = query.get("token");
  const [statusMessage, setStatusMessage] = useState(
    "Please wait while we verify your email address"
  );
  const [verificationError, setVerificationError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (accountToken) {
      accountService
        .verifyEmail(accountToken)
        .then((response: any) => {
          setStatusMessage(response.body.title);
          setVerificationError(false);
          setIsLoading(false);
        })
        .catch((error: any) => {
          setStatusMessage(error.body.title);
          setVerificationError(true);
          setIsLoading(false);
        });
    }
  }, [accountToken]);

  return (
    <>
      <Typography variant="h4" sx={{ mt: 8 }}>
        <strong>Verify Email</strong>
      </Typography>
      <Box
        sx={{
          mt: 2,
          mb: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {isLoading ? <PathCircularProgress /> : null}
        <Typography variant="body1" sx={{ mt: 1 }}>
          {statusMessage}
        </Typography>
        {verificationError && (
          <Typography variant="body1" sx={{ mt: 1 }}>
            Please contact an administrator for further help
          </Typography>
        )}
      </Box>
    </>
  );
};

export default VerifyEmailComponent;
