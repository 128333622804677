import React, { useState } from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";

import AdminHeader from "./AdminHeader";
import HealthcareProfessionalAccountsComponent from "./HealthcareProfessionalAccountsComponent";
import InviteUserComponent from "./InviteUserComponent";
import ManageInvitesComponent from "./ManageInvitesComponent";
import PatientAccountsComponent from "./PatientAccountsComponent";
import { selectAccount } from "../../../redux/selectors/accountSelectors";
import { useAppThunkDispatch } from "../../../redux/configureStore";
import {
  getActiveInvites,
  getAllHcpAccounts,
  getAllPatientAccounts,
} from "../../../redux/actions/adminActions";

export const AdminPanelPage: React.FC = () => {
  const account = useSelector(selectAccount);
  const dispatch = useAppThunkDispatch();
  const [isHcpAccountsLoading, setiIsHcpAccountsLoading] = useState(false);
  const [isPatientAccountsLoading, setIsPatientAccountsLoading] =
    useState(false);
  const [isManageInvitesLoading, setIsManageInvitesLoading] = useState(false);

  const handleRefreshPendingInvites = () => {
    setIsManageInvitesLoading(true);
    dispatch(getActiveInvites()).then((response: any) => {
      setIsManageInvitesLoading(false);
    });
  };

  const handleRefreshAdmin = () => {
    if (account.accountGuid) {
      setiIsHcpAccountsLoading(true);
      setIsPatientAccountsLoading(true);
      handleRefreshPendingInvites();

      dispatch(getAllHcpAccounts()).then((response: any) => {
        setiIsHcpAccountsLoading(false);
      });
      dispatch(getAllPatientAccounts()).then((response: any) => {
        setIsPatientAccountsLoading(false);
      });
    }
  };

  return (
    <Box>
      <AdminHeader onRefreshAdmin={handleRefreshAdmin} />
      <InviteUserComponent handleRefreshInvites={handleRefreshPendingInvites} />
      <ManageInvitesComponent isLoading={isManageInvitesLoading} />
      <HealthcareProfessionalAccountsComponent
        isLoading={isHcpAccountsLoading}
      />
      <PatientAccountsComponent isLoading={isPatientAccountsLoading} />
    </Box>
  );
};

export default AdminPanelPage;
