import { CalculatedPassiveParameterModel } from "../../../model/passive/calculatedPassiveParameterModel";
import { PassiveParameterTypeEnum } from "../../../model/passive/enum/passiveParameterTypeEnum";
import { InsoleFootEnum } from "../../../model/passive/enum/insoleFootEnum";
import { InsoleUsageData } from "../InsoleUsageData";
import { AverageAsymmetryCardData } from "./AverageAsymmetry/averageAsymmetryCardData";
import { AverageCadenceCardData } from "./AverageCadence/averageCadenceCardData";
import {
  AverageCycleDuration,
  AverageCycleDurationCardData,
} from "./AverageCycleDuration/averageCycleDurationCardData";
import {
  AveragePeakAngleVelocity,
  AveragePeakAngleVelocityCardData,
} from "./AveragePeakAngleVelocity/averagePeakAngleVelocityCardData";
import {
  AverageStrideAngles,
  AverageStrideAnglesCardData,
} from "./AverageStrideAnglesCard/AverageStrideAnglesCardData";
import {
  AverageDoubleSupport,
  AverageSwingAverageStance,
  AverageSwingAverageStanceCardData,
} from "./AverageSwingAverageStanceCard/averageSwingAverageStanceCardData";
import {
  AverageSwingSpeed,
  AverageSwingSpeedCardData,
} from "./AverageSwingSpeed/averageSwingSpeedCardData";
import { AverageVariabilityCardData } from "./AverageVariability/averageVariabilityCardData";
import { AverageWalkingSpeedCardData } from "./AverageWalkingSpeed/averageWalkingSpeedCardData";
import { TotalDistanceCardData } from "./TotalDistance/totalDistanceCardData";
import { TotalStepsCardData } from "./TotalSteps/totalStepsCardData";

export const getDailyParameters = (
  dailyParameterData: CalculatedPassiveParameterModel[],
  type: PassiveParameterTypeEnum
) => {
  switch (type) {
    case PassiveParameterTypeEnum.AverageStrideLength: {
      const data = dailyParameterData.filter(
        (x) => x.type === PassiveParameterTypeEnum.AverageStrideLength
      )[0];
      const items: AverageStrideAngles[] =
        data && data.value ? JSON.parse(data.value) : null;
      const left = items
        ? items.filter((x) => x.foot === InsoleFootEnum.Left)[0]
        : null;
      const right = items
        ? items.filter((x) => x.foot === InsoleFootEnum.Right)[0]
        : null;

      const averageStrideAnglesCardData: AverageStrideAnglesCardData = {
        left: {
          title: "Left foot",
          heel: left?.heel || "0",
          angleUnit: left?.angleUnit || "0",
          toe: left?.toe || "0",
          length: left?.length || "0",
          lengthUnit: left?.lengthUnit || "0",
          foot: InsoleFootEnum.Left,
        },
        right: {
          title: "Right foot",
          heel: right?.heel || "0",
          angleUnit: right?.angleUnit || "0",
          toe: right?.toe || "0",
          length: right?.length || "0",
          lengthUnit: right?.lengthUnit || "0",
          foot: InsoleFootEnum.Right,
        },
        unit: data?.unit || "N/A",
      };
      return averageStrideAnglesCardData;
    }

    case PassiveParameterTypeEnum.TotalDistance: {
      const distanceRecord = dailyParameterData.filter(
        (x) => x.type === PassiveParameterTypeEnum.TotalDistance
      )[0];

      const parsedValue = JSON.parse(
        distanceRecord ? distanceRecord.value : "{}"
      );
      const distance: TotalDistanceCardData = {
        value: parsedValue?.value || "",
        unit: distanceRecord?.unit || "0",
      };
      return distance;
    }

    case PassiveParameterTypeEnum.AverageWalkingSpeed: {
      const speedRecord = dailyParameterData.filter(
        (x) => x.type === PassiveParameterTypeEnum.AverageWalkingSpeed
      )[0];

      const parsedValue = JSON.parse(speedRecord ? speedRecord.value : "{}");
      const cadence: AverageWalkingSpeedCardData = {
        value: parsedValue?.value || "",
        unit: speedRecord?.unit || "0",
      };
      return cadence;
    }

    case PassiveParameterTypeEnum.AverageAsymmetry: {
      const asymmetryRecord = dailyParameterData.filter(
        (x) => x.type === PassiveParameterTypeEnum.AverageAsymmetry
      )[0];

      const parsedValue = JSON.parse(
        asymmetryRecord ? asymmetryRecord.value : "{}"
      );
      const asymmetry: AverageAsymmetryCardData = {
        value: parsedValue?.value || "",
        unit: asymmetryRecord?.unit || "0",
      };
      return asymmetry;
    }

    case PassiveParameterTypeEnum.AverageCadence: {
      const cadenceRecord = dailyParameterData.filter(
        (x) => x.type === PassiveParameterTypeEnum.AverageCadence
      )[0];
      const parsedValue = JSON.parse(
        cadenceRecord ? cadenceRecord.value : "{}"
      );
      const cadence: AverageCadenceCardData = {
        value: parsedValue?.value || "",
        unit: cadenceRecord?.unit || "0",
      };
      return cadence;
    }

    case PassiveParameterTypeEnum.TotalSteps: {
      const stepsRecord = dailyParameterData.filter(
        (x) => x.type === PassiveParameterTypeEnum.TotalSteps
      )[0];

      const parsedValue = JSON.parse(stepsRecord ? stepsRecord.value : "{}");
      const steps: TotalStepsCardData = {
        value: parsedValue?.value || "",
        unit: stepsRecord?.unit || "0",
      };
      return steps;
    }

    case PassiveParameterTypeEnum.AverageVariability: {
      const variabilityRecord = dailyParameterData.filter(
        (x) => x.type === PassiveParameterTypeEnum.AverageVariability
      )[0];

      const parsedValue = JSON.parse(
        variabilityRecord ? variabilityRecord.value : "{}"
      );
      const variability: AverageVariabilityCardData = {
        value: parsedValue?.value || "",
        unit: variabilityRecord?.unit || "0",
      };
      return variability;
    }

    case PassiveParameterTypeEnum.AverageCycleDuration: {
      const data = dailyParameterData.filter(
        (x) => x.type === PassiveParameterTypeEnum.AverageCycleDuration
      )[0];

      const items: AverageCycleDuration[] =
        data && data.value ? JSON.parse(data.value) : null;
      const left = items
        ? items.filter((x) => x.foot === InsoleFootEnum.Left)[0]
        : null;
      const right = items
        ? items.filter((x) => x.foot === InsoleFootEnum.Right)[0]
        : null;

      const averageCycleDurationCardData: AverageCycleDurationCardData = {
        left: {
          title: "Left",
          value: left?.value || "0",
          foot: InsoleFootEnum.Left,
        },
        right: {
          title: "Right foot",
          value: right?.value || "0",
          foot: InsoleFootEnum.Right,
        },
        unit: data?.unit || "0",
      };
      return averageCycleDurationCardData;
    }

    case PassiveParameterTypeEnum.AverageSwingSpeed: {
      const data = dailyParameterData.filter(
        (x) => x.type === PassiveParameterTypeEnum.AverageSwingSpeed
      )[0];

      const items: AverageSwingSpeed[] =
        data && data.value ? JSON.parse(data.value) : null;
      const left = items
        ? items.filter((x) => x.foot === InsoleFootEnum.Left)[0]
        : null;
      const right = items
        ? items.filter((x) => x.foot === InsoleFootEnum.Right)[0]
        : null;

      const averageSwingSpeedCardData: AverageSwingSpeedCardData = {
        left: {
          title: "Left",
          value: left?.value || "0",

          foot: InsoleFootEnum.Left,
        },
        right: {
          title: "Right",
          value: right?.value || "0",
          foot: InsoleFootEnum.Right,
        },
        unit: data?.unit || "N/A",
      };
      return averageSwingSpeedCardData;
    }

    case PassiveParameterTypeEnum.AveragePeakAngleVelocity: {
      const data = dailyParameterData.filter(
        (x) => x.type === PassiveParameterTypeEnum.AveragePeakAngleVelocity
      )[0];
      const items: AveragePeakAngleVelocity[] =
        data && data.value ? JSON.parse(data.value) : null;
      const left = items
        ? items.filter((x) => x.foot === InsoleFootEnum.Left)[0]
        : null;
      const right = items
        ? items.filter((x) => x.foot === InsoleFootEnum.Right)[0]
        : null;

      const averagePeakAngleVelocityCardData: AveragePeakAngleVelocityCardData =
        {
          left: {
            title: "Left",
            value: left?.value || "0",
            unit: left?.unit || "°/s",
            foot: InsoleFootEnum.Left,
          },
          right: {
            title: "Right",
            value: right?.value || "0",
            unit: right?.unit || "°/s",
            foot: InsoleFootEnum.Right,
          },
        };
      return averagePeakAngleVelocityCardData;
    }

    case PassiveParameterTypeEnum.AverageSwing: {
      const data = dailyParameterData.filter(
        (x) => x.type === PassiveParameterTypeEnum.AverageSwing
      )[0];
      const items: AverageSwingAverageStance[] =
        data && data.value ? JSON.parse(data.value) : null;
      const left = items
        ? items.filter((x) => x.foot === InsoleFootEnum.Left)[0]
        : null;
      const right = items
        ? items.filter((x) => x.foot === InsoleFootEnum.Right)[0]
        : null;

      const doubleSupportRecord = dailyParameterData.filter(
        (x) => x.type === PassiveParameterTypeEnum.AverageDoubleSupport
      )[0];

      const doubleSupport: AverageDoubleSupport = JSON.parse(
        doubleSupportRecord ? doubleSupportRecord.value : "{}"
      );

      const averageSwingAverageStanceCardData: AverageSwingAverageStanceCardData =
        {
          left: {
            swing: left?.swing || "0",
            stance: left?.stance || "0",
            unit: left?.unit || "%",
            foot: InsoleFootEnum.Left,
          },
          right: {
            swing: right?.swing || "0",
            stance: right?.stance || "0",
            unit: right?.unit || "%",
            foot: InsoleFootEnum.Right,
          },
          averageDoubleSupport: {
            value: doubleSupport?.value || "0",
            unit: doubleSupport?.unit || "%",
          },
        };
      return averageSwingAverageStanceCardData;
    }

    case PassiveParameterTypeEnum.InsoleUsage: {
      const usageRecord = dailyParameterData.filter(
        (x) => x.type === PassiveParameterTypeEnum.InsoleUsage
      )[0];

      const usage: InsoleUsageData = JSON.parse(
        usageRecord ? usageRecord.value : "{}"
      );
      return usage;
    }

    default: {
      //we should never get here - maybe return back a default value/measurement?
      break;
    }
  }
};
