import * as React from "react";
import { useSelector } from "react-redux";
import { Avatar, Box, Typography } from "@mui/material";
//import avatar from "../../assets/images/avatar.jpg";
import AccountMenu from "./AccountMenu";
import { selectAccount } from "../../redux/selectors/accountSelectors";

export default function AccountPanel() {
  const account = useSelector(selectAccount);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Avatar alt="Dr Avatar" sx={{ width: 40, height: 40 }} />
      <Typography sx={{ color: "#58595B", ml: 2 }}>
        {account.firstName} {account.lastName}
      </Typography>
      <AccountMenu />
    </Box>
  );
}
