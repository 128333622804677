import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import AverageStrideAnglesCard from "../../../../components/Cards/DailyParameters/AverageStrideAnglesCard/AverageStrideAnglesCard";
import TotalDistanceCard from "../../../../components/Cards/DailyParameters/TotalDistance/TotalDistanceCard";
import Scrollable from "../../../../components/Scrollable";
import AverageWalkingSpeedCard from "../../../../components/Cards/DailyParameters/AverageWalkingSpeed/AverageWalkingSpeedCard";
import { selectedDailyParameterData } from "../../../../redux/selectors/dailyParametersSelectors";
import { AverageStrideAnglesCardData } from "../../../../components/Cards/DailyParameters/AverageStrideAnglesCard/AverageStrideAnglesCardData";
import { getDailyParameters } from "../../../../components/Cards/DailyParameters/cardFunctions";
import { PassiveParameterTypeEnum } from "../../../../model/passive/enum/passiveParameterTypeEnum";
import AverageAsymmetryCard from "../../../../components/Cards/DailyParameters/AverageAsymmetry/AverageAsymmetryCard";
import AverageCadenceCard from "../../../../components/Cards/DailyParameters/AverageCadence/AverageCadenceCard";
import TotalStepsCard from "../../../../components/Cards/DailyParameters/TotalSteps/TotalStepsCard";
import AverageVariabilityCard from "../../../../components/Cards/DailyParameters/AverageVariability/AverageVariabilityCard";
import AverageCycleDurationCard from "../../../../components/Cards/DailyParameters/AverageCycleDuration/AverageCycleDurationCard";
import AverageSwingSpeedCard from "../../../../components/Cards/DailyParameters/AverageSwingSpeed/AverageSwingSpeedCard";
import AverageSwingAverageStanceCard from "../../../../components/Cards/DailyParameters/AverageSwingAverageStanceCard/AverageSwingAverageStanceCard";
import { TotalDistanceCardData } from "../../../../components/Cards/DailyParameters/TotalDistance/totalDistanceCardData";
import { AverageWalkingSpeedCardData } from "../../../../components/Cards/DailyParameters/AverageWalkingSpeed/averageWalkingSpeedCardData";
import { AverageAsymmetryCardData } from "../../../../components/Cards/DailyParameters/AverageAsymmetry/averageAsymmetryCardData";
import { AverageCadenceCardData } from "../../../../components/Cards/DailyParameters/AverageCadence/averageCadenceCardData";
import { TotalStepsCardData } from "../../../../components/Cards/DailyParameters/TotalSteps/totalStepsCardData";
import { AverageVariabilityCardData } from "../../../../components/Cards/DailyParameters/AverageVariability/averageVariabilityCardData";
import { AverageCycleDurationCardData } from "../../../../components/Cards/DailyParameters/AverageCycleDuration/averageCycleDurationCardData";
import { AverageSwingSpeedCardData } from "../../../../components/Cards/DailyParameters/AverageSwingSpeed/averageSwingSpeedCardData";
import { AverageSwingAverageStanceCardData } from "../../../../components/Cards/DailyParameters/AverageSwingAverageStanceCard/averageSwingAverageStanceCardData";
import ControlVisibility from "../../../../components/Settings/ControlVisibility";

interface Props {
  patientId?: string;
}

export const DailyParametersHomeDetails: React.FC<Props> = ({ patientId }) => {
  let navigate = useNavigate();

  const dailyParameterData = useSelector(selectedDailyParameterData);

  const [averageStrideAnglesCardData, setAverageStrideAnglesCardData] =
    useState<AverageStrideAnglesCardData>();

  const [totalDistanceCardData, setTotalDistanceCardData] =
    useState<TotalDistanceCardData>();

  const [averageWalkingSpeedCardData, setAverageWalkingSpeedCardData] =
    useState<AverageWalkingSpeedCardData>();

  const [averageAsymmetryCardData, setAverageAsymmetryCardData] =
    useState<AverageAsymmetryCardData>();

  const [averageCadenceCardData, setAverageCadenceCardData] =
    useState<AverageCadenceCardData>();

  const [totalStepsCardData, setTotalStepsCardData] =
    useState<TotalStepsCardData>();

  const [averageVariabilityCardData, setAverageVariabilityCardData] =
    useState<AverageVariabilityCardData>();

  const [averageCycleDurationCardData, setAverageCycleDurationCardData] =
    useState<AverageCycleDurationCardData>();

  const [averageSwingSpeedCardData, setAverageSwingSpeedCardData] =
    useState<AverageSwingSpeedCardData>();

  const [
    averageSwingAverageStanceCardData,
    setAverageSwingAverageStanceCardData,
  ] = useState<AverageSwingAverageStanceCardData>();

  /*  const [
    averagePeakAngleVelocityCardData,
    setAveragePeakAngleVelocityCardData,
  ] = useState<AveragePeakAngleVelocityCardData>(); */

  useEffect(() => {
    setAverageStrideAnglesCardData(
      getDailyParameters(
        dailyParameterData!,
        PassiveParameterTypeEnum.AverageStrideLength
      ) as AverageStrideAnglesCardData
    );

    setTotalDistanceCardData(
      getDailyParameters(
        dailyParameterData!,
        PassiveParameterTypeEnum.TotalDistance
      ) as TotalDistanceCardData
    );

    setAverageWalkingSpeedCardData(
      getDailyParameters(
        dailyParameterData!,
        PassiveParameterTypeEnum.AverageWalkingSpeed
      ) as AverageWalkingSpeedCardData
    );

    setAverageAsymmetryCardData(
      getDailyParameters(
        dailyParameterData!,
        PassiveParameterTypeEnum.AverageAsymmetry
      ) as AverageAsymmetryCardData
    );

    setAverageCadenceCardData(
      getDailyParameters(
        dailyParameterData!,
        PassiveParameterTypeEnum.AverageCadence
      ) as AverageCadenceCardData
    );

    setTotalStepsCardData(
      getDailyParameters(
        dailyParameterData!,
        PassiveParameterTypeEnum.TotalSteps
      ) as TotalStepsCardData
    );

    setAverageVariabilityCardData(
      getDailyParameters(
        dailyParameterData!,
        PassiveParameterTypeEnum.AverageVariability
      ) as AverageVariabilityCardData
    );

    setAverageCycleDurationCardData(
      getDailyParameters(
        dailyParameterData!,
        PassiveParameterTypeEnum.AverageCycleDuration
      ) as AverageCycleDurationCardData
    );

    setAverageSwingSpeedCardData(
      getDailyParameters(
        dailyParameterData!,
        PassiveParameterTypeEnum.AverageSwingSpeed
      ) as AverageSwingSpeedCardData
    );
    /* 
    setAveragePeakAngleVelocityCardData(
      getDailyParameters(
        dailyParameterData!,
        PassiveParameterTypeEnum.AveragePeakAngleVelocity
      ) as AveragePeakAngleVelocityCardData
    ); */

    setAverageSwingAverageStanceCardData(
      getDailyParameters(
        dailyParameterData!,
        PassiveParameterTypeEnum.AverageSwing
      ) as AverageSwingAverageStanceCardData
    );

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyParameterData]);

  const handleCardClick = (type: PassiveParameterTypeEnum) => {
    navigate(
      `/dashboard/patients/${patientId}/dailyparameters/${PassiveParameterTypeEnum[type]}`
    );
  };

  return (
    <Scrollable>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <ControlVisibility value="totalDistance">
          <Grid item>
            <TotalDistanceCard
              data={totalDistanceCardData}
              onClick={() =>
                handleCardClick(PassiveParameterTypeEnum.TotalDistance)
              }
            />
          </Grid>
        </ControlVisibility>
        <ControlVisibility value="averageWalkingSpeed">
          <Grid item>
            <AverageWalkingSpeedCard
              data={averageWalkingSpeedCardData}
              onClick={() =>
                handleCardClick(PassiveParameterTypeEnum.AverageWalkingSpeed)
              }
            />
          </Grid>
        </ControlVisibility>
        <ControlVisibility value="averageAsymmetry">
          <Grid item>
            <AverageAsymmetryCard
              data={averageAsymmetryCardData}
              onClick={() =>
                handleCardClick(PassiveParameterTypeEnum.AverageAsymmetry)
              }
            />
          </Grid>
        </ControlVisibility>
        <ControlVisibility value="averageCadence">
          <Grid item>
            <AverageCadenceCard
              data={averageCadenceCardData}
              onClick={() =>
                handleCardClick(PassiveParameterTypeEnum.AverageCadence)
              }
            />
          </Grid>
        </ControlVisibility>
        <ControlVisibility value="totalSteps">
          <Grid item>
            <TotalStepsCard
              data={totalStepsCardData}
              onClick={() =>
                handleCardClick(PassiveParameterTypeEnum.TotalSteps)
              }
            />
          </Grid>
        </ControlVisibility>

        <ControlVisibility value="averageVariability">
          <Grid item>
            <AverageVariabilityCard
              data={averageVariabilityCardData}
              onClick={() =>
                handleCardClick(PassiveParameterTypeEnum.AverageVariability)
              }
            />
          </Grid>
        </ControlVisibility>
        <ControlVisibility value="averageCycleDuration">
          <Grid item>
            <AverageCycleDurationCard
              data={averageCycleDurationCardData}
              onClick={() =>
                handleCardClick(PassiveParameterTypeEnum.AverageCycleDuration)
              }
            />
          </Grid>
        </ControlVisibility>
        <ControlVisibility value="averageSwingSpeed">
          <Grid item>
            <AverageSwingSpeedCard
              data={averageSwingSpeedCardData}
              onClick={() =>
                handleCardClick(PassiveParameterTypeEnum.AverageSwingSpeed)
              }
            />
          </Grid>
        </ControlVisibility>
        <ControlVisibility value="averageStrideLength">
          <Grid item>
            <AverageStrideAnglesCard
              data={averageStrideAnglesCardData}
              onClick={() =>
                handleCardClick(PassiveParameterTypeEnum.AverageStrideLength)
              }
            />
          </Grid>
        </ControlVisibility>
        <ControlVisibility value="averageSwing">
          <Grid item>
            <AverageSwingAverageStanceCard
              data={averageSwingAverageStanceCardData}
              onClick={() =>
                handleCardClick(PassiveParameterTypeEnum.AverageSwing)
              }
            />
          </Grid>
        </ControlVisibility>
      </Grid>
    </Scrollable>
  );
};

export default DailyParametersHomeDetails;
