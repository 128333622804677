import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import AverageWalkingSpeedDetailHeader from "./AverageWalkingSpeedDetailHeader";
import AverageWalkingSpeedDetailContent from "./AverageWalkingSpeedDetailContent";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column",
    height: "100vh",
  },
}));

const AverageWalkingSpeedDetail: React.FC = () => {
  const classes = useStyles();
  const { patientId } = useParams();
  const [selectedDateRange, setSelectedDateRange] = useState<
    [Date | null, Date | null]
  >([new Date(), new Date()]);

  return (
    <div className={classes.root}>
      <AverageWalkingSpeedDetailHeader
        patientId={patientId}
        selectedDateRange={selectedDateRange}
        setSelectedDateRange={setSelectedDateRange}
      />
      <AverageWalkingSpeedDetailContent
        patientId={patientId}
        selectedDateRange={selectedDateRange}
      />
    </div>
  );
};

export default AverageWalkingSpeedDetail;
