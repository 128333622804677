import { Patient } from "../../model/patient/patient";
import { Box, Grid, Typography } from "@mui/material";
import PatientValue from "./PatientValue";
import { styled } from "@mui/material/styles";

interface PatientDetailsHeaderProps {
  selectedPatient?: Patient;
}

const ParameterTitleTypographyStyle = styled(Typography)(({ theme }) => ({
  display: "inline",
  component: "p",
  variant: "h3",
  color: theme.palette.primary.main,
}));

function PatientDetailsHeader({
  selectedPatient,
}: PatientDetailsHeaderProps): JSX.Element {
  return (
    <Box sx={{ textAlign: "left" }}>
      <PatientValue patient={selectedPatient} patientKey="name" />
      <Grid container spacing={1}>
        <Grid item>
          <ParameterTitleTypographyStyle>
            Gender:{" "}
          </ParameterTitleTypographyStyle>
          <PatientValue
            patient={selectedPatient}
            patientKey="gender"
            display="inline"
            variant="body1"
            component="p"
          />
        </Grid>
        <Grid item>
          <ParameterTitleTypographyStyle>Age: </ParameterTitleTypographyStyle>
          <PatientValue
            patient={selectedPatient}
            patientKey="dateOfBirth"
            display="inline"
            variant="body1"
            component="p"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default PatientDetailsHeader;
