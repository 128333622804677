import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Tooltip, Typography } from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import AlertCircle from "../../../components/AlertCircle";
import NoData from "../../../components/NoData";
import PathCircularProgress from "../../../components/PathCircularProgress";
import { ThreeDotsMenu } from "../../../components/documented/ThreeDotsMenu";
import { selectPatients } from "../../../redux/selectors/patientSelectors";
import { selectAccountId } from "../../../redux/selectors/accountSelectors";
import { useAppThunkDispatch } from "../../../redux/configureStore";
import { getPatients } from "../../../redux/actions/healthcareProfessionalActions";
import { Patient } from "../../../model/patient/patient";
import { healthcareProfessionalService } from "../../../services/healthcareProfessionalService";
import { pluralize } from "../../../helpers/utilityFunctions/utilityFunctions";
import { useClientTheme } from "../../../helpers/hooks/useClientTheme";
import { clearSelectedDailyMetrics } from "../../../redux/actions/dailyMetricActions";
import { styled } from "@mui/material/styles";

import {
  getComparator,
  Order,
  StyledTableCell,
  StyledTableRow,
} from "../TableComponents";

const TableDetailContainer = styled(Box)(() => ({
  height: "fit-content",
  width: "100%",
  padding: "10px 20px 10px 20px",
}));

export interface HeadCell {
  id: keyof Patient;
  label: string;
  numeric: boolean;
  toolTip?: string;
}

export interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Patient
  ) => void;
  order: Order;
  orderBy: string;
}

function AuthorisedPatientsTableHead(props: EnhancedTableProps) {
  const { theme } = useClientTheme();
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Patient) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const headCells: readonly HeadCell[] = [
    {
      id: "firstName",
      numeric: false,
      label: "First Name",
    },
    {
      id: "lastName",
      numeric: false,
      label: "Last Name",
    },
    {
      id: "totalDaysOfInsoleUsageLastMonth",
      numeric: true,
      label: "Days of use previous month",
      toolTip:
        "Number of days of use in the previous calendar month. One day is defined by 30 mins or more of use.",
    },
    {
      id: "totalDaysOfInsoleUsageCurrentMonth",
      numeric: true,
      label: "Days of use current month",
      toolTip:
        "Number of days of use in the current calendar month. One day is defined by 30 mins or more of use.",
    },
    {
      id: "insoleLastUsageDate",
      numeric: false,
      label: "Last day of use",
    },
    {
      id: "email",
      numeric: false,
      label: "Email",
    },
    {
      id: "alertLevel",
      numeric: true,
      label: "Alert",
      toolTip:
        "A red dot appears if there has been one or more pressure or temperature alerts in the last 3 days. The dot’s color is linked to the highest alert level.",
    },
    {
      id: "verified",
      numeric: false,
      label: `${theme.productNames.insoles} user since`,
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            /* slight hack to center to the alert level header differently to the rest*/
            align={headCell.id !== "alertLevel" ? "left" : "center"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <Tooltip title={headCell.toolTip || ""}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </Tooltip>
          </StyledTableCell>
        ))}
        <StyledTableCell align="left">User Actions</StyledTableCell>
      </TableRow>
    </TableHead>
  );
}

interface Props {
  isLoading: boolean;
}

export const AuthorisedPatientsTable: React.FC<Props> = ({ isLoading }) => {
  const patients = useSelector(selectPatients);
  const accountId = useSelector(selectAccountId);
  const navigate = useNavigate();
  const dispatch = useAppThunkDispatch();
  const { theme } = useClientTheme();

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Patient>("lastName");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Patient
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (
    event: React.MouseEvent<unknown>,
    accountGuid: string
  ) => {
    const selectedPatientData = patients.find(
      (data) => data.accountGuid === accountGuid
    );
    window.localStorage.setItem(
      "selectedPatientFromPatientsTable",
      JSON.stringify(selectedPatientData)
    );
    dispatch(clearSelectedDailyMetrics());
    navigate(`/dashboard/patients/${accountGuid}`);
  };

  const handleRemovePatient = (patientId: string) => {
    healthcareProfessionalService
      .revokePatient(accountId, patientId)
      .then((response: any) => {
        dispatch(getPatients(accountId, true));
      })
      .catch((error: any) => {});
  };

  const RenderAlertColour = (level: number) => {
    switch (level) {
      case 0:
        return "transparent";
      case 1:
        return theme.palette.alertLevel1.main;
      case 2:
        return theme.palette.alertLevel2.main;
      case 3:
        return theme.palette.alertLevel3.main;
      default:
        return "transparent";
    }
  };
  return (
    <TableDetailContainer>
      <Typography
        sx={{ padding: "5px 0px 5px 0px" }}
        variant="h5"
        gutterBottom
        component="h2"
      >
        Your Patients
      </Typography>
      {!isLoading ? (
        patients.length ? (
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer sx={{ maxHeight: 980, minWidth: 750 }}>
              <Table stickyHeader aria-labelledby="authorised-patients-table">
                <AuthorisedPatientsTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {patients
                    .slice()
                    .sort(getComparator(order, orderBy))
                    .map((patient) => {
                      return (
                        <StyledTableRow
                          hover
                          tabIndex={-1}
                          key={patient.accountGuid}
                        >
                          <StyledTableCell
                            align="left"
                            onClick={(event) =>
                              handleClick(event, patient.accountGuid)
                            }
                          >
                            {patient.firstName}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            onClick={(event) =>
                              handleClick(event, patient.accountGuid)
                            }
                          >
                            {patient.lastName}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            onClick={(event) =>
                              handleClick(event, patient.accountGuid)
                            }
                          >
                            {pluralize(
                              patient.totalDaysOfInsoleUsageLastMonth,
                              "day"
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            onClick={(event) =>
                              handleClick(event, patient.accountGuid)
                            }
                          >
                            {pluralize(
                              patient.totalDaysOfInsoleUsageCurrentMonth,
                              "day"
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            onClick={(event) =>
                              handleClick(event, patient.accountGuid)
                            }
                          >
                            {new Date(
                              patient.insoleLastUsageDate
                            ).toDateString()}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            onClick={(event) =>
                              handleClick(event, patient.accountGuid)
                            }
                          >
                            {patient.email}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            onClick={(event) =>
                              handleClick(event, patient.accountGuid)
                            }
                          >
                            <Box
                              display="flex"
                              justifyContent="center"
                              mr="18px"
                            >
                              <Tooltip title="This patient had one or more temperature/pressure alerts in the past 3 days">
                                <AlertCircle
                                  fill={RenderAlertColour(patient.alertLevel)}
                                />
                              </Tooltip>
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            onClick={(event) =>
                              handleClick(event, patient.accountGuid)
                            }
                          >
                            {new Date(patient.verified).toDateString()}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <ThreeDotsMenu
                              data={patient.accountGuid}
                              handleAction={handleRemovePatient}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        ) : (
          <NoData message="You have no authorised patients. Click the + button above to add a patient" />
        )
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          height="100px"
          justifyContent="center"
        >
          <PathCircularProgress />
        </Box>
      )}
    </TableDetailContainer>
  );
};

export default AuthorisedPatientsTable;
