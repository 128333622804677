import { Dispatch } from "redux";
import {
  FETCH_DAILY_METRIC_DATES,
  FETCH_DAILY_METRIC_DATES_SUCCESS,
  FETCH_DAILY_METRIC_DATES_FAILURE,
  FETCH_DAILY_METRICS,
  FETCH_DAILY_METRICS_SUCCESS,
  FETCH_DAILY_METRICS_FAILURE,
  CLEAR_SELECTED_DAILY_METRICS,
  SELECT_DAILY_METRIC_DATE,
} from "../constants";
import { DailyMetricsActionTypes } from "../types/dailyMetricsActionTypes";
import { DailyMetrics } from "../../model/passive/dailyMetrics";
import { Temperature } from "../../model/temperature";
import { celsiusToFahrenheit } from "../../helpers/utilityFunctions/utilityFunctions";
import dailyMetricsService from "../../services/DailyMetricsService";

export function fetchDailyMetricDates(): DailyMetricsActionTypes {
  return {
    type: FETCH_DAILY_METRIC_DATES,
  };
}

export function fetchDailyMetricDatesSuccess(
  data: any
): DailyMetricsActionTypes {
  return {
    type: FETCH_DAILY_METRIC_DATES_SUCCESS,
    payload: data,
  };
}

export function fetchDailyMetricDatesError(
  error: string
): DailyMetricsActionTypes {
  return {
    type: FETCH_DAILY_METRIC_DATES_FAILURE,
    payload: error,
  };
}

export function getDailyMetricDates(deviceId: string) {
  return function action(dispatch: Dispatch) {
    dispatch(fetchDailyMetricDates());

    return dailyMetricsService.getDailyMetricDates(deviceId).then(
      (response: any) => dispatch(fetchDailyMetricDatesSuccess(response))
      //error => dispatch(someerror('woody', buzz, error))
    );
  };
}

export function getDailyMetricDatesByRange(
  deviceId: string,
  startDate: string,
  endDate: string
) {
  return function action(dispatch: Dispatch) {
    dispatch(fetchDailyMetricDates());

    return dailyMetricsService
      .getDailyMetricDatesByRange(deviceId, startDate, endDate)
      .then(
        (response: any) => dispatch(fetchDailyMetricDatesSuccess(response))
        //error => dispatch(someerror('woody', buzz, error))
      );
  };
}

export function setDailyMetricDate(date: Date): DailyMetricsActionTypes {
  return {
    type: SELECT_DAILY_METRIC_DATE,
    payload: date,
  };
}

//Metrics

export function fetchDailyMetrics(): DailyMetricsActionTypes {
  return {
    type: FETCH_DAILY_METRICS,
  };
}

export function clearSelectedDailyMetrics(): DailyMetricsActionTypes {
  return {
    type: CLEAR_SELECTED_DAILY_METRICS,
  };
}

export function fetchDailyMetricsSuccess(
  data: DailyMetrics
): DailyMetricsActionTypes {
  return {
    type: FETCH_DAILY_METRICS_SUCCESS,
    payload: data,
  };
}

export function fetchDailyMetricsError(error: string): DailyMetricsActionTypes {
  return {
    type: FETCH_DAILY_METRICS_FAILURE,
    payload: error,
  };
}

export function getDailyMetrics(
  patientId: string,
  date: Date,
  metrics: any = undefined
) {
  return function action(dispatch: Dispatch) {
    dispatch(fetchDailyMetrics());
    return dailyMetricsService.getDailyMetrics(patientId, date).then(
      (response: any) => {
        if (
          response.latestAlertsTemperature &&
          metrics &&
          metrics === Temperature.Fahrenheit
        ) {
          const sensorDataLeft: any[] = JSON.parse(
            response.latestAlertsTemperature.sensorDataLeft
          );
          const sensorDataRight: any[] = JSON.parse(
            response.latestAlertsTemperature.sensorDataRight
          );
          sensorDataLeft.forEach((o: any, i: any, a: any) => {
            a[i] = celsiusToFahrenheit(o);
          });
          sensorDataRight.forEach((o: any, i: any, a: any) => {
            a[i] = celsiusToFahrenheit(o);
          });

          response.latestAlertsTemperature.sensorDataLeft =
            JSON.stringify(sensorDataLeft);
          response.latestAlertsTemperature.sensorDataRight =
            JSON.stringify(sensorDataRight);
        }

        dispatch(fetchDailyMetricsSuccess(response));
      }
      //error => dispatch(someerror('woody', buzz, error))
    );
  };
}
