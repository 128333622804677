import * as React from "react";
import { Grid, Typography } from "@mui/material";
import IconLoader from "./IconLoader";

interface Props {
  message: string;
}

const NoData: React.FC<Props> = ({ message }) => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={1}
    >
      <Grid item xl={6} md={6} sm={12} xs={12}>
        <IconLoader name="NoPatients" />
      </Grid>
      <Grid item xl={6} md={6} sm={12} xs={12}>
        <Typography sx={{ mb: 2 }}>{message}</Typography>
      </Grid>
    </Grid>
  );
};

export default NoData;
