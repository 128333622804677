type Severity = "error" | "info" | "success" | "warning";

class ToastListeners {
  private listeners: ((message: string, severity: Severity) => void)[] = [];

  public addListener(listener: (message: string, severity: Severity) => void) {
    this.listeners.push(listener);
  }

  public removeListener(
    listener: (message: string, severity: Severity) => void
  ) {
    this.listeners = this.listeners.filter((l) => l !== listener);
  }

  public addToast(message: string, severity: Severity) {
    this.listeners.forEach((listener) => listener(message, severity));
  }
}

export const toastListeners = new ToastListeners();

const showErrorToast = (error: string) => {
  toastListeners.addToast(error, "error");
};

const showInfoToast = (info: string) => {
  toastListeners.addToast(info, "info");
};

const showSuccessToast = (message: string) => {
  toastListeners.addToast(message, "success");
};

const showWarningToast = (message: string) => {
  toastListeners.addToast(message, "warning");
};

export const toastService = {
  showErrorToast,
  showInfoToast,
  showSuccessToast,
  showWarningToast,
};
