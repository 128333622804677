import { SystemState } from "../system/SystemState";

export const selectAccount = (state: SystemState) => state.account.account;

export const selectAccountId = (state: SystemState) =>
  state.account.account.accountGuid;

export const selectIsAuthenticated = (state: SystemState) =>
  !!state.account.account.jwtToken;

export const selectAccountRole = (state: SystemState) =>
  state.account.account.role;

export const selectAccountType = (state: SystemState) =>
  state.account.account.accountType.accountTypeId;

export const selectAccountError = (state: SystemState) => state.account.error;

export const selectAccountIsLoading = (state: SystemState) =>
  state.account.isLoading;

export const selectAccountRememberMe = (state: SystemState) =>
  state.account.account.rememberMe;
