import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Box, CardContent } from "@mui/material";
import { ParameterCard } from "../../../documented/ParameterCard";
import { TotalDistanceCardData } from "./totalDistanceCardData";
import IconLoader from "../../../IconLoader";
import { isEmpty } from "../../../../helpers/utilityFunctions/utilityFunctions";

interface TotalDistanceCardProps {
  data?: TotalDistanceCardData;
  onClick: (arg: string) => void;
}

const CardDetailTypography = styled(Typography)(() => ({
  fontSize: 14,
  padding: "0px 0px 0px 16px !important",
}));

const TotalDistanceCardMedia = styled(CardContent)(() => ({
  display: "flex !important",
  flexDirection: "column",
  flexGrow: "1 !important",
}));

const PanelDisplayBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
}));

const TotalDistanceCard: React.FC<TotalDistanceCardProps> = ({
  data,
  onClick,
}: TotalDistanceCardProps) => {
  return (
    <ParameterCard
      title="Total Distance"
      iconName="TotalDistance"
      popoverContent="Total distance walked during one day. This is categorised as follows (in km)*: &lt; 3.7 = sedentary, 3.7-5.55 = low active, 5.55-7.4 = moderately active, 7.4-9.25 = active, &gt; 9.5 = highly active. 

      *this is based on the average stride length of the population (0.74m)."
      onClick={() => onClick("_10MWT")}
      enabled={!isEmpty(data)}
    >
      <CardDetailTypography>
        Total distance that the user walked throughout the day
      </CardDetailTypography>
      <TotalDistanceCardMedia>
        <PanelDisplayBox>
          <IconLoader name="TotalDistanceCardFeet" />
          <Typography sx={{ fontWeight: "bold", fontSize: 57 }}>
            {data ? data.value : "0"}
          </Typography>
          <Typography sx={{ fontWeight: "bold", fontSize: 14 }}>
            {data ? data.unit : "km"}
          </Typography>
        </PanelDisplayBox>
      </TotalDistanceCardMedia>
    </ParameterCard>
  );
};

export default TotalDistanceCard;
