import RefreshIcon from "@mui/icons-material/Refresh";
import React, { useEffect } from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface Props {
  onRefreshAdmin: () => void;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: 30,
  },
  personActions: {
    display: "flex",
  },
}));

export const AdminHeader: React.FC<Props> = ({ onRefreshAdmin }) => {
  const classes = useStyles();

  useEffect(() => {
    onRefreshAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <Typography variant="h4" component="h4">
        Admin
      </Typography>

      <Box className={classes.personActions}>
        <Tooltip title="Refresh All">
          <IconButton
            aria-label="refresh"
            size="large"
            onClick={onRefreshAdmin}
          >
            <RefreshIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </Box>
    </div>
  );
};

export default AdminHeader;
