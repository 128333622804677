import { FETCH_SETTINGS_SUCCESS, UPDATE_USER_SETTING } from "../constants";
import { initialState } from "../system/SystemState";
import { SettingsActionTypes } from "../types/settingsActionTypes";

function isJSON(str: any) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export default function settingsReducer(
  state = initialState.settings,
  action: SettingsActionTypes
): typeof initialState.settings {
  switch (action.type) {
    case FETCH_SETTINGS_SUCCESS:
      const environmentSettings = action.payload.environment.reduce(
        (acc: any, setting: any) => {
          acc[setting.name] = isJSON(setting.value)
            ? JSON.parse(setting.value)
            : setting.value;
          return acc;
        },
        {}
      );

      const userSettings = action.payload.user.reduce(
        (acc: any, setting: any) => {
          acc[setting.name] = isJSON(setting.value)
            ? JSON.parse(setting.value)
            : setting.value;
          return acc;
        },
        {}
      );

      return {
        ...state,
        environment: {
          ...state.environment,
          ...environmentSettings,
        },
        user: {
          ...state.user,
          ...userSettings,
        },
      };

    case UPDATE_USER_SETTING:
      const { category, key, value } = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          [category]: {
            ...state.user[category],
            [key]: value,
          },
        },
      };

    default:
      return state;
  }
}
