import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  REFRESH_TOKEN,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  ENABLE_2FA,
  CHECK_CREDENTIALS_SUCCESS,
  CHECK_CREDENTIALS_FAILURE,
  SET_REMEMBER_ME,
  SET_2FA_STATUS,
  CLEAR_ACCOUNT_ERROR,
} from "../constants";
import { AccountState } from "../../model/account/account";
import { AccountActionTypes } from "../types/accountActionTypes";
import { initialState } from "../system/SystemState";

export default function accountReducer(
  state = initialState.account,
  action: AccountActionTypes
): AccountState {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        isLoading: true,
      };
    case CHECK_CREDENTIALS_SUCCESS:
      return {
        ...state,
        account: {
          ...state.account,
          accountGuid: action.payload.accountGuid,
          isTwoFactorAuthenticationEnabled:
            action.payload.isTwoFactorAuthenticationEnabled,
          isTwoFactorAuthenticationSetupCompleted:
            action.payload.isTwoFactorAuthenticationSetupCompleted,
          password: action.payload.password,
          email: action.payload.email,
        },
        isLoading: false,
      };
    //Writing error to the same state as the login - not sure if this needs seperating
    case CHECK_CREDENTIALS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        account: {
          ...action.payload,
          role: Number(action.payload.role),
          accountType: {
            ...action.payload.accountType,
          },
        },
        isLoading: false,
      };
    case LOGIN_USER_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case REFRESH_TOKEN:
      return {
        ...state,
        isLoading: true,
      };
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        account: {
          ...action.payload,
          rememberMe: state.account.rememberMe,
        },
        isLoading: false,
      };
    case REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case ENABLE_2FA:
      return {
        ...state,
        account: {
          ...state.account,
          isTwoFactorAuthenticationEnabled: true,
          isTwoFactorAuthenticationSetupCompleted: true,
        },
        isLoading: false,
      };
    case SET_REMEMBER_ME:
      return {
        ...state,
        account: {
          ...state.account,
          rememberMe: action.payload,
        },
        isLoading: false,
      };

    case SET_2FA_STATUS:
      return {
        ...state,
        account: {
          ...state.account,
          isTwoFactorAuthenticationPassed: action.payload,
        },
        isLoading: false,
      };

    case CLEAR_ACCOUNT_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
}
