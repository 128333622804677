import { ErrorState } from "../../model/error/ErrorState";
import { CLEAR_ERROR, SHOW_ERROR } from "../constants";
import { initialState } from "../system/SystemState";
import { ErrorActionTypes } from "../types/errorActionTypes";

export default function errorReducer(
  state = initialState.error,
  action: ErrorActionTypes
): ErrorState {
  switch (action.type) {
    case SHOW_ERROR:
      return {
        ...state,
        showDialog: true,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        showDialog: false,
        error: "",
      };
    default:
      return state;
  }
}
