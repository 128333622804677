import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Box, CardContent } from "@mui/material";
import { ParameterCard } from "../../../documented/ParameterCard";
import { TotalStepsCardData } from "./totalStepsCardData";
import IconLoader from "../../../IconLoader";
import { isEmpty } from "../../../../helpers/utilityFunctions/utilityFunctions";

interface TotalStepsCardProps {
  data?: TotalStepsCardData;
  onClick: (arg: string) => void;
}

const CardDetailTypography = styled(Typography)(() => ({
  fontSize: 14,
  padding: "0px 0px 0px 16px !important",
}));

const TotalStepsCardMedia = styled(CardContent)(() => ({
  display: "flex !important",
  flexDirection: "column",
  flexGrow: "1 !important",
}));

const PanelDisplayBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
}));

const TotalStepsCard: React.FC<TotalStepsCardProps> = ({
  onClick,
  data,
}: TotalStepsCardProps) => {
  return (
    <ParameterCard
      title="Total Steps"
      iconName="TotalSteps"
      popoverContent="Total steps walked during one day. This is categorised as follows*: &lt; 4999 = sedentary, 5000-7499 = low active, 7500-9999 = moderately active, 10,000-12,499 = active, &gt; 12,500 = highly active. 

      *this is based on averages and does not account for individual circumstances."
      onClick={() => onClick("_10MWT")}
      enabled={!isEmpty(data)}
    >
      <CardDetailTypography>
        Total step number that the user has taken throughout the day
      </CardDetailTypography>
      <TotalStepsCardMedia>
        <PanelDisplayBox>
          <IconLoader name="TotalStepsCardFeet" />
          <Typography sx={{ fontWeight: "bold", fontSize: 57 }}>
            {data ? Number(data.value) : 0}
          </Typography>
          <Typography sx={{ fontWeight: "bold", fontSize: 14 }}>
            {data ? data.unit : "Steps"}
          </Typography>
        </PanelDisplayBox>
      </TotalStepsCardMedia>
    </ParameterCard>
  );
};

export default TotalStepsCard;
