import {
  FETCH_DAILY_PARAMETER_DATES_SUCCESS,
  FETCH_DAILY_PARAMETER_DATA_SUCCESS,
  CLEAR_SELECTED_DAILY_PARAMETER, FETCH_AVERAGE_WALKING_SPEED,
} from "../constants";
import { initialState } from "../system/SystemState";
import { DailyParametersActionTypes } from "../types/dailyParametersActionTypes";
import { DailyParametersState } from "../../model/DailyParametersState";

export default function dailyParametersReducer(
  state = initialState.dailyParameters,
  action: DailyParametersActionTypes
): DailyParametersState {
  switch (action.type) {
    case FETCH_DAILY_PARAMETER_DATES_SUCCESS:
      return {
        ...state,
        dates: action.payload,
      };

    case FETCH_DAILY_PARAMETER_DATA_SUCCESS:
      return {
        ...state,
        selectedDailyParameterData: action.payload,
      };
    case FETCH_AVERAGE_WALKING_SPEED:
      return  {
        ...state,
        averageWalkingSpeedData: action.payload
      };
    case CLEAR_SELECTED_DAILY_PARAMETER:
      return {
        ...state,
        selectedDailyParameterData: [],
      };

    default:
      return state;
  }
}
