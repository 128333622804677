import { Buffer } from "buffer";

const heatmapHeight = 300;
const heatmapWidth = 300;

const heatmapHeightOriginal = 250;
const heatmapWidthOriginal = 250;

const pressureSensorLocation = [
  [
    (89 * heatmapWidth) / heatmapWidthOriginal,
    (98 * heatmapHeight) / heatmapHeightOriginal -
      (heatmapHeight - heatmapHeightOriginal) / 2,
  ],
  [
    (83 * heatmapWidth) / heatmapWidthOriginal,
    (61 * heatmapHeight) / heatmapHeightOriginal -
      (heatmapHeight - heatmapHeightOriginal) / 2,
  ],
  [
    (50 * heatmapWidth) / heatmapWidthOriginal,
    (72 * heatmapHeight) / heatmapHeightOriginal -
      (heatmapHeight - heatmapHeightOriginal) / 2,
  ],
  [
    (64 * heatmapWidth) / heatmapWidthOriginal,
    (102 * heatmapHeight) / heatmapHeightOriginal -
      (heatmapHeight - heatmapHeightOriginal) / 2,
  ],
  [
    (37 * heatmapWidth) / heatmapWidthOriginal,
    (110 * heatmapHeight) / heatmapHeightOriginal -
      (heatmapHeight - heatmapHeightOriginal) / 2,
  ],
  [
    (44 * heatmapWidth) / heatmapWidthOriginal,
    (169 * heatmapHeight) / heatmapHeightOriginal -
      (heatmapHeight - heatmapHeightOriginal) / 2,
  ],
  [
    (54 * heatmapWidth) / heatmapWidthOriginal,
    (235 * heatmapHeight) / heatmapHeightOriginal -
      (heatmapHeight - heatmapHeightOriginal) / 2,
  ],
  [
    (74 * heatmapWidth) / heatmapWidthOriginal,
    (235 * heatmapHeight) / heatmapHeightOriginal -
      (heatmapHeight - heatmapHeightOriginal) / 2,
  ],
  [
    (75 * heatmapWidth) / heatmapWidthOriginal,
    (193 * heatmapHeight) / heatmapHeightOriginal -
      (heatmapHeight - heatmapHeightOriginal) / 2,
  ],
];

const tempSensorLocation = [
  [
    (64 * heatmapWidth) / heatmapWidthOriginal,
    (45 * heatmapHeight) / heatmapHeightOriginal -
      (heatmapHeight - heatmapHeightOriginal) / 2,
  ],
  [
    (64 * heatmapWidth) / heatmapWidthOriginal,
    (80 * heatmapHeight) / heatmapHeightOriginal -
      (heatmapHeight - heatmapHeightOriginal) / 2,
  ],
  [
    (64 * heatmapWidth) / heatmapWidthOriginal,
    (213 * heatmapHeight) / heatmapHeightOriginal -
      (heatmapHeight - heatmapHeightOriginal) / 2,
  ],
];

export const minimumTemperatureC = 10; // in deg C°
export const minimumTemperatureF = 50; // in deg F°
export const minimumPressure = 0.01; // in bars

//Heatmap coefficient
export const maxTempHeatmapC = 45 - minimumTemperatureC; // in deg C°
export const maxTempHeatmapF = 113 - minimumTemperatureF; // in deg F°
export const sizeTempHeatmap = 35;
export const blurTempHeatmap = 30;

export const maxPressHeatmap =
  Math.log((6 - minimumPressure) * 100) / Math.log(10); // in bars
export const sizePressHeatmap = 25;
export const blurPressHeatmap = 20;

export const psToHeatMap = (ps: any, rightSide: boolean) => {
  // Function to get from pressure sensor data to table to be used in the heatmap.
  var offset = 0;
  var coef = 1;
  if (rightSide) {
    offset = heatmapWidth;
    coef = -1;
  }

  //Scale Pressure to start at 0.01 bars and use a logarithmic scal
  for (let i = 0; i < ps.length; i++) {
    if (ps[i] >= minimumPressure) {
      ps[i] = Math.log((ps[i] - minimumPressure) * 100) / Math.log(10);
    } else {
      ps[i] = 0;
    }
  }

  let temp: any[] = [];
  for (let i = 0; i < ps.length; i++) {
    temp = temp.concat([
      [
        offset + coef * pressureSensorLocation[i][0],
        pressureSensorLocation[i][1],
        ps[i],
      ],
    ]);
  }
  // 0-1-3 Bis
  temp = temp.concat([
    [
      offset +
        coef *
          (pressureSensorLocation[0][0] / 2 +
            pressureSensorLocation[3][0] / 2 -
            3),
      pressureSensorLocation[0][1] / 2 + pressureSensorLocation[1][1] / 2 + 4,
      ps[0] / 3 + ps[1] / 3 + ps[3] / 3,
    ],
  ]);
  // 1-2 Bis
  temp = temp.concat([
    [
      offset +
        coef *
          (pressureSensorLocation[1][0] / 2 + pressureSensorLocation[2][0] / 2),
      pressureSensorLocation[1][1] / 2 + pressureSensorLocation[2][1] / 2,
      ps[1] / 2 + ps[2] / 2,
    ],
  ]);
  // 4-2-3 Bis
  temp = temp.concat([
    [
      offset +
        coef *
          (pressureSensorLocation[2][0] / 2 +
            pressureSensorLocation[4][0] / 2 +
            3),
      pressureSensorLocation[2][1] / 2 + pressureSensorLocation[4][1] / 2,
      ps[4] / 3 + ps[2] / 3 + ps[3] / 3,
    ],
  ]);
  // 3-4/5 Bis
  temp = temp.concat([
    [
      offset +
        coef *
          (pressureSensorLocation[4][0] / 2 +
            pressureSensorLocation[3][0] / 2 +
            4),
      pressureSensorLocation[4][1] / 2 +
        ((pressureSensorLocation[5][1] / 3) * 1 +
          (pressureSensorLocation[4][1] / 3) * 2) /
          2 -
        1,
      ps[3] / 2 + ((ps[4] / 3) * 2 + (ps[5] / 3) * 1) / 2,
    ],
  ]);
  // 4/5 Bis
  temp = temp.concat([
    [
      offset +
        coef *
          ((pressureSensorLocation[5][0] / 3) * 1 +
            (pressureSensorLocation[4][0] / 3) * 2),
      (pressureSensorLocation[5][1] / 3) * 1 +
        (pressureSensorLocation[4][1] / 3) * 2,
      (ps[4] / 3) * 2 + (ps[5] / 3) * 1,
    ],
  ]);
  // 5/4 Bis
  temp = temp.concat([
    [
      offset +
        coef *
          ((pressureSensorLocation[5][0] / 3) * 2 +
            (pressureSensorLocation[4][0] / 3) * 1),
      (pressureSensorLocation[5][1] / 3) * 2 +
        (pressureSensorLocation[4][1] / 3) * 1,
      (ps[4] / 3) * 1 + (ps[5] / 3) * 2,
    ],
  ]);
  // 5/6 Bis
  temp = temp.concat([
    [
      offset +
        coef *
          ((pressureSensorLocation[6][0] / 3) * 1 +
            (pressureSensorLocation[5][0] / 3) * 2),
      (pressureSensorLocation[6][1] / 3) * 1 +
        (pressureSensorLocation[5][1] / 3) * 2,
      (ps[5] / 3) * 2 + (ps[6] / 3) * 1,
    ],
  ]);
  // 6/5 Bis
  temp = temp.concat([
    [
      offset +
        coef *
          ((pressureSensorLocation[6][0] / 3) * 2 +
            (pressureSensorLocation[5][0] / 3) * 1),
      (pressureSensorLocation[6][1] / 3) * 2 +
        (pressureSensorLocation[5][1] / 3) * 1,
      (ps[5] / 3) * 1 + (ps[6] / 3) * 2,
    ],
  ]);
  // 7-8 Bis
  temp = temp.concat([
    [
      offset +
        coef *
          (pressureSensorLocation[7][0] / 2 + pressureSensorLocation[8][0] / 2),
      pressureSensorLocation[7][1] / 2 + pressureSensorLocation[8][1] / 2,
      ps[7] / 2 + ps[8] / 2,
    ],
  ]);

  return temp;
};

export const tsToHeatMap = (ts: any, rightSide: boolean, degreeC: boolean) => {
  // Function to get from pressure sensor data to table to be used in the heatmap.
  var offset = 0;
  var coef = 1;
  if (rightSide) {
    offset = heatmapWidth;
    coef = -1;
  }

  //Scale temperature to start at minimumTemperature degree
  //If degreeC is true we used degree Celsuis otherwise we use Fahrenheit
  for (let i = 0; i < ts.length; i++) {
    if (degreeC && ts[i] >= minimumTemperatureC) {
      ts[i] = ts[i] - minimumTemperatureC;
    } else if (!degreeC && ts[i] >= minimumTemperatureF) {
      ts[i] = ts[i] - minimumTemperatureF;
    } else {
      ts[i] = 0;
    }
  }

  let temp: any[] = [];
  for (let i = 0; i < ts.length; i++) {
    temp = temp.concat([
      [
        offset + coef * tempSensorLocation[i][0],
        tempSensorLocation[i][1],
        ts[i],
      ],
    ]);
    temp = temp.concat([
      [
        offset + coef * (tempSensorLocation[i][0] - 35),
        tempSensorLocation[i][1],
        ts[i],
      ],
    ]);
    temp = temp.concat([
      [
        offset + coef * (tempSensorLocation[i][0] + 35),
        tempSensorLocation[i][1],
        ts[i],
      ],
    ]);
  }

  temp = temp.concat([
    [
      offset + coef * tempSensorLocation[0][0],
      tempSensorLocation[0][1] - 35,
      ts[0],
    ],
  ]);
  temp = temp.concat([
    [
      offset + coef * (tempSensorLocation[0][0] - 35),
      tempSensorLocation[0][1] - 35,
      ts[0],
    ],
  ]);
  temp = temp.concat([
    [
      offset + coef * (tempSensorLocation[0][0] + 35),
      tempSensorLocation[0][1] - 35,
      ts[0],
    ],
  ]);

  temp = temp.concat([
    [
      offset + coef * tempSensorLocation[1][0],
      (tempSensorLocation[1][1] / 3) * 2 + tempSensorLocation[2][1] / 3 - 10,
      (ts[1] / 3) * 2 + ts[2] / 3,
    ],
  ]);
  temp = temp.concat([
    [
      offset + coef * (tempSensorLocation[1][0] - 35),
      (tempSensorLocation[1][1] / 3) * 2 + tempSensorLocation[2][1] / 3 - 10,
      (ts[1] / 3) * 2 + ts[2] / 3,
    ],
  ]);
  temp = temp.concat([
    [
      offset + coef * (tempSensorLocation[1][0] + 35),
      (tempSensorLocation[1][1] / 3) * 2 + tempSensorLocation[2][1] / 3 - 10,
      (ts[1] / 3) * 2 + ts[2] / 3,
    ],
  ]);

  temp = temp.concat([
    [
      offset + coef * tempSensorLocation[1][0],
      tempSensorLocation[1][1] / 2 + tempSensorLocation[2][1] / 2,
      ts[1] / 2 + ts[2] / 2,
    ],
  ]);
  temp = temp.concat([
    [
      offset + coef * (tempSensorLocation[1][0] - 35),
      tempSensorLocation[1][1] / 2 + tempSensorLocation[2][1] / 2,
      ts[1] / 2 + ts[2] / 2,
    ],
  ]);
  temp = temp.concat([
    [
      offset + coef * (tempSensorLocation[1][0] + 35),
      tempSensorLocation[1][1] / 2 + tempSensorLocation[2][1] / 2,
      ts[1] / 2 + ts[2] / 2,
    ],
  ]);

  temp = temp.concat([
    [
      offset + coef * tempSensorLocation[1][0],
      (tempSensorLocation[1][1] / 3) * 1 +
        (tempSensorLocation[2][1] / 3) * 2 +
        10,
      ts[1] / 3 + (ts[2] / 3) * 2,
    ],
  ]);
  temp = temp.concat([
    [
      offset + coef * (tempSensorLocation[1][0] - 35),
      (tempSensorLocation[1][1] / 3) * 1 +
        (tempSensorLocation[2][1] / 3) * 2 +
        10,
      ts[1] / 3 + (ts[2] / 3) * 2,
    ],
  ]);
  temp = temp.concat([
    [
      offset + coef * (tempSensorLocation[1][0] + 35),
      (tempSensorLocation[1][1] / 3) * 1 +
        (tempSensorLocation[2][1] / 3) * 2 +
        10,
      ts[1] / 3 + (ts[2] / 3) * 2,
    ],
  ]);

  temp = temp.concat([
    [
      offset + coef * tempSensorLocation[1][0],
      tempSensorLocation[2][1] + 35,
      ts[2],
    ],
  ]);
  temp = temp.concat([
    [
      offset + coef * (tempSensorLocation[1][0] - 35),
      tempSensorLocation[2][1] + 35,
      ts[2],
    ],
  ]);
  temp = temp.concat([
    [
      offset + coef * (tempSensorLocation[1][0] + 35),
      tempSensorLocation[2][1] + 35,
      ts[2],
    ],
  ]);

  temp = temp.concat([
    [
      offset + coef * tempSensorLocation[1][0],
      tempSensorLocation[2][1] + 70,
      ts[2],
    ],
  ]);
  temp = temp.concat([
    [
      offset + coef * (tempSensorLocation[1][0] - 35),
      tempSensorLocation[2][1] + 70,
      ts[2],
    ],
  ]);
  temp = temp.concat([
    [
      offset + coef * (tempSensorLocation[1][0] + 35),
      tempSensorLocation[2][1] + 70,
      ts[2],
    ],
  ]);

  return temp;
};

export const decodeBase64 = (value: any, type: string) => {
  // create buffer
  let buffer = Buffer.from(value, "base64");

  switch (type) {
    case "UNSIGNED_CHAR_1BYTE":
      return buffer.readUInt8(0);
    case "UNSIGNED_SHORT_2BYTE":
      return buffer.readUInt16LE(0);
    case "UNSIGNED_LONG_4BYTE":
      return buffer.readUInt32LE(0);
  }
};

export const psToSensorValues = (data: any) => {
  let temp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  let counter = 0;

  for (let i = 3; i < temp.length; i++) {
    temp[i] = data[counter++];
  }

  return temp;
};

export const tempToSensorValues = (data: any) => {
  let temp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  for (let i = 0; i < 3; i++) {
    temp[i] = data[i];
  }
  return temp;
};

// 0 - 3 temp sensor && 3 - 12 pressure sensor for each insole
let tempSensorValues: any[] = [];
export const toSensorValues = (
  originalData: any,
  type: any,
  foot: any,
  getDataOnly = false
) => {
  if (getDataOnly) {
    return tempSensorValues;
  }
  let data = [];
  if (type === "pressure") {
    data = originalData.ps;
  } else {
    data = originalData.temp;
  }

  if (tempSensorValues.length !== 24) {
    for (let i = 0; i < 24; i++) {
      tempSensorValues[i] = 0;
    }
  }

  if (type === "pressure" && foot === "left") {
    let counter = 0;
    for (let i = 3; i < 12; i++) {
      tempSensorValues[i] = data[counter++];
    }
  } else if (type === "pressure" && foot === "right") {
    let counter = 0;
    for (let i = 15; i < 24; i++) {
      tempSensorValues[i] = data[counter++];
    }
  } else if (type === "temperature" && foot === "left") {
    let counter = 0;
    for (let i = 0; i < 3; i++) {
      tempSensorValues[i] = data[counter++];
    }
  } else if (type === "temperature" && foot === "right") {
    let counter = 0;
    for (let i = 12; i < 15; i++) {
      tempSensorValues[i] = data[counter++];
    }
  }

  return tempSensorValues;
};

export function getTempDifferences(
  sensorDataLeft: string,
  sensorDataRight: string
) {
  const left: number[] = JSON.parse(sensorDataLeft);
  const right: number[] = JSON.parse(sensorDataRight);
  return differences(left, right);
}

export function differences(a: number[], b: number[]) {
  return a.map((x, i) => Math.abs(x - b[i]));
}
