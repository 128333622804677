import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Box, CardContent } from "@mui/material";
import { ParameterCard } from "../../../documented/ParameterCard";
import { StrideAnglesPanel } from "./StrideAnglesPanel";
import { AverageStrideAnglesCardData } from "./AverageStrideAnglesCardData";
import { isEmpty } from "../../../../helpers/utilityFunctions/utilityFunctions";

interface AverageStrideAnglesCardProps {
  data?: AverageStrideAnglesCardData;
  onClick: (arg: string) => void;
}

const CardDetailTypography = styled(Typography)(() => ({
  fontSize: 14,
}));

const AverageStrideAnglesCardMedia = styled(CardContent)(() => ({
  display: "flex !important",
  flexDirection: "column",
  flexGrow: "1 !important",
  padding: "0px 0px 0px 16px !important",
}));

const PanelDisplayBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
}));

const AverageStrideAnglesCard: React.FC<AverageStrideAnglesCardProps> = ({
  data,
  onClick,
}: AverageStrideAnglesCardProps) => {
  return (
    <ParameterCard
      large
      title="Average Stride Length / Angles"
      iconName="AverageStrideAngles"
      onClick={() => onClick("_10MWT")}
      enabled={!isEmpty(data)}
    >
      <AverageStrideAnglesCardMedia>
        <CardDetailTypography>
          - Toe-off: The angle that your toes push off from the ground.
        </CardDetailTypography>
        <CardDetailTypography>
          - Heel strike: The angle that your heel initially touches the ground
          with a new step.
        </CardDetailTypography>
        <PanelDisplayBox>
          <StrideAnglesPanel
            title={data ? data.left.title : "Left Foot"}
            base={data ? data.left.length + data.unit : "N/A"}
            toeOff={data ? data.left.toe + "°" : "0°"}
            heelStrike={data ? data.left.heel + "°" : "0°"}
          />
          <StrideAnglesPanel
            title={data ? data.right.title : "Right Foot"}
            base={data ? data.right.length + data.unit : "N/A"}
            toeOff={data ? data.right.toe + "°" : "0°"}
            heelStrike={data ? data.right.heel + "°" : "0°"}
          />
        </PanelDisplayBox>
      </AverageStrideAnglesCardMedia>
    </ParameterCard>
  );
};

export default AverageStrideAnglesCard;
