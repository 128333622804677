import React from "react";
import { Grid, Typography } from "@mui/material";
import TenMetreWalkTestTableComponent from "./TenMetreWalkTestTableComponent";

const TenMetreWalk: React.FC = () => {
  return (
    <Grid container direction="column" mt={1} spacing={2} alignItems="stretch">
      <Grid item xs={8}>
        <Typography>
          Here are all the tests conducted on this day. Select a test to view
          the test detail
        </Typography>
      </Grid>
      <Grid item mr={4}>
        <TenMetreWalkTestTableComponent />
      </Grid>
    </Grid>
  );
};

export default TenMetreWalk;
