import { SystemState } from "../system/SystemState";

export const dailyMetricDates = (state: SystemState) =>
  state.dailyMetrics.dates;

export const selectedMetricDate = (state: SystemState) =>
  state.dailyMetrics.selectedDate;

export const selectedDailyMetrics = (state: SystemState) =>
  state.dailyMetrics.selectedDailyMetrics;
