import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Box, CardContent } from "@mui/material";
import { ParameterCard } from "../../../documented/ParameterCard";
import { AverageCadencePanel } from "./AverageCadencePanel";
import { AverageCadenceCardData } from "./averageCadenceCardData";
import { isEmpty } from "../../../../helpers/utilityFunctions/utilityFunctions";

interface AverageCadenceCardProps {
  data?: AverageCadenceCardData;
  onClick: (arg: string) => void;
}

const CardDetailTypography = styled(Typography)(() => ({
  fontSize: 14,
  padding: "0px 0px 0px 16px !important",
}));

const AverageCadenceCardMedia = styled(CardContent)(() => ({
  display: "flex !important",
  flexDirection: "column",
  flexGrow: "1 !important",
}));

const PanelDisplayBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
}));

const AverageCadenceCard: React.FC<AverageCadenceCardProps> = ({
  data,
  onClick,
}: AverageCadenceCardProps) => {
  return (
    <ParameterCard
      title="Average Cadence"
      iconName="AverageCadence"
      popoverContent="Normal cadence (m/s): Men: 118.8 (in their 20s), 120 (in their 30s), 120.6 (in their 40s), 117.6 (in their 50s), 117 (in their 60s), 114.6 (in their 70s). Women: 124.8 (in their 20s), 127.8 (in their 30s), 129.6 (in their 40s), 121.8 (in their 50s), 123.6 (in their 60s), 121.8 (in their 70s)."
      onClick={() => onClick("_10MWT")}
      enabled={!isEmpty(data)}
    >
      <CardDetailTypography>
        The average number of steps taken per minute
      </CardDetailTypography>
      <AverageCadenceCardMedia>
        {data && data.value ? (
          <PanelDisplayBox>
            <AverageCadencePanel
              minValue={0}
              maxValue={200}
              range={[0, 100, 200]}
              value={data ? Number(data.value) : 0}
              unit={data ? data.unit : "steps/min"}
            />
          </PanelDisplayBox>
        ) : null}
      </AverageCadenceCardMedia>
    </ParameterCard>
  );
};

export default AverageCadenceCard;
