import React from "react";
import { Permission } from "../../model/account/permissions";

type PermissionContextType = {
  isAllowedTo: (permissions: Permission[]) => boolean;
};

const defaultBehaviour: PermissionContextType = {
  isAllowedTo: () => false,
};

const PermissionContext =
  React.createContext<PermissionContextType>(defaultBehaviour);

export default PermissionContext;
