import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Paper,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import NoData from "../../../components/NoData";
import PathCircularProgress from "../../../components/PathCircularProgress";
import { ThreeDotsMenu } from "../../../components/documented/ThreeDotsMenu";
import { useAppThunkDispatch } from "../../../redux/configureStore";
import { selectInvites } from "../../../redux/selectors/adminSelectors";
import { getActiveInvites } from "../../../redux/actions/adminActions";
import { inviteService } from "../../../services/inviteService";
import { InvitationCode } from "../../../model/admin/inviteCode";
import {
  getComparator,
  Order,
  StyledTableCell,
  StyledTableRow,
} from "../TableComponents";

const TableDetailContainer = styled(Box)(() => ({
  boxSizing: "border-box",
  height: "fit-content",
  width: "100%",
  padding: "10px 40px 10px 20px",
}));

export interface HeadCell {
  id: keyof InvitationCode;
  label: string;
  numeric: boolean;
  toolTip?: string;
}

export interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof InvitationCode
  ) => void;
  order: Order;
  orderBy: string;
}

function HealthcareProfessionalAccountsTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof InvitationCode) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const headCells: readonly HeadCell[] = [
    {
      id: "firstName",
      numeric: false,
      label: "First Name",
    },
    {
      id: "lastName",
      numeric: false,
      label: "Last Name",
    },
    {
      id: "email",
      numeric: false,
      label: "Email",
    },
    {
      id: "invitedBy",
      numeric: false,
      label: "Invited By",
    },
    {
      id: "created",
      numeric: false,
      label: "Created",
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <Tooltip title={headCell.toolTip || ""}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </Tooltip>
          </StyledTableCell>
        ))}
        <StyledTableCell align="left">Invite Email</StyledTableCell>
        <StyledTableCell align="left">User Actions</StyledTableCell>
      </TableRow>
    </TableHead>
  );
}

interface Props {
  isLoading: boolean;
}

export const ManageInvitesComponent: React.FC<Props> = ({ isLoading }) => {
  const invites = useSelector(selectInvites);
  const dispatch = useAppThunkDispatch();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] =
    React.useState<keyof InvitationCode>("lastName");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof InvitationCode
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function handleResendClick(email: string) {
    try {
      inviteService.resendInviteRequest(email).then((response: any) => {});
    } catch (error: any) {
      //hmmm
    }
  }

  function handleDeleteInvite(inviteCodeGuid: string) {
    try {
      inviteService.deleteInvite(inviteCodeGuid).then((response: any) => {
        dispatch(getActiveInvites()).then((response: any) => {});
      });
    } catch (error: any) {
      //hmmm
    }
  }

  return (
    <TableDetailContainer>
      <Typography
        sx={{ padding: "5px 0px 5px 0px" }}
        variant="h5"
        gutterBottom
        component="h2"
      >
        Invitations Pending for Healthcare Professionals
      </Typography>
      {!isLoading ? (
        invites.length ? (
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer sx={{ maxHeight: 490, minWidth: 750 }}>
              <Table stickyHeader aria-labelledby="pending-invites-table">
                <HealthcareProfessionalAccountsTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {invites
                    .slice()
                    .sort(getComparator(order, orderBy))
                    .map((invite) => {
                      return (
                        <StyledTableRow
                          hover
                          tabIndex={-1}
                          key={invite.invitationCodeGuid}
                        >
                          <StyledTableCell align="left">
                            {invite.firstName}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {invite.lastName}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {invite.email}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {invite.invitedBy}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {new Date(invite.created).toDateString()}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <Button
                              onClick={() => handleResendClick(invite.email)}
                            >
                              Resend
                            </Button>
                          </StyledTableCell>
                          <StyledTableCell>
                            <ThreeDotsMenu
                              data={invite.invitationCodeGuid}
                              handleAction={handleDeleteInvite}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        ) : (
          <NoData message="You have no invites to manage right now" />
        )
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          height="100px"
          justifyContent="center"
          data-testid="loading-indicator"
        >
          <PathCircularProgress />
        </Box>
      )}
    </TableDetailContainer>
  );
};

export default ManageInvitesComponent;
