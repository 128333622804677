import * as React from "react";
import { useNavigate } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";

import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, TableHead, TableSortLabel, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { activeTestData } from "../../../../redux/selectors/activeSelectors";
import { TenMetreWalkTest } from "../../../../model/active/tenMetreWalkTest";
import {
  Order,
  StyledTableCell,
  StyledTableRow,
  getComparator,
} from "../../TableComponents";
import { visuallyHidden } from "@mui/utils";
import NoData from "../../../../components/NoData";
import { useAppThunkDispatch } from "../../../../redux/configureStore";
import { getPatientTestTableDataByCategory } from "../../../../redux/actions/healthcareProfessionalActions";

interface HeadCell {
  id: keyof TenMetreWalkTest;
  label: string;
  numeric: boolean;
  align: "left" | "right" | "inherit" | "center" | "justify" | undefined;
  toolTip?: string;
}

interface TenMetreWalkTestTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof TenMetreWalkTest
  ) => void;
  order: Order;
  orderBy: string;
}

function TenMetreWalkTestTableHead(props: TenMetreWalkTestTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof TenMetreWalkTest) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const headCells: readonly HeadCell[] = [
    {
      id: "dateTime",
      numeric: false,
      label: "Time",
      align: "left",
    },
    {
      id: "overview",
      numeric: false,
      label: "Overview",
      align: "left",
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <StyledTableCell
              key={headCell.id}
              align={headCell.align}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <Tooltip title={headCell.toolTip || ""}>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </Tooltip>
            </StyledTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

const TenMetreWalkTestTableComponent: React.VoidFunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useAppThunkDispatch();
  const data = useSelector(activeTestData);

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] =
    React.useState<keyof TenMetreWalkTest>("dateTime");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof TenMetreWalkTest
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (
    event: React.MouseEvent<unknown>,
    testDateTime: string,
    testGuid: string
  ) => {
    dispatch(
      getPatientTestTableDataByCategory(testGuid, testDateTime, "_10MWT")
    );
    navigate(`/dashboard/patients/active/_10MWT/detail`);
  };

  return data.length ? (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <TenMetreWalkTestTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {data
                .slice()
                .sort(getComparator(order, orderBy))
                .map((test: TenMetreWalkTest) => {
                  return (
                    <StyledTableRow hover tabIndex={-1} key={test.id}>
                      <StyledTableCell
                        align="left"
                        onClick={(event) =>
                          handleClick(event, test.dateTime, test.testGuid)
                        }
                      >
                        {new Date(test.dateTime).toLocaleTimeString()}
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        onClick={(event) =>
                          handleClick(event, test.dateTime, test.testGuid)
                        }
                      >
                        {`${test.overview} M/S`}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  ) : (
    <NoData message="There is no test data for the selected date" />
  );
};

export default TenMetreWalkTestTableComponent;
