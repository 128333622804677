import React from "react";
import { Grid } from "@mui/material";
import TimeTakenTableComponent from "./TimeTakenTableComponent";
import { RadialIndicator } from "../../../../components/documented/RadialIndicator";
import { DropShadowCard } from "../../../../components/documented/DropShadowCard";

const TimedUpAndGo: React.FC = () => {
  return (
    <Grid container direction="row" mt={1} spacing={12} alignItems="stretch">
      <Grid item xs={8}>
        <DropShadowCard title="TUG Score" icon="_TUGT" shadow={3}>
          <RadialIndicator
            size={200}
            minValue={0}
            maxValue={100}
            startAngle={40}
            endAngle={320}
            angleType={{
              direction: "cw",
              axis: "-y",
            }}
            value={20}
            unit="m/s"
            range={[0, 10, 20, 30, 40, 50, 50, 60, 70, 80, 90, 100]}
          />
        </DropShadowCard>
      </Grid>
      <Grid item>
        <TimeTakenTableComponent />
      </Grid>
    </Grid>
  );
};

export default TimedUpAndGo;
