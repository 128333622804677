import React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

const AlmostDoneComponent: React.VoidFunctionComponent = () => {
  return (
    <>
      <Typography variant="body1" sx={{ mt: 1 }}>
        Invite redeemed, you can now log in, you will be asked to get setup with
        an authenticator app.
      </Typography>
      <Typography variant="body1" sx={{ mt: 1 }}>
        <Link href="/" variant="body2" sx={{ ml: 1 }}>
          Click here to log in
        </Link>
      </Typography>
    </>
  );
};
export default AlmostDoneComponent;
