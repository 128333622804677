import React from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

interface Props {
  data: any;
  handleAction: (value: string) => void;
}

/** An expandable menu component to use with the material ui table*/
export const ThreeDotsMenu: React.FC<Props> = ({ data, handleAction }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleMenuClick(data: any) {
    handleAction(data);
    handleClose();
  }

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button onClick={handleClick}>
        <MoreVertIcon />
      </Button>
      <Menu
        id="card-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleMenuClick(data)}>Remove</MenuItem>
      </Menu>
    </>
  );
};
