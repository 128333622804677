import { Grid } from "@mui/material";
import * as React from "react";
import { RadialIndicator } from "../../../documented/RadialIndicator";

interface Props {
  minValue: number;
  maxValue: number;
  range: Array<number>;
  value: number;
  unit: string;
}

export const AverageCadencePanel: React.FC<Props> = ({
  minValue,
  maxValue,
  range,
  value,
  unit,
}) => {
  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <RadialIndicator
            size={200}
            minValue={minValue}
            maxValue={maxValue}
            range={range}
            startAngle={40}
            endAngle={320}
            angleType={{
              direction: "cw",
              axis: "-y",
            }}
            value={value}
            unit={unit}
          />
        </Grid>
      </Grid>
    </>
  );
};
