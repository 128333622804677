import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Box, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AlertDetailHeader from "./AlertDetailHeader";
import AlertDetailsComponent from "./AlertDetailsComponent";
import { PassiveParameterType } from "../../../model/passive/passiveParameterType";
import {
  capitaliseFirstLetter,
  getEnumKeyByEnumValue,
} from "../../../helpers/utilityFunctions/utilityFunctions";
import PatientDetailsHeader from "../../../components/Account/PatientDetailsHeader";
import { useSelector } from "react-redux";
import { selectPatients } from "../../../redux/selectors/patientSelectors";
import { Patient } from "../../../model/patient/patient";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column",
    height: "100vh",
    margin: "12px 40px 0px 0px",
    backgroundColor: "white",
    borderRadius: "40px 40px 0px 10px",
  },
  header: {
    margin: "40px 0px 0px 30px",
    backgroundColor: "white",
  },
  content: {
    display: "flex",
    flexFlow: "column",
    backgroundColor: "white",
  },
  backButton: {
    width: "40px",
    height: "40px",
  },
  backIcon: {
    transform: "scale(1.3)",
  },
}));

const AlertDetail: React.FC = () => {
  const classes = useStyles();
  const { patientId, alertType } = useParams();
  const patients = useSelector(selectPatients);
  const [selectedPatient, setSelectedPatient] = useState<Patient>();
  const navigate = useNavigate();

  useEffect(() => {
    const selectedPatient = patients.find((p) => p.accountGuid === patientId);
    setSelectedPatient(selectedPatient);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId]);

  //bit of a hack - this needs a tidy
  const type = alertType as keyof typeof PassiveParameterType;
  const processedType = getEnumKeyByEnumValue(
    PassiveParameterType,
    capitaliseFirstLetter(type)
  );
  const finalType: PassiveParameterType = PassiveParameterType[processedType!];

  return (
    <Box>
      <PatientDetailsHeader selectedPatient={selectedPatient} />

      <div className={classes.root}>
        <div className={classes.header}>
          <IconButton
            className={classes.backButton}
            color="primary"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon className={classes.backIcon} />
          </IconButton>
        </div>
        <div className={classes.content}>
          <Box sx={{ margin: "0px 40px 40px 40px" }}>
            <AlertDetailHeader patientId={patientId} alertType={type} />
            <AlertDetailsComponent
              patientId={patientId}
              alertType={finalType}
            />
          </Box>
        </div>
      </div>
    </Box>
  );
};

export default AlertDetail;
