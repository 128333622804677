import * as React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";

import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  IconButton,
  TableHead,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useSelector } from "react-redux";
import { activeTestData } from "../../../../redux/selectors/activeSelectors";
import { CustomTest } from "../../../../model/active/customTest";
import { Order, StyledTableCell, getComparator } from "../../TableComponents";
import { visuallyHidden } from "@mui/utils";
import NoData from "../../../../components/NoData";
import { activeTestService } from "../../../../services/activeTestService";

interface HeadCell {
  id: keyof CustomTest;
  label: string;
  numeric: boolean;
  align: "left" | "right" | "inherit" | "center" | "justify" | undefined;
  toolTip?: string;
}

interface CustomTestTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof CustomTest
  ) => void;
  order: Order;
  orderBy: string;
}

function CustomTestTableHead(props: CustomTestTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof CustomTest) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      label: "Test Name",
      align: "left",
    },
    {
      id: "mask",
      numeric: false,
      label: "Mask",
      align: "left",
    },
    {
      id: "dateTime",
      numeric: false,
      label: "Time of test",
      align: "left",
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <StyledTableCell
              key={headCell.id}
              align={headCell.align}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <Tooltip title={headCell.toolTip || ""}>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </Tooltip>
            </StyledTableCell>
          );
        })}
        <StyledTableCell align="left">Download</StyledTableCell>
      </TableRow>
    </TableHead>
  );
}

const CustomTestTableComponent: React.VoidFunctionComponent = () => {
  const data = useSelector(activeTestData);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof CustomTest>("name");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof CustomTest
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return data.length ? (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <CustomTestTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {data
                .slice()
                .sort(getComparator(order, orderBy))
                .map((test: CustomTest) => {
                  return (
                    <TableRow tabIndex={-1} key={test.id}>
                      <StyledTableCell align="left">
                        {test.customName}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {test.mask}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {new Date(test.dateTime).toLocaleTimeString()}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Box sx={{ ml: 2 }}>
                          <Tooltip title={`Download file ${test.name}`}>
                            <IconButton
                              sx={{
                                color: "white",
                                backgroundColor: "primary.main",
                                "&:hover": {
                                  backgroundColor: "primary.main",
                                },
                              }}
                              size="small"
                              onClick={async () => {
                                await activeTestService.DownloadActiveTestDataFile(
                                  test.testGuid,
                                  "_CTEST",
                                  test.name
                                );
                              }}
                            >
                              <DownloadIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  ) : (
    <NoData message="There is no test data for the selected date" />
  );
};

export default CustomTestTableComponent;
