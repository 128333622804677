import React, { useEffect, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/system";
import {
  psToHeatMap,
  tsToHeatMap,
  maxTempHeatmapC,
  maxTempHeatmapF,
  sizeTempHeatmap,
  blurTempHeatmap,
  maxPressHeatmap,
  sizePressHeatmap,
  blurPressHeatmap,
} from "./heatmapFunctions";
import { HeatmapData } from "../../model/passive/heatmapData";
import { PassiveParameterType } from "../../model/passive/passiveParameterType";
import { HeatmapTransforms } from "./heatmapTransforms";
import { Temperature } from "../../model/temperature";
import HeatmapOverlay from "./HeatmapOverlay";
import { HeatmapSizeEnum } from "./HeatmapSizeEnum";
import DifferenceOverlay from "./OverlaySegments";

interface HeatmapStyleProps {
  width: number;
  height: number;
  background: any;
  overlay: any;
}

const useStyles = makeStyles<Theme, HeatmapStyleProps>((theme: Theme) => ({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  container: {
    position: "relative",
    width: ({ width }) => width,
    height: ({ height }) => height,
    backgroundImage: ({ background }) => `url(${background})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
}));

interface IHeatmapProps {
  width: number;
  height: number;
  magnitude: HeatmapSizeEnum;
  transforms: HeatmapTransforms;
  type: PassiveParameterType;
  data: HeatmapData;
  unit?: Temperature;
  background?: any;
  overlay?: any;
}

const defaultGradient = {
  0.0: "#FFFFFF",
  0.1: "#0033FF",
  0.5: "#01FFFF",
  0.7: "#00FF01",
  0.8: "#FFFF01",
  0.9: "#FF9500",
  0.95: "#FC0100",
  1.0: "#FC0100",
};

const Heatmap: React.FC<IHeatmapProps> = ({
  width,
  height,
  magnitude,
  transforms,
  type,
  data,
  unit,
  background,
  overlay,
}: IHeatmapProps) => {
  const styleProps: HeatmapStyleProps = {
    width: width,
    height: height,
    background: background,
    overlay: overlay,
  };

  const classes = useStyles(styleProps);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [size, setSize] = React.useState<number>(0);
  const [max, setMax] = React.useState<number>(0);
  const [blur, setBlur] = React.useState<number>(0);
  const [mergedData, setMergedData] = React.useState<any[]>([]);

  const defaultOpacity = 0.05;

  const setDataCallback = (data: any[]) => {
    setMergedData(data);
  };

  useEffect(() => {
    setDataCallback([]);
    if (data.left && data.right) {
      switch (type) {
        case PassiveParameterType.AlarmPressure: {
          setSize(sizePressHeatmap);
          setMax(maxPressHeatmap);
          setBlur(blurPressHeatmap);
          const left = psToHeatMap(JSON.parse(data.left), false);
          const right = psToHeatMap(JSON.parse(data.right), true);
          const merged = left.concat(right);
          setDataCallback(merged);
          break;
        }
        case PassiveParameterType.AlarmTemperature: {
          setSize(sizeTempHeatmap);
          setMax(
            unit === Temperature.Celsius ? maxTempHeatmapC : maxTempHeatmapF
          );
          setBlur(blurTempHeatmap);
          const left = tsToHeatMap(
            JSON.parse(data.left),
            false,
            unit === Temperature.Celsius
          );
          const right = tsToHeatMap(
            JSON.parse(data.right),
            true,
            unit === Temperature.Celsius
          );
          const merged = left.concat(right);
          setDataCallback(merged);
          break;
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    draw();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mergedData]);

  const createCircle = (r: any, blur: any) => {
    const canvas: HTMLCanvasElement = document.createElement(
      "canvas"
    ) as HTMLCanvasElement;
    const context = canvas.getContext("2d");
    blur = blur === undefined ? 15 : blur;
    const r2 = r + blur;

    canvas.width = canvas.height = r2 * 2;

    if (context) {
      context.shadowOffsetX = context.shadowOffsetY = r2 * 2;
      context.shadowBlur = blur;
      context.shadowColor = "black";
      context.beginPath();
      context.arc(-r2, -r2, r, 0, Math.PI * 2, true);
      context.closePath();
      context.fill();
    }

    return canvas;
  };

  const createGradient = (grad: any) => {
    const canvas: HTMLCanvasElement = document.createElement(
      "canvas"
    ) as HTMLCanvasElement;
    const context = canvas.getContext("2d");
    const gradient = context?.createLinearGradient(0, 0, 0, 256);

    canvas.width = 1;
    canvas.height = 256;

    for (var i in grad) {
      gradient?.addColorStop(+i, grad[i]);
    }

    if (context) {
      context.fillStyle = gradient!!;
      context.fillRect(0, 0, 1, 256);
    }

    return context?.getImageData(0, 0, 1, 256).data;
  };

  const colorize = (pixels: any, gradient: any) => {
    for (var i = 0, len = pixels.length, j; i < len; i += 4) {
      j = pixels[i + 3] * 4; // get gradient color from opacity value

      if (j) {
        pixels[i] = gradient[j];
        pixels[i + 1] = gradient[j + 1];
        pixels[i + 2] = gradient[j + 2];
      }
    }
  };

  const draw = () => {
    if (!canvasRef.current) {
      return;
    }

    const canvas: HTMLCanvasElement = canvasRef.current;
    const context = canvas.getContext("2d");
    if (!context) {
      return;
    }

    //clear previous drawing (clearing down transforms is done at end of this function)
    context.clearRect(0, 0, width, height);

    //apply transforms
    context.scale(transforms.scaleX, transforms.scaleY);
    context.translate(transforms.translateX, transforms.translateY);

    for (var i = 0, len = mergedData.length, p; i < len; i++) {
      p = mergedData[i];
      context.globalAlpha = Math.min(
        Math.max(p[2] / max, max === undefined ? 0.05 : defaultOpacity),
        1
      );
      context.drawImage(
        createCircle(size, blur),
        p[0] - (size - blur),
        p[1] - (size - blur)
      );
    }

    const coloured: ImageData = context.getImageData(0, 0, width, height)!;
    const gradient = createGradient(defaultGradient);
    colorize(coloured?.data, gradient);
    context.putImageData(coloured, 0, 0);
    context.setTransform(1, 0, 0, 1, 0, 0);
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <canvas ref={canvasRef} height={height} width={width} />
      </div>
      <HeatmapOverlay height={height} width={width} image={overlay} />
      {type === PassiveParameterType.AlarmTemperature &&
      magnitude === HeatmapSizeEnum.Large &&
      data.left &&
      data.right ? (
        <DifferenceOverlay
          sensorDataLeft={data.left}
          sensorDataRight={data.right}
          unit={unit!}
        />
      ) : null}
    </div>
  );
};

export default Heatmap;
