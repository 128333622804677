import React from "react";
import { Permission } from "../../model/account/permissions";

import usePermissions from "../../helpers/hooks/usePermissions";

type Props = {
  to: Permission[];
  fallback?: JSX.Element | string;
  children: React.ReactNode;
};

const Restricted: React.FunctionComponent<Props> = ({
  to,
  fallback,
  children,
}) => {
  const allowed = usePermissions(to);
  if (allowed) {
    return <>{children}</>;
  }

  return <>{fallback}</>;
};

export default Restricted;
