import React from "react";
import { Typography, Box } from "@mui/material";

interface ErrorResponse {
  type: string;
  title: string;
  status: number;
  traceId: string;
  errors: Record<string, string[]>;
}

interface ErrorDisplayProps {
  error: ErrorResponse | null;
}

const ErrorDisplay: React.FC<ErrorDisplayProps> = ({ error }) => {
  if (!error) return null;

  return (
    <Box>
      <Typography sx={{ fontSize: "0.75rem" }} color="error">
        {error.title}
      </Typography>
      {error.errors &&
        Object.keys(error.errors).map((key) => (
          <Box key={key} mt={1}>
            <Typography
              sx={{ fontSize: "0.75rem", textDecoration: "underline" }}
              color="error"
            >
              {key}:
            </Typography>
            {error.errors[key].map((message, index) => (
              <Typography
                sx={{ fontSize: "0.75rem" }}
                key={index}
                color="error"
              >
                {message}
              </Typography>
            ))}
          </Box>
        ))}
    </Box>
  );
};

export default ErrorDisplay;
