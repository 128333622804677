import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useAppThunkDispatch } from "../../../redux/configureStore";
import {
  getPatients,
  getPendingPatients,
} from "../../../redux/actions/healthcareProfessionalActions";
import PatientsHeader from "./PatientsHeader";
import AuthorisedPatientsTable from "./AuthorisedPatientsTable";
import { Box } from "@mui/material";
import { selectAccount } from "../../../redux/selectors/accountSelectors";
import PendingPatientsTable from "./PendingPatientsTable";

const PatientsPage: React.VoidFunctionComponent = () => {
  const account = useSelector(selectAccount);
  const dispatch = useAppThunkDispatch();
  const [isAuthorisedLoading, setIsAuthorisedLoading] = useState(false);
  const [isPendingLoading, setIsPendingLoading] = useState(false);

  const handleRefreshPatients = () => {
    if (account.accountGuid) {
      setIsAuthorisedLoading(true);
      setIsPendingLoading(true);
      dispatch(getPatients(account.accountGuid, true)).then((response: any) => {
        setIsAuthorisedLoading(false);
      });
      dispatch(getPendingPatients(account.accountGuid)).then(
        (response: any) => {
          setIsPendingLoading(false);
        }
      );
    }
  };

  return (
    <>
      <PatientsHeader
        setIsAuthorisedLoading={setIsAuthorisedLoading}
        setIsPendingLoading={setIsPendingLoading}
        onRefreshPatients={handleRefreshPatients}
      />
      {/* This hardcoded mr is here for now, as removing the higher order margin value creates errors in other pages */}
      <Box sx={{ mr: "78px" }}>
        <AuthorisedPatientsTable isLoading={isAuthorisedLoading} />
        <Box sx={{ mt: 3 }}>
          <PendingPatientsTable
            isLoading={isPendingLoading}
            onRefreshPatients={handleRefreshPatients}
          />
        </Box>
      </Box>
    </>
  );
};

export default PatientsPage;
