import * as React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: any) => ({
  root: {
    flex: "1 1 auto",
    overflow: "auto",
    paddingBottom: "100px",
  },
}));

interface ScrollableProps {
  children: React.ReactNode;
}

export const Scrollable: React.FC<ScrollableProps> = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};

export default Scrollable;
