import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconLoader from "../../IconLoader";
import { Grid } from "@mui/material";

interface Props {
  title: string;
  icon: string;
  shadow: number;
  children?: React.ReactNode;
}

/** Container with drop shadow thats used to highlight content throughout the app  */
export const DropShadowCard = ({ title, icon, shadow, children }: Props) => {
  return (
    <Card sx={{ boxShadow: shadow }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item>
            <IconLoader name={icon} />
          </Grid>
          <Grid item>
            <Typography variant="h5" gutterBottom>
              {title}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          {children}
        </Grid>
      </CardContent>
    </Card>
  );
};
