import { CLEAR_ERROR, SHOW_ERROR } from "../constants";
import { ErrorActionTypes } from "../types/errorActionTypes";

export function showError(data: string): ErrorActionTypes {
  return {
    type: SHOW_ERROR,
    payload: data,
  };
}

export function clearError(): ErrorActionTypes {
  return {
    type: CLEAR_ERROR,
  };
}
