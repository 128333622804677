import { SystemState } from "../system/SystemState";

export const selectPatients = (state: SystemState) => state.patients.patients;

export const selectPendingPatients = (state: SystemState) =>
  state.patients.pendingPatients;

export const selectPatientTests = (state: SystemState) =>
  state.patients.patientTests;

export const selectedTestDetail = (state: SystemState) =>
  state.patients.selectedTest.detail;

export const selectedTestTableData = (state: SystemState) =>
  state.patients.selectedTest.tableData;

export const selectedTestTableDataIsLoading = (state: SystemState) =>
  state.patients.selectedTest.isLoading;

export const selectedTestDateTime = (state: SystemState) =>
  state.patients.selectedTest.dateTime;
