import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import AccessDenied from "./pages/AccessDenied";
import { Role } from "./model/account/role";
import {
  selectAccount,
  selectIsAuthenticated,
} from "./redux/selectors/accountSelectors";

interface Props {
  component: React.ComponentType;
  path?: string;
  roles: Array<Role>;
}

export const PrivateRoute: React.FC<Props> = ({
  component: RouteComponent,
  roles,
}) => {
  const account = useSelector(selectAccount);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const accountHasRequiredRole = roles.includes(Number(account.role));

  if (isAuthenticated && accountHasRequiredRole) {
    return <RouteComponent />;
  }

  if (!accountHasRequiredRole) {
    return <AccessDenied />;
  }

  return <Navigate to="/" />;
};
