import * as React from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";

export default function BackButton(): JSX.Element {
  const navigate = useNavigate();
  return (
    <IconButton
      sx={{ height: "40px", width: "40px", mt: 1 }}
      color="primary"
      onClick={() => navigate(-1)}
    >
      <ArrowBackIcon sx={{ transform: "scale(1.3)" }} />
    </IconButton>
  );
}
