import { Box, Typography } from "@mui/material";
import { Temperature } from "../../model/temperature";
import { getTempDifferences } from "./heatmapFunctions";
import { toFixed } from "../../helpers/utilityFunctions/utilityFunctions";

const SensorPointView = () => {
  return (
    <Box
      sx={{
        width: 16,
        height: 16,
        backgroundColor: (theme) => theme.palette.primary.main,
      }}
    />
  );
};

const SensorConnectionLine = () => {
  return (
    <Box
      sx={{
        width: "40%",
        height: 4,
        backgroundColor: (theme) => theme.palette.primary.main,
      }}
    />
  );
};

const SensorLineWithConnection = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <SensorPointView />
      <SensorConnectionLine />
      <SensorPointView />
    </Box>
  );
};

interface SensorValueIndicatorProps {
  value: number;
  unit: Temperature;
}

function SensorValueIndicator({
  value,
  unit,
}: SensorValueIndicatorProps): JSX.Element {
  return (
    <Box>
      <Typography sx={{ textAlign: "center" }}>
        <strong>
          {parseFloat(toFixed(value, 1))}
          {unit === Temperature.Celsius ? "°C" : "°F"} difference
        </strong>
      </Typography>
      <SensorLineWithConnection />
    </Box>
  );
}

interface DifferenceOverlayProps {
  sensorDataLeft: string;
  sensorDataRight: string;
  unit: Temperature;
}

function DifferenceOverlay({
  sensorDataLeft,
  sensorDataRight,
  unit,
}: DifferenceOverlayProps): JSX.Element {
  const differences = getTempDifferences(sensorDataLeft, sensorDataRight);

  return (
    <Box
      sx={{
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        position: "absolute",
        flex: 1,
      }}
    >
      <Box
        sx={{
          mt: "40px",
          display: "flex",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        {differences.map((v, index) => {
          return (
            <SensorValueIndicator
              unit={unit}
              key={v.toString() + index}
              value={v}
            />
          );
        })}
      </Box>
    </Box>
  );
}

export default DifferenceOverlay;
