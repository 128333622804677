import { fetch } from "../../helpers/api/api";
import { handleResponse } from "../../helpers/handleResponse";

const baseUrl = `${process.env.REACT_APP_API_URL}/dailymetrics`;
const baseUrlPassive = `${process.env.REACT_APP_API_URL}/passive`;

export const dailyMetricsService = {
  getDailyMetrics,
  getDailyMetricDates,
  getDailyMetricDatesByRange,
};

//naming of this is confusing - it fetches the daily params for display on the card for a daily metric, which is why it needs /passive as it exists on a different controller
function getDailyMetrics(patientId: string, date: Date) {
  return fetch(
    `${baseUrlPassive}/dailyMetrics?patientId=${patientId}&date=${date}`
  )
    .then((response: any) => handleResponse(response))
    .catch((error: any) =>
      handleResponse({ response: error.response, body: error.body })
    );
}

function getDailyMetricDates(patientId: string) {
  return fetch(`${baseUrl}/dates/${patientId}`)
    .then((response: any) => handleResponse(response))
    .catch((error: any) =>
      handleResponse({ response: error.response, body: error.body })
    );
}

function getDailyMetricDatesByRange(
  patientId: string,
  startDate: string,
  endDate: string
) {
  return fetch(
    `${baseUrl}/dates/range?accountGuid=${patientId}&dateStart=${startDate}&dateEnd=${endDate}`
  )
    .then((response: any) => handleResponse(response))
    .catch((error: any) =>
      handleResponse({ response: error.response, body: error.body })
    );
}
