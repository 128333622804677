import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useSelector } from "react-redux";
import { Box, Tooltip, Typography } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import NoData from "../../../components/NoData";
import PathCircularProgress from "../../../components/PathCircularProgress";

import { selectPendingPatients } from "../../../redux/selectors/patientSelectors";
import { selectAccount } from "../../../redux/selectors/accountSelectors";
import { useAppThunkDispatch } from "../../../redux/configureStore";
import {
  getComparator,
  Order,
  StyledTableCell,
  StyledTableRow,
} from "../TableComponents";
import { PendingPatient } from "../../../model/patient/pendingPatient";
import { styled } from "@mui/material/styles";
import { ThreeDotsMenu } from "../../../components/documented/ThreeDotsMenu";
import { cancelPendingPatientAuthorisation } from "../../../redux/actions/healthcareProfessionalActions";

const TableDetailContainer = styled(Box)(() => ({
  height: "fit-content",
  width: "100%",
  padding: "10px 20px 10px 20px",
}));

export interface HeadCell {
  id: keyof PendingPatient;
  label: string;
  numeric: boolean;
  toolTip?: string;
}

export interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof PendingPatient
  ) => void;
  order: Order;
  orderBy: string;
}

function PendingPatientsTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof PendingPatient) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const headCells: readonly HeadCell[] = [
    {
      id: "patientAccountGuid",
      numeric: false,
      label: "Patient Account GUID",
    },
    {
      id: "date",
      numeric: false,
      label: "Request Date",
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <Tooltip title={headCell.toolTip || ""}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </Tooltip>
          </StyledTableCell>
        ))}
        <StyledTableCell>User Actions</StyledTableCell>
      </TableRow>
    </TableHead>
  );
}

interface Props {
  isLoading: boolean;
  onRefreshPatients: () => void;
}

export const PendingPatientsTable: React.FC<Props> = ({
  isLoading,
  onRefreshPatients,
}) => {
  const account = useSelector(selectAccount);
  const pendingPatients = useSelector(selectPendingPatients);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof PendingPatient>("date");
  const dispatch = useAppThunkDispatch();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof PendingPatient
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleRemoveRequest = (patientId: string) => {
    dispatch(
      cancelPendingPatientAuthorisation(account.accountGuid, patientId)
    ).then((response: any) => {
      onRefreshPatients();
    });
  };

  return (
    <TableDetailContainer>
      <Typography
        sx={{ padding: "5px 0px 5px 0px" }}
        variant="h5"
        gutterBottom
        component="h2"
      >
        Your Pending Patients
      </Typography>
      {!isLoading ? (
        pendingPatients && pendingPatients.length ? (
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer sx={{ maxHeight: 980, minWidth: 750 }}>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <PendingPatientsTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {pendingPatients
                    .slice()
                    .sort(getComparator(order, orderBy))
                    .map((patient) => {
                      return (
                        <StyledTableRow
                          hover
                          tabIndex={-1}
                          key={patient.patientAccountGuid}
                        >
                          <StyledTableCell align="left">
                            {patient.patientAccountGuid}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {new Date(patient.date).toLocaleString("en-US", {
                              weekday: "short", // Mon
                              year: "numeric", // 2024
                              month: "short", // Oct
                              day: "2-digit", // 14
                              hour: "2-digit", // HH
                              minute: "2-digit", // MM
                              hour12: false, // 24-hour format
                            })}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <ThreeDotsMenu
                              data={patient.patientAccountGuid}
                              handleAction={handleRemoveRequest}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        ) : (
          <NoData message="You have no pending patients. Click the + button above to add a patient" />
        )
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          height="100px"
          justifyContent="center"
        >
          <PathCircularProgress />
        </Box>
      )}
    </TableDetailContainer>
  );
};

export default PendingPatientsTable;
