import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import useQuery from "../../helpers/hooks/useQuery";
import { Box, Grid, TextField } from "@mui/material";
import accountService from "../../services/AccountService";
import { LoadingButton } from "@mui/lab";
import { FORM_STATUS } from "../../model/formStatus";

interface RequestNewTokenLinkProps {
  result: string;
}

const RequestNewTokenLink = ({
  result,
}: RequestNewTokenLinkProps): JSX.Element => {
  return (() => {
    switch (result) {
      case "Password reset successful, you can now login":
        return (
          <Typography>Password reset successful, you can now login</Typography>
        );
      case "Invalid token":
        return (
          <Typography variant="body1">
            The password was already reset using this link. You need to request
            a new one.
          </Typography>
        );
      default:
        return <Typography>An Error Occured</Typography>;
    }
  })();
};

const useStyles = makeStyles((theme: any) => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  username: {
    float: "left",
  },
  password: {
    float: "right",
  },
  textField: {
    "& .Mui-focused legend": {
      paddingRight: 20,
    },
  },
  passwordField: {
    "& .Mui-focused legend": {
      paddingRight: 10,
    },
  },
}));

const defaultState = {
  password: "",
  confirmPassword: "",
};

const ResetPasswordComponent: React.VoidFunctionComponent = () => {
  const classes = useStyles();
  let query = useQuery();
  let resetToken = query.get("token");

  const [formData, setFormData] = useState(defaultState);
  const [status, setStatus] = useState(FORM_STATUS.IDLE);
  const [touched, setTouched] = useState(defaultState);

  const [statusMessage, setStatusMessage] = useState(
    "Please wait while we verify your email address"
  );
  const [requestSuccess, setRequestsuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function handleChange(e: any) {
    e.persist(); // persist the event
    setFormData((curFormData) => {
      return {
        ...curFormData,
        [e.target.id]: e.target.value,
      };
    });
  }

  function handleBlur(e: any) {
    e.persist();
    setTouched((cur: any) => {
      return { ...cur, [e.target.id]: true };
    });
  }

  function getErrors(formData: any) {
    const result = {
      password: "",
      confirmPassword: "",
    };
    if (status === FORM_STATUS.COMPLETED) return result;

    if (!formData.password) {
      result.password = "Please enter a password.";
    } //else if (passwordRegex.test(formData.password)) {
    //result.password = "Your password does not meet the complexity rules.";
    //}

    if (!formData.confirmPassword)
      result.confirmPassword = "Please confirm your password.";

    if (formData.password !== formData.confirmPassword) {
      result.password = "your passwords does not match";
      result.confirmPassword = "your passwords do not match";
    }

    return result;
  }

  //Derived state
  const errors = getErrors(formData);
  const isValid = Object.values(errors).every((x) => x === null || x === "");

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setStatus(FORM_STATUS.SUBMITTING);
    if (isValid) {
      setIsLoading(true);

      accountService
        .validateResetToken(resetToken || "")
        .then((response: any) => {
          accountService
            .resetPassword(
              resetToken || "",
              formData.password,
              formData.confirmPassword
            )
            .then((response: any) => {
              setStatus(FORM_STATUS.COMPLETED);
              setFormData(defaultState);
              //TODO This should come from server as "response.body.title" but for now hardcode success message
              setStatusMessage("Password reset successful, you can now login");
              setRequestsuccess(true);
            });
        })
        .catch(function (error: any) {
          setStatusMessage(error.body.title);
          setRequestsuccess(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setStatus(FORM_STATUS.SUBMITTED);
    }
  };

  return (
    <>
      <Typography variant="h4" sx={{ mt: 8 }}>
        <strong>Reset Password</strong>
      </Typography>

      {requestSuccess ? null : (
        <Box component="form" noValidate sx={{ mt: 2 }} onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={18}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                value={formData.password}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={status === FORM_STATUS.COMPLETED ? true : false}
                helperText={
                  (touched.password || status === FORM_STATUS.SUBMITTED) &&
                  errors.password
                }
                error={
                  (touched.password !== "" ||
                    status === FORM_STATUS.SUBMITTED) &&
                  errors.password !== ""
                }
              />
            </Grid>
            <Grid item xs={18}>
              <TextField
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                autoComplete="confirm-new-password"
                value={formData.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={status === FORM_STATUS.COMPLETED ? true : false}
                helperText={
                  (touched.confirmPassword ||
                    status === FORM_STATUS.SUBMITTED) &&
                  errors.confirmPassword
                }
                error={
                  (touched.confirmPassword !== "" ||
                    status === FORM_STATUS.SUBMITTED) &&
                  errors.confirmPassword !== ""
                }
              />
            </Grid>
          </Grid>
          <LoadingButton
            type="submit"
            loading={isLoading}
            variant="contained"
            color="primary"
            className={classes.submit}
            sx={{ mt: 3 }}
            disabled={status === FORM_STATUS.COMPLETED ? true : false}
          >
            Submit
          </LoadingButton>
        </Box>
      )}

      <Box
        sx={{
          mt: 2,
          mb: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {requestSuccess ? <RequestNewTokenLink result={statusMessage} /> : null}
      </Box>
    </>
  );
};

export default ResetPasswordComponent;
