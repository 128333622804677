import { Dispatch } from "redux";
import {
  FETCH_ALL_ALERT_DETAIL,
  FETCH_ALL_ALERT_DETAIL_SUCCESS,
  FETCH_ALL_ALERT_DETAIL_FAILURE,
  CLEAR_ALL_ALERT_DETAIL,
} from "../constants";
import { AlertMoment } from "../../model/passive/alertMoment";
import { Temperature } from "../../model/temperature";
import { celsiusToFahrenheit } from "../../helpers/utilityFunctions/utilityFunctions";
import { PassiveParametersActionTypes } from "../types/passiveParametersActionTypes";
import alertService from "../../services/AlertService";

export function fetchAllAlertDetail(): PassiveParametersActionTypes {
  return {
    type: FETCH_ALL_ALERT_DETAIL,
  };
}

export function fetchAllAlertDetailSuccess(
  data: Array<AlertMoment>
): PassiveParametersActionTypes {
  return {
    type: FETCH_ALL_ALERT_DETAIL_SUCCESS,
    payload: data,
  };
}

export function fetchAllAlertDetailError(
  error: string
): PassiveParametersActionTypes {
  return {
    type: FETCH_ALL_ALERT_DETAIL_FAILURE,
    payload: error,
  };
}

export function getAllAlertDetail(
  deviceId: string,
  type: string,
  metrics: any = undefined
) {
  return function action(dispatch: Dispatch) {
    dispatch(fetchAllAlertDetail());
    alertService.getAlertDetailByType(deviceId, type).then(
      (response: any) => {
        if (metrics && metrics === Temperature.Fahrenheit) {
          response[0].data.forEach((alert: any, index: number) => {
            const sensorDataLeft: any[] = JSON.parse(alert.sensorDataLeft);
            const sensorDataRight: any[] = JSON.parse(alert.sensorDataRight);
            sensorDataLeft.forEach((o: any, i: any, a: any) => {
              a[i] = celsiusToFahrenheit(o);
            });
            sensorDataRight.forEach((o: any, i: any, a: any) => {
              a[i] = celsiusToFahrenheit(o);
            });
            response[0].data[index].sensorDataLeft =
              JSON.stringify(sensorDataLeft);
            response[0].data[index].sensorDataRight =
              JSON.stringify(sensorDataRight);
          });
        }
        dispatch(fetchAllAlertDetailSuccess(response));
      }
      //error => dispatch(someerror('woody', buzz, error))
    );
  };
}

export function clearAllAlertDetails(): PassiveParametersActionTypes {
  return {
    type: CLEAR_ALL_ALERT_DETAIL,
  };
}
