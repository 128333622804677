import { AccountTypeId } from "../../model/account/accountTypeId";
import { Role } from "../../model/account/role";

const DashboardRoutes = [
  {
    path: "/dashboard/patients",
    sidebarName: "Patients",
    icon: "PatientsIcon",
    permissions: [AccountTypeId[AccountTypeId.HealthcareProfessional]],
  },
  {
    path: "/dashboard/admin",
    sidebarName: "Admin",
    icon: "AdminMan",
    permissions: [Role[Role.SuperAdmin], Role[Role.Admin]],
  },
  {
    path: "/dashboard/support",
    sidebarName: "Support",
    icon: "SupportIcon",
    permissions: [
      AccountTypeId[AccountTypeId.HealthcareProfessional],
      AccountTypeId[AccountTypeId.Patient],
      AccountTypeId[AccountTypeId.Account],
      Role[Role.Admin],
      Role[Role.User],
    ],
  },
];

export default DashboardRoutes;
