import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";
import IconLoader from "../IconLoader";
import { ParameterCard } from "../documented/ParameterCard";
import { CalculatedPassiveParameterModel } from "../../model/passive/calculatedPassiveParameterModel";
import { PassiveParameterTypeEnum } from "../../model/passive/enum/passiveParameterTypeEnum";
import { RadialIndicator } from "../documented/RadialIndicator";
import { AverageCadenceCardData } from "./DailyParameters/AverageCadence/averageCadenceCardData";
import { AverageAsymmetryCardData } from "./DailyParameters/AverageAsymmetry/averageAsymmetryCardData";
import { AverageWalkingSpeedCardData } from "./DailyParameters/AverageWalkingSpeed/averageWalkingSpeedCardData";
import { getDailyParameters } from "./DailyParameters/cardFunctions";
import AverageStrideLengthCardMini from "./DailyParameters/AverageStrideLength/AverageStrideLengthCardMini";
import { AverageStrideLengthCardData } from "./DailyParameters/AverageStrideLength/AverageStrideLengthCardData";
import AverageSymmetryCardMini from "./DailyParameters/AverageSymmetry/AverageSymmetryCardMini";
import { isEmpty } from "../../helpers/utilityFunctions/utilityFunctions";

interface IDailyParametersCardProps {
  onClick: (arg: string) => void;
  title: string;
  popoverContent: string;
  data?: CalculatedPassiveParameterModel[];
  large?: boolean;
}

const ParamHeader = styled(Typography)(() => ({
  display: "inline",
  border: "1px solid",
  borderRadius: "16px",
  padding: "3px",
  margin: "10px",
}));

const ParamHeaderContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
}));

const ParamContentContainer = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
}));

const DailyParametersCard: React.FC<IDailyParametersCardProps> = ({
  onClick,
  title,
  popoverContent,
  data,
  large,
}: IDailyParametersCardProps) => {
  const [averageCadenceCardData, setAverageCadenceCardData] =
    useState<AverageCadenceCardData>();

  const [averageAsymmetryCardData, setAverageAsymmetryCardData] =
    useState<AverageAsymmetryCardData>();

  const [averageWalkingSpeedCardData, setAverageWalkingSpeedCardData] =
    useState<AverageWalkingSpeedCardData>();

  const [averageStrideLengthCardData, setAverageStrideLengthCardData] =
    useState<AverageStrideLengthCardData>();

  useEffect(() => {
    if (data) {
      setAverageCadenceCardData(
        getDailyParameters(
          data!,
          PassiveParameterTypeEnum.AverageCadence
        ) as AverageCadenceCardData
      );

      setAverageAsymmetryCardData(
        getDailyParameters(
          data!,
          PassiveParameterTypeEnum.AverageAsymmetry
        ) as AverageAsymmetryCardData
      );

      setAverageWalkingSpeedCardData(
        getDailyParameters(
          data!,
          PassiveParameterTypeEnum.AverageWalkingSpeed
        ) as AverageWalkingSpeedCardData
      );

      setAverageStrideLengthCardData(
        getDailyParameters(
          data!,
          PassiveParameterTypeEnum.AverageStrideLength
        ) as AverageStrideLengthCardData
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <ParameterCard
      large={large}
      title={title}
      iconName="DailyParameters"
      popoverContent={popoverContent}
      onClick={() => onClick("_10MWT")}
    >
      <Grid
        container
        sx={{
          margin: "0px 0px 0px 32px",
        }}
      >
        <Grid
          item
          sx={{
            height: "154px",
            width: "265px",
          }}
        >
          <ParamHeaderContainer>
            <IconLoader name="AverageCadence" fill="black" />
            <Typography
              sx={{
                ml: 2,
              }}
              variant="h5"
              component="h2"
            >
              <ParamHeader>Average Cadence</ParamHeader>
            </Typography>
          </ParamHeaderContainer>
          {isEmpty(averageCadenceCardData?.value) ? (
            <ParamContentContainer>
              <RadialIndicator
                size={140}
                minValue={0}
                maxValue={200}
                startAngle={40}
                endAngle={320}
                angleType={{
                  direction: "cw",
                  axis: "-y",
                }}
                value={
                  averageCadenceCardData
                    ? Number(averageCadenceCardData.value)
                    : 0
                }
                unit={
                  averageCadenceCardData ? averageCadenceCardData.unit : "m/s"
                }
                range={[0, 100, 200]}
              />
            </ParamContentContainer>
          ) : null}
        </Grid>

        <AverageSymmetryCardMini data={averageAsymmetryCardData} />

        <Grid
          item
          sx={{
            height: "154px",
            width: "265px",
          }}
        >
          <ParamHeaderContainer>
            <IconLoader name="AverageWalkingSpeed" fill="black" />
            <Typography
              sx={{
                ml: 2,
              }}
              variant="h5"
              component="h2"
            >
              <ParamHeader>Average Walking Speed</ParamHeader>
            </Typography>
          </ParamHeaderContainer>
          {isEmpty(averageWalkingSpeedCardData?.value) ? (
            <ParamContentContainer>
              <RadialIndicator
                size={140}
                minValue={0}
                maxValue={2.5}
                startAngle={40}
                endAngle={320}
                angleType={{
                  direction: "cw",
                  axis: "-y",
                }}
                value={
                  averageWalkingSpeedCardData
                    ? Number(averageWalkingSpeedCardData.value)
                    : 0
                }
                unit={
                  averageWalkingSpeedCardData
                    ? averageWalkingSpeedCardData.unit
                    : "m/s"
                }
                range={[0, 1.25, 2.5]}
              />
            </ParamContentContainer>
          ) : null}
        </Grid>

        <AverageStrideLengthCardMini data={averageStrideLengthCardData} />
      </Grid>
    </ParameterCard>
  );
};

export default DailyParametersCard;
