import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Box, CardContent } from "@mui/material";
import { ParameterCard } from "../../../documented/ParameterCard";
import { AverageWalkingSpeedPanel } from "./AverageWalkingSpeedPanel";
import { AverageWalkingSpeedCardData } from "./averageWalkingSpeedCardData";
import { isEmpty } from "../../../../helpers/utilityFunctions/utilityFunctions";

interface AverageWalkingSpeedCardProps {
  data?: AverageWalkingSpeedCardData;
  onClick: () => void;
}

const CardDetailTypography = styled(Typography)(() => ({
  fontSize: 14,
  padding: "0px 0px 0px 16px !important",
}));

const AverageWalkingSpeedCardMedia = styled(CardContent)(() => ({
  display: "flex !important",
  flexDirection: "column",
  flexGrow: "1 !important",
}));

const PanelDisplayBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
}));

const AverageWalkingSpeedCard: React.FC<AverageWalkingSpeedCardProps> = ({
  data,
  onClick,
}: AverageWalkingSpeedCardProps) => {
  return (
    <ParameterCard
      title="Average Walking Speed"
      iconName="AverageWalkingSpeedParam"
      popoverContent="Average walking speed in the last 24 hours when activity was detected. The color bar reflects the reference values according to the age and gender of the user"
      onClick={onClick}
      enabled={!isEmpty(data)}
    >
      <CardDetailTypography>
        User's natural walking speed. This is sensitive to their general health
        and changes as they age
      </CardDetailTypography>
      <AverageWalkingSpeedCardMedia>
        {data && data.value ? (
          <PanelDisplayBox>
            <AverageWalkingSpeedPanel
              minValue={0}
              maxValue={2.5}
              range={[0, 1.25, 2.5]}
              value={data ? Number(data.value) : 0}
              unit={data ? data.unit : "m/s"}
            />
          </PanelDisplayBox>
        ) : null}
      </AverageWalkingSpeedCardMedia>
    </ParameterCard>
  );
};

export default AverageWalkingSpeedCard;
