import {
  FETCH_ACTIVE_OVERVIEW,
  FETCH_ACTIVE_OVERVIEW_SUCCESS,
  FETCH_ACTIVE_OVERVIEW_FAILURE,
  FETCH_ACTIVE_TEST_DATES,
  FETCH_ACTIVE_TEST_DATES_SUCCESS,
  FETCH_ACTIVE_TEST_DATES_FAILURE,
  FETCH_ACTIVE_TEST_DATA,
  FETCH_ACTIVE_TEST_DATA_SUCCESS,
  FETCH_ACTIVE_TEST_DATA_FAILURE,
  CLEAR_ACTIVE_TEST_DATA,
  CLEAR_ACTIVE_TEST_DATES,
  SELECT_ACTIVE_TEST_DATE,
} from "../constants";
import { Dispatch } from "redux";
import { ActiveActionTypes } from "../types/activeActionTypes";
import { activeTestService } from "../../services/activeTestService";

export function fetchActiveOverview(): ActiveActionTypes {
  return {
    type: FETCH_ACTIVE_OVERVIEW,
  };
}

export function fetchActiveOverviewSuccess(data: any) {
  return {
    type: FETCH_ACTIVE_OVERVIEW_SUCCESS,
    payload: data,
  };
}

export function fetchActiveOverviewError(error: string): ActiveActionTypes {
  return {
    type: FETCH_ACTIVE_OVERVIEW_FAILURE,
    payload: error,
  };
}

export function getActiveOverview(patientGuid: string) {
  return function action(dispatch: Dispatch) {
    dispatch(fetchActiveOverview());
    return activeTestService.GetActiveTestOverview(patientGuid).then(
      (response: any) => dispatch(fetchActiveOverviewSuccess(response))
      //error => dispatch(someerror('woody', buzz, error))
    );
  };
}

//active test dates

export function fetchActiveTestDates(): ActiveActionTypes {
  return {
    type: FETCH_ACTIVE_TEST_DATES,
  };
}

export function fetchActiveTestDatesSuccess(data: any): ActiveActionTypes {
  return {
    type: FETCH_ACTIVE_TEST_DATES_SUCCESS,
    payload: data,
  };
}

export function fetchActiveTestDatesError(error: string): ActiveActionTypes {
  return {
    type: FETCH_ACTIVE_TEST_DATES_FAILURE,
    payload: error,
  };
}

export function getActiveTestDatesByRange(
  patientId: string,
  activeType: string,
  startDate: string,
  endDate: string
) {
  return function action(dispatch: Dispatch) {
    dispatch(fetchActiveTestDates());
    return activeTestService
      .GetActiveTestDatesByRange(patientId, activeType, startDate, endDate)
      .then(
        (response: any) => {
          dispatch(fetchActiveTestDatesSuccess(response));
        }
        //error => dispatch(someerror('woody', buzz, error))
      );
  };
}

//active test data

export function fetchActiveTestData(): ActiveActionTypes {
  return {
    type: FETCH_ACTIVE_TEST_DATA,
  };
}

export function fetchActiveTestDataSuccess(data: any): ActiveActionTypes {
  return {
    type: FETCH_ACTIVE_TEST_DATA_SUCCESS,
    payload: data,
  };
}

export function fetchActiveTestDataError(error: string): ActiveActionTypes {
  return {
    type: FETCH_ACTIVE_TEST_DATA_FAILURE,
    payload: error,
  };
}

export function getActiveTestData(
  accountGuid: string,
  activeType: string,
  date: string
) {
  return function action(dispatch: Dispatch) {
    dispatch(fetchActiveTestData());
    return activeTestService
      .GetActiveTestDataByDate(accountGuid, activeType, date)
      .then(
        (response: any) => dispatch(fetchActiveTestDataSuccess(response))
        //error => dispatch(someerror('woody', buzz, error))
      );
  };
}

export function setActiveTestDate(date: Date): ActiveActionTypes {
  return {
    type: SELECT_ACTIVE_TEST_DATE,
    payload: date,
  };
}

export function clearActiveTestData(): ActiveActionTypes {
  return {
    type: CLEAR_ACTIVE_TEST_DATA,
  };
}

export function clearActiveTestDates(): ActiveActionTypes {
  return {
    type: CLEAR_ACTIVE_TEST_DATES,
  };
}
