import { toastService } from "../services/ToastService/toastService";
import { isDevelopment } from "./utilityFunctions/utilityFunctions";

function formatErrors(status, statusText, errors) {
  let errorString = `Error ${status}: ${statusText}:`;

  for (const [key, value] of Object.entries(errors)) {
    errorString += ` ${key}: ${value.join(", ")}`;
  }

  return errorString;
}

export function handleResponse(response) {
  return new Promise(function (resolve, reject) {
    const responseData = response.response;
    const data = response.body && JSON.parse(JSON.stringify(response.body));

    if (responseData && !responseData.ok) {
      if ([401, 403].includes(responseData.status)) {
        if (isDevelopment()) {
          toastService.showErrorToast("Unauthorised");
        }
        return reject("Unauthorised");
      }
      const error = (data && data.message) || responseData.statusText;
      if (isDevelopment()) {
        const formattedError =
          data && data.errors
            ? formatErrors(
                responseData.status,
                responseData.statusText,
                data.errors
              )
            : error;
        toastService.showErrorToast(formattedError);
      }
      return reject(error);
    } else {
      return resolve(data);
    }
  });
}
