import { Box, Typography } from "@mui/material";
import React from "react";

export const SupportPage: React.FC = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", marginRight: 30 }}>
      <Typography variant="h4" component="h4">
        Support
      </Typography>
      <Typography sx={{ mt: 2 }}>
        You can view our full privacy policy by clicking{" "}
        <a
          href="https://www.walkwithpath.com/policies/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
      </Typography>
      <Typography sx={{ mt: 2 }}>
        If you need assistance, please contact us at:{" "}
        <a href="mailto:dashboard-support@walkwithpath.com">
          dashboard-support@walkwithpath.com
        </a>
      </Typography>
    </Box>
  );
};

export default SupportPage;
