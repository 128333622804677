import {
  CLEAR_SELECTED_PATIENT_TEST_DETAIL,
  FETCH_PATIENTS_SUCCESS,
  FETCH_PATIENT_TESTS_SUCCESS,
  FETCH_PATIENT_TEST_DETAIL_SUCCESS,
  FETCH_PATIENT_TEST_TABLE_DATA,
  FETCH_PATIENT_TEST_TABLE_DATA_SUCCESS,
  FETCH_PENDING_PATIENTS_SUCCESS,
  SET_SELECTED_TEST_DATE,
} from "../constants";
import { PatientActionTypes } from "../types/patientActionTypes";
import { initialState } from "../system/SystemState";
import { PatientsState } from "../../model/patient/PatientsState";

export default function patientReducer(
  state = initialState.patients,
  action: PatientActionTypes
): PatientsState {
  switch (action.type) {
    case FETCH_PATIENTS_SUCCESS:
      return {
        ...state,
        patients: action.payload,
      };
    case FETCH_PENDING_PATIENTS_SUCCESS:
      return {
        ...state,
        pendingPatients: action.payload,
      };
    case FETCH_PATIENT_TESTS_SUCCESS:
      return {
        ...state,
        patientTests: action.payload,
      };
    case FETCH_PATIENT_TEST_DETAIL_SUCCESS:
      return {
        ...state,
        selectedTest: {
          ...state.selectedTest,
          detail: action.payload,
        },
      };

    case FETCH_PATIENT_TEST_TABLE_DATA:
      return {
        ...state,
        selectedTest: {
          ...state.selectedTest,
          tableData: {
            ...state.selectedTest.tableData,
          },
          isLoading: true,
        },
      };
    case FETCH_PATIENT_TEST_TABLE_DATA_SUCCESS:
      return {
        ...state,
        selectedTest: {
          ...state.selectedTest,
          tableData: {
            ...state.selectedTest.tableData,
            [action.payload.key]: action.payload.data,
          },
          isLoading: false,
        },
      };

    case SET_SELECTED_TEST_DATE:
      return {
        ...state,
        selectedTest: {
          ...state.selectedTest,
          dateTime: action.payload,
        },
      };

    case CLEAR_SELECTED_PATIENT_TEST_DETAIL:
      return {
        ...state,
        patientTests: [],
        selectedTest: {
          dateTime: "",
          detail: null,
          tableData: null,
          isLoading: false,
        },
      };
    default:
      return state;
  }
}
