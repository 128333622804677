import React, { Dispatch, SetStateAction, useEffect } from "react";
import { isSameDay } from "date-fns";
import {
  DatePicker,
  PickersDay,
  PickersDayProps,
  pickersDayClasses,
} from "@mui/x-date-pickers";

import {
  PickersDay as PickersDayPro,
  PickersDayProps as PickersDayPropsPro,
  pickersDayClasses as pickersDayClassesPro,
} from "@mui/x-date-pickers-pro";

import { Box, IconButton, Tooltip } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { getAdjacentDate } from "../../../helpers/utilityFunctions/utilityFunctions";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";

import { AdapterDateFns as RegularAdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDateFns as ProAdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";

import { LocalizationProvider as RegularLocalizationProvider } from "@mui/x-date-pickers";
import { LocalizationProvider as ProLocalizationProvider } from "@mui/x-date-pickers-pro";

enum Sequence {
  Next,
  Previous,
}

interface IMultiDatePickerProps {
  dates?: Date[];
  selectedDate?: Date | undefined;
  setSelectedDate?: Dispatch<SetStateAction<Date | undefined>>;
  setSelectedMonth?: Dispatch<SetStateAction<Date | undefined>>;
  isLoading?: boolean;
  isRangePicker?: boolean;
  selectedRange?: [Date | null, Date | null];
  setSelectedRange?:
    | React.Dispatch<React.SetStateAction<[Date | null, Date | null]>>
    | undefined;
}

const MultiDatePicker = ({
  dates,
  selectedDate,
  setSelectedDate,
  setSelectedMonth,
  isLoading,
  isRangePicker = false,
  selectedRange,
  setSelectedRange,
}: IMultiDatePickerProps) => {
  const [formattedDates, setFormattedDates] = React.useState<Date[]>([]);

  useEffect(() => {
    setFormattedDates([]);
    if (dates !== undefined) {
      const formattedDates = dates.map((date) => {
        return new Date(date);
      });
      setFormattedDates(formattedDates);
    }
  }, [dates]);

  const onAnyTextFieldChanged = (e: any) => {
    if (!!e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  };

  const CustomDay = (props: PickersDayProps<Date>) => {
    const matchedStyles = formattedDates.reduce((a, v) => {
      const date = new Date(props.day);
      return isSameDay(date, v)
        ? {
            backgroundColor: (theme: any) => theme.palette.primary.main,
            color: (theme: any) => theme.palette.common.white,
            "&:hover, &:focus": {
              backgroundColor: (theme: any) => theme.palette.primary.dark,
            },
            borderTopLeftRadius: "50%",
            borderBottomLeftRadius: "50%",
            borderTopRightRadius: "50%",
            borderBottomRightRadius: "50%",
          }
        : a;
    }, {});

    return (
      <PickersDay
        {...props}
        sx={{
          ...matchedStyles,
          [`&&.${pickersDayClasses.selected}`]: {
            border: 2,
            borderColor: (theme: any) => theme.palette.common.black,
            color: (theme: any) => theme.palette.common.black,
            background: (theme: any) => theme.palette.common.white,
          },
        }}
      />
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const CustomDayPro = (props: PickersDayPropsPro<Date>) => {
    const { day, selected } = props;
    const date = new Date(day);

    const matchedStyles: {
      backgroundColor?: string;
      color?: string;
      "&:hover, &:focus"?: {
        backgroundColor?: string;
      };
    } = {};

    formattedDates.forEach((formattedDate) => {
      if (isSameDay(date, formattedDate)) {
        matchedStyles.backgroundColor = "#00aaff"; // Your custom background color
        matchedStyles.color = "#ffffff"; // Your custom text color
        matchedStyles["&:hover, &:focus"] = {
          backgroundColor: "#0088cc", // Your custom hover background color
        };
      }
    });

    if (selected) {
      matchedStyles.backgroundColor = "#ff0000";
      matchedStyles.color = "#ffffff";
      matchedStyles["&:hover, &:focus"] = {
        backgroundColor: "#cc0000",
      };
    }

    return (
      <PickersDayPro
        {...props}
        sx={{
          ...matchedStyles,
          [`&&.${pickersDayClassesPro.selected}`]: {
            border: "2px solid #000000",
          },
        }}
      />
    );
  };

  const handleStartMonthChange = (date: Date) => {
    if (!setSelectedMonth) return;
    setSelectedMonth(date);
  };

  const handleRangeChange = (newValue: [Date | null, Date | null]) => {
    setSelectedRange?.(newValue);
  };

  const handleToggleDate = (sequence: Sequence) => {
    switch (sequence) {
      case Sequence.Next:
        let nextDate = getAdjacentDate(selectedDate!, "next");
        if (nextDate.getMonth() !== selectedDate!.getMonth()) {
          handleStartMonthChange(nextDate);
        }
        setSelectedDate!(nextDate);
        break;
      case Sequence.Previous:
        let previousDate = getAdjacentDate(selectedDate!, "previous");
        if (previousDate.getMonth() !== selectedDate!.getMonth()) {
          handleStartMonthChange(previousDate);
        }
        setSelectedDate!(previousDate);
        break;
      default:
        break;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {!isRangePicker ? (
        <>
          <Tooltip title="Previous day">
            <span>
              <IconButton
                onClick={() => handleToggleDate(Sequence.Previous)}
                size="small"
                sx={{ mr: 1 }}
              >
                <ArrowLeftIcon />
              </IconButton>
            </span>
          </Tooltip>
          <RegularLocalizationProvider dateAdapter={RegularAdapterDateFns}>
            <DatePicker
              loading={isLoading}
              label="Start date"
              openTo="day"
              views={["day"]}
              value={selectedDate ?? new Date()}
              format="dd.MM.yyyy"
              slots={{ day: CustomDay }}
              onChange={(newValue) => {
                if (!setSelectedDate) return;
                setSelectedDate(newValue || undefined);
              }}
              onMonthChange={handleStartMonthChange}
              slotProps={{
                textField: {
                  onBeforeInput: onAnyTextFieldChanged,
                  InputProps: {
                    size: "medium",
                    readOnly: true,
                  },
                },
              }}
            />
          </RegularLocalizationProvider>
          <Tooltip title="Next day">
            <span>
              <IconButton
                onClick={() => handleToggleDate(Sequence.Next)}
                size="small"
                sx={{ ml: 1 }}
              >
                <ArrowRightIcon />
              </IconButton>
            </span>
          </Tooltip>
        </>
      ) : (
        <>
          <ProLocalizationProvider dateAdapter={ProAdapterDateFns}>
            <DateRangePicker
              value={selectedRange}
              onChange={handleRangeChange}
              loading={isLoading}
              format="dd.MM.yyyy"
              onMonthChange={handleStartMonthChange}
            />
          </ProLocalizationProvider>
        </>
      )}
    </Box>
  );
};

export default MultiDatePicker;
