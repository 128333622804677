import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoginForm from "./LoginForm";
import EnableTwoFactorAuthComponent from "./TwoFactorAuth/EnableTwoFactorAuthComponent";
import SendTwoFactorAuthComponent from "./TwoFactorAuth/SendTwoFactorAuthComponent";
import { AccountTypeId } from "../../model/account/accountTypeId";
import { selectAccount } from "../../redux/selectors/accountSelectors";

const LoginComponent: React.VoidFunctionComponent = () => {
  const account = useSelector(selectAccount);
  let navigate = useNavigate();

  useEffect(() => {
    const loggedInAccount = localStorage.getItem("account");
    if (loggedInAccount) {
      const foundAccount = JSON.parse(loggedInAccount);
      if (foundAccount.rememberMe) {
        if (
          foundAccount.accountType.accountTypeId ===
          AccountTypeId.HealthcareProfessional
        ) {
          navigate("/dashboard/patients");
        } else {
          navigate(`/dashboard/patients/${foundAccount.accountGuid}`);
        }
      } else {
        localStorage.removeItem("account");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return (
    <>
      {!account.isTwoFactorAuthenticationEnabled ? (
        <LoginForm />
      ) : account.isTwoFactorAuthenticationSetupCompleted ? (
        <SendTwoFactorAuthComponent />
      ) : (
        <EnableTwoFactorAuthComponent />
      )}
    </>
  );
};

export default LoginComponent;
