import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";

import IconLoader from "../../../IconLoader";
import { AverageStrideLengthCardData } from "./AverageStrideLengthCardData";
import background from "./averageStrideLengthBackground.svg";
import { isEmpty } from "../../../../helpers/utilityFunctions/utilityFunctions";

interface AverageStrideLengthCardMiniProps {
  data?: AverageStrideLengthCardData;
}

const ParamHeader = styled(Typography)(() => ({
  display: "inline",
  border: "1px solid",
  borderRadius: "16px",
  padding: "3px",
  margin: "10px",
}));

const ParamHeaderContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
}));

const ParamContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
}));

const CardDetailTypography = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: "bold",
  textAlign: "center",
}));

const AverageStrideLengthCardMini: React.FC<
  AverageStrideLengthCardMiniProps
> = ({ data }: AverageStrideLengthCardMiniProps) => {
  return (
    <Box
      sx={{
        height: "154px",
        width: "265px",
        direction: "column",
        alignItems: "center",
      }}
    >
      <ParamHeaderContainer>
        <IconLoader name="AverageStrideLength" fill="black" />
        <Typography
          sx={{
            ml: 2,
          }}
          variant="h5"
          component="h2"
        >
          <ParamHeader>Average stride length</ParamHeader>
        </Typography>
      </ParamHeaderContainer>
      <ParamContentContainer>
        {isEmpty(data?.left.length) && isEmpty(data?.right.length) ? (
          <Grid
            sx={{
              backgroundImage: `url(${background})`,
              height: "100px",
              width: "180px",
              margin: "5px 32px 0px 0px",
            }}
            container
          >
            <Grid
              item
              sx={{
                width: "86.5px",
                display: "flex",
                flexDirection: "column",
                margin: "25px 0px 0px 15px",
              }}
            >
              <CardDetailTypography>
                {data ? Number(data.left.length) : 0}
              </CardDetailTypography>
              <CardDetailTypography>
                {data ? data.unit : "s"}
              </CardDetailTypography>
            </Grid>
            <Grid
              item
              sx={{
                width: "86.5px",
                display: "flex",
                flexDirection: "column",
                margin: "25px 0px 0px -20px",
              }}
            >
              <CardDetailTypography>
                {data ? Number(data.right.length) : 0}
              </CardDetailTypography>
              <CardDetailTypography>
                {data ? data.unit : "s"}
              </CardDetailTypography>
            </Grid>
          </Grid>
        ) : null}
      </ParamContentContainer>
    </Box>
  );
};

export default AverageStrideLengthCardMini;
