import { useEffect, useState } from "react";
import { walkwithpath } from "../../theme/walkwithpath";

export const useClientTheme = () => {
  const [theme, setTheme] = useState<any>(walkwithpath);
  const [isDefaultTheme, setIsDefaultTheme] = useState<boolean>(true);

  const requestedTheme = `${process.env.REACT_APP_CLIENT_NAME}`;

  const loadThemeAsync = async () => {
    try {
      await import(`../../theme/${requestedTheme}`).then((theme: any) => {
        setTheme(theme[requestedTheme]);
        setIsDefaultTheme(
          theme[requestedTheme].clientId === "walkwithpath" ? true : false
        );
      });
    } catch (error) {
      //theme not found, so just load the base walkwithpath theme
      setTheme(walkwithpath);
      setIsDefaultTheme(true);
    }
  };

  useEffect(() => {
    loadThemeAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { theme, isDefaultTheme };
};
