import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { MenuItem } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/system";
import { inviteService } from "../../../services/inviteService";
import { FORM_STATUS } from "../../../model/formStatus";
import { InviteRequest } from "../../../model/inviteRequest";

const FormItem = styled(Box)(({ theme }) => ({
  margin: "10px",
  [theme.breakpoints.down("sm")]: {
    flex: "1 1 100%",
  },
}));

const SubmitButton = styled(LoadingButton)(({ theme }) => ({
  margin: "10px",
  alignSelf: "center",
  [theme.breakpoints.down("sm")]: {
    flex: "1 1 100%",
  },
}));

const defaultState: InviteRequest = {
  firstName: "",
  lastName: "",
  role: 1,
  email: "",
};

interface Props {
  handleRefreshInvites: any;
}

export const InviteUserComponent: React.FC<Props> = ({
  handleRefreshInvites,
}) => {
  const [formData, setFormData] = useState<InviteRequest>(defaultState);
  const [status, setStatus] = useState(FORM_STATUS.IDLE);
  const [touched, setTouched] = useState(defaultState);
  const [isLoading, setIsLoading] = useState(false);

  function handleChange(e: any) {
    setFormData((curFormData) => {
      return {
        ...curFormData,
        [e.target.name]: e.target.value,
      };
    });
  }

  function handleBlur(e: any) {
    e.persist();
    setTouched((cur: any) => {
      return { ...cur, [e.target.id]: true };
    });
  }

  function getErrors(formData: any) {
    const result = {
      firstName: "",
      lastName: "",
      email: "",
    };
    if (status === FORM_STATUS.COMPLETED) return result;

    if (!formData.firstName) result.firstName = "Please enter a first name.";
    if (!formData.lastName) result.lastName = "Please enter a last name.";

    if (!formData.email) {
      result.email = "Please enter an email address.";
    } else if (!/^.+@.+$/i.test(formData.email)) {
      result.email = "The email address is Invalid.";
    }

    return result;
  }

  //Derived state
  const errors = getErrors(formData);
  const isValid = Object.values(errors).every((x) => x === null || x === "");

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setStatus(FORM_STATUS.SUBMITTING);
    if (isValid) {
      setIsLoading(true);
      try {
        inviteService.inviteUser(formData).then((response: any) => {
          setStatus(FORM_STATUS.COMPLETED);
          setFormData(defaultState);
          setIsLoading(false);
          handleRefreshInvites();
        });
      } catch (e) {
        //This is not the exception you are looking for
        setIsLoading(false);
      }
    } else {
      setStatus(FORM_STATUS.SUBMITTED);
    }
  };

  return (
    <>
      <Box sx={{ ml: "20px" }}>
        <Typography variant="h5" gutterBottom component="h2">
          Invite Healthcare Professionals
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          padding: "10px 40px 10px 10px",
          width: "auto",
        }}
        component="form"
        noValidate
        onSubmit={onSubmit}
        aria-labelledby="invite-hcp-form"
      >
        <FormItem>
          <TextField
            autoComplete="given-name"
            name="firstName"
            required
            fullWidth
            id="firstName"
            label="First Name"
            value={formData.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={status === FORM_STATUS.COMPLETED}
            helperText={
              (touched.firstName || status === FORM_STATUS.SUBMITTED) &&
              errors.firstName
            }
            error={
              (touched.firstName !== "" || status === FORM_STATUS.SUBMITTED) &&
              errors.firstName !== ""
            }
            FormHelperTextProps={{ style: { minHeight: "1.5em" } }}
          />
        </FormItem>
        <FormItem>
          <TextField
            required
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            autoComplete="family-name"
            value={formData.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={status === FORM_STATUS.COMPLETED}
            helperText={
              (touched.lastName || status === FORM_STATUS.SUBMITTED) &&
              errors.lastName
            }
            error={
              (touched.lastName !== "" || status === FORM_STATUS.SUBMITTED) &&
              errors.lastName !== ""
            }
            FormHelperTextProps={{ style: { minHeight: "1.5em" } }}
          />
        </FormItem>
        <FormItem>
          <TextField
            select
            id="role"
            label="Role"
            name="role"
            value={formData.role}
            onChange={handleChange}
            disabled={status === FORM_STATUS.COMPLETED}
          >
            <MenuItem value={0}>Admin</MenuItem>
            <MenuItem value={1}>User</MenuItem>
          </TextField>
        </FormItem>
        <FormItem>
          <TextField
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={formData.email}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={status === FORM_STATUS.COMPLETED}
            helperText={
              (touched.email || status === FORM_STATUS.SUBMITTED) &&
              errors.email
            }
            error={
              (touched.email !== "" || status === FORM_STATUS.SUBMITTED) &&
              errors.email !== ""
            }
            FormHelperTextProps={{ style: { minHeight: "1.5em" } }}
          />
        </FormItem>
        <SubmitButton
          type="submit"
          loading={isLoading}
          variant="contained"
          color="primary"
          disabled={status === FORM_STATUS.COMPLETED}
        >
          Submit
        </SubmitButton>
      </Box>
    </>
  );
};

export default InviteUserComponent;
