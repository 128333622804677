import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { Line } from "react-chartjs-2";
import { Chart } from "chart.js";
import { useSelector } from "react-redux";
import {
  averageWalkingSpeedData,
  dailyParameterDates,
} from "../../../../redux/selectors/dailyParametersSelectors";
import { useAppThunkDispatch } from "../../../../redux/configureStore";
import { getAverageWalkingSpeedData } from "../../../../redux/actions/dailyParameterActions";
import CircularProgress from "@mui/material/CircularProgress";
import { DateGroupType } from "../../../../model/active/dateGroupType";

interface Props {
  patientId?: string;
  selectedDateRange: [Date | null, Date | null];
}

// const colors = ['rgba(25,128,44,0.06)', 'rgba(217,11,11,0.06)',];
const colors = ["rgba(173,173,173,0.06)", "rgba(173,173,173,0.06)"];

function getDateRangeGranularity(
  selectedDateRange: [Date | null, Date | null]
): DateGroupType | null {
  if (!selectedDateRange[0] || !selectedDateRange[1]) {
    return null;
  }

  const daysBetween = Math.abs(
    Math.floor(
      (selectedDateRange[1].getTime() - selectedDateRange[0].getTime()) /
        (1000 * 60 * 60 * 24)
    )
  );

  switch (true) {
    case daysBetween >= 0 && daysBetween <= 60:
      return DateGroupType.Day;
    case daysBetween >= 61 && daysBetween <= 420:
      return DateGroupType.Week;
    case daysBetween >= 421 && daysBetween <= 1800:
      return DateGroupType.Month;
    case daysBetween >= 2700 && daysBetween <= 21900:
      return DateGroupType.Year;
    default:
      return null;
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column",
    height: "100vh",
    margin: "0px 40px 0px 0px",
    backgroundColor: "white",
  },
  content: {
    display: "flex",
    flexFlow: "column",
    backgroundColor: "white",
  },
  backButton: {
    width: "40px",
    height: "40px",
  },
  backIcon: {
    transform: "scale(1.3)",
  },
}));

export const AverageWalkingSpeedDetailContent: React.FC<Props> = ({
  patientId,
  selectedDateRange,
}) => {
  const classes = useStyles();
  const dispatch = useAppThunkDispatch();
  const selectorDates = useSelector(dailyParameterDates);
  const averageWalkingSpeed = useSelector(averageWalkingSpeedData);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);

  //To split and colorize background of chart
  useEffect(() => {
    Chart.register({
      id: "backgroundSplitter",
      beforeDraw: (chart) => {
        const ctx = chart.ctx;
        const { top, bottom, left, right } = chart.chartArea;

        const segmentHeight = (bottom - top) / colors.length;

        for (let i = 0; i < colors.length; i++) {
          ctx.fillStyle = colors[i];
          ctx.fillRect(
            left,
            top + segmentHeight * i,
            right - left,
            segmentHeight
          );
        }
      },
    });
  }, []);

  useEffect(() => {
    if (selectedDateRange[0] && selectedDateRange[1]) {
      setIsDataLoading(() => true);
      dispatch(
        getAverageWalkingSpeedData(
          patientId!,
          selectedDateRange[0],
          selectedDateRange[1],
          getDateRangeGranularity(selectedDateRange) || DateGroupType.Day
        )
      );
      setIsDataLoading(() => false);
    }
  }, [selectedDateRange, dispatch, patientId]);

  const rangeDates = useMemo(() => {
    if (selectedDateRange[0] && selectedDateRange[1]) {
      const daysBetween: Date[] = getDates(
        selectedDateRange[0],
        selectedDateRange[1]
      );
      return daysBetween;
    }
  }, [selectedDateRange]);

  const labels = useMemo(() => {
    if (rangeDates) {
      return rangeDates.map((date) => new Date(date).toLocaleDateString());
    }
  }, [rangeDates]);

  if (selectorDates.length === 0) {
    return <CircularProgress />;
  }

  const data = {
    labels,
    datasets: [
      {
        label: "Speed m/s",
        data: averageWalkingSpeed.map(({ date, value }, index) => value),
        fill: false,
        borderColor: "#28666C",
      },
    ],
  };

  const options = {
    maintainAspectRatio: true,
    elements: {
      point: {
        radius: 4,
        borderWidth: 2,
        backgroundColor: "#FFFFFF",
        borderColor: "#28666C",
      },
      line: {
        borderWidth: 1,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      backgroundSplitter: {
        colors,
      },
    },
    scales: {
      y: {
        ticks: {
          stepSize: 0.5,
          callback: (value: string, index: number, values: Array<string>) =>
            index !== 0 && index !== values.length - 1 ? `${value}m/s` : "",
        },
      },
    },
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Box sx={{ margin: "0px 40px 40px 40px" }}>
          <Typography sx={{ marginBottom: "40px" }} fontSize={13}>
            The walking speed is collected whilst the user is completing normal
            daily activities, averaged over the day. A change in walking speed
            is to be interpreted in relation to population norms, alongside
            clinical reasoning and understanding of the user and walking speed
            trends.
          </Typography>
          {isDataLoading ? (
            <Skeleton animation="wave" />
          ) : (
            <Box sx={{ position: "relative", margin: "auto", width: "80vw" }}>
              {/*// @ts-ignore */}
              <Line data={data} options={{ ...options, responsive: true }} />
            </Box>
          )}
          <Box
            sx={{
              width: "402px",
              height: "366px",
              marginBottom: "30px",
            }}
          >
            <Box
              sx={{
                border: "3px solid #28666C",
                borderRadius: "14px 14px 0 0",
                backgroundColor: "#28666C",
              }}
            >
              <Typography textAlign="center" color="white" fontWeight={700}>
                Average Walking Speed
              </Typography>
            </Box>

            <Box
              sx={{
                textAlign: "center",
                flexGrow: 1,
                border: "3px solid #28666C",
                borderRadius: "0 0 14px 14px",
                paddingTop: "15px",
                paddingBottom: "10px",
              }}
            >
              <Grid sx={{ fontWeight: "500" }} container>
                <Grid item xs={4}>
                  Decade
                </Grid>
                <Grid item xs={4}>
                  Men (m/s)
                </Grid>
                <Grid item xs={4}>
                  Women (m/s)
                </Grid>
              </Grid>
              <div className="averageSpeedBox">
                <AverageSpeedItems />
              </div>
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
};
function AverageSpeedItems() {
  const averageSpeedDataMock = [
    {
      decade: "20s",
      men: "1.358",
      women: "1.341",
    },
    {
      decade: "30s",
      men: "1.433",
      women: "1.337",
    },
    {
      decade: "40s",
      men: "1.434",
      women: "1.39",
    },
    {
      decade: "50s",
      men: "1.433",
      women: "1.313",
    },
    {
      decade: "60s",
      men: "1.339",
      women: "1.241",
    },
    {
      decade: "70s",
      men: "1.262",
      women: "1.132",
    },
    {
      decade: ">80s",
      men: "0.969",
      women: "0.943",
    },
  ];

  return (
    <>
      {averageSpeedDataMock.map(({ decade, men, women }, index) => {
        const styles = {
          backgroundColor: index % 2 === 0 ? "#F1F3F6" : "inherit",
          color: "#979797",
          display: "flex",
          justifyContent: "space-around",
          margin: "10px 0",
        };

        const itemStyle = {
          width: "33.333%",
          padding: "7px 0",
        };

        return (
          <div style={styles} className="decadesItem" key={decade}>
            <div style={itemStyle}>{decade}</div>
            <div style={itemStyle}>{men}</div>
            <div style={itemStyle}>{women}</div>
          </div>
        );
      })}
    </>
  );
}

function getDates(startDate: Date, endDate: Date): Date[] {
  let dates = [];
  let currentDate: Date = startDate;
  while (currentDate <= endDate) {
    dates.push(currentDate);
    currentDate = addDays(currentDate);
  }
  return dates;
}

function addDays(currentDate: Date): Date {
  const date = new Date(currentDate);
  date.setDate(date.getDate() + 1);
  return date;
}

export default AverageWalkingSpeedDetailContent;
