import { styled } from "@mui/material/styles";
import { shouldForwardProp } from "../../helpers/utilityFunctions/utilityFunctions";

type IHeatmapOverlayStyleProps = {
  height: number;
  width: number;
  image: string;
};

const HeatmapOverlay = styled("div", {
  shouldForwardProp: (prop) =>
    shouldForwardProp<IHeatmapOverlayStyleProps>(
      ["height", "width", "image"],
      prop
    ),
})<IHeatmapOverlayStyleProps>(({ height, width, image }) => ({
  position: "absolute",
  width: width,
  height: height,
  backgroundImage: `url(${image})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
}));

export default HeatmapOverlay;
