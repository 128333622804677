import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Box, Paper, TableSortLabel, Tooltip, Typography } from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import NoData from "../../../components/NoData";
import PathCircularProgress from "../../../components/PathCircularProgress";
import accountService from "../../../services/AccountService";
import { ThreeDotsMenu } from "../../../components/documented/ThreeDotsMenu";
import { selectAccountId } from "../../../redux/selectors/accountSelectors";
import { useAppThunkDispatch } from "../../../redux/configureStore";
import { selectPatientAccounts } from "../../../redux/selectors/adminSelectors";
import { getAllHcpAccounts } from "../../../redux/actions/adminActions";
import { Role } from "../../../model/account/role";
import { PathAccount } from "../../../model/account/pathAccount";
import {
  getComparator,
  Order,
  StyledTableCell,
  StyledTableRow,
} from "../TableComponents";

const TableDetailContainer = styled(Box)(() => ({
  boxSizing: "border-box",
  height: "fit-content",
  width: "100%",
  padding: "10px 40px 10px 20px",
}));

export interface HeadCell {
  id: keyof PathAccount;
  label: string;
  numeric: boolean;
  toolTip?: string;
}

export interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof PathAccount
  ) => void;
  order: Order;
  orderBy: string;
}

function PatientAccountsTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof PathAccount) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const headCells: readonly HeadCell[] = [
    {
      id: "accountGuid",
      numeric: false,
      label: "AccountId",
    },
    {
      id: "created",
      numeric: false,
      label: "User Since",
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <Tooltip title={headCell.toolTip || ""}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </Tooltip>
          </StyledTableCell>
        ))}
        <StyledTableCell align="left">User Actions</StyledTableCell>
      </TableRow>
    </TableHead>
  );
}

interface Props {
  isLoading: boolean;
}

export const PatientAccountsComponent: React.FC<Props> = ({ isLoading }) => {
  const accounts = useSelector(selectPatientAccounts);
  const accountId = useSelector(selectAccountId);
  const dispatch = useAppThunkDispatch();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof PathAccount>("lastName");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof PathAccount
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function handleDeleteAccount(accountGuid: string) {
    try {
      accountService
        .deleteAccount(accountGuid)
        .then((response: any) => {
          dispatch(getAllHcpAccounts());
        })
        .then((response: any) => {});
    } catch (error: any) {
      //hmmm
    }
  }

  return (
    <TableDetailContainer>
      <Typography
        sx={{ padding: "5px 0px 5px 0px" }}
        variant="h5"
        gutterBottom
        component="h2"
      >
        Patients Accounts
      </Typography>
      {!isLoading ? (
        accounts.length ? (
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer sx={{ maxHeight: 490, minWidth: 750 }}>
              <Table stickyHeader aria-labelledby="patient-accounts-table">
                <PatientAccountsTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {accounts
                    .slice()
                    .sort(getComparator(order, orderBy))
                    .map((account) => {
                      return (
                        <StyledTableRow
                          hover
                          tabIndex={-1}
                          key={account.accountGuid}
                        >
                          <StyledTableCell align="left">
                            {account.accountGuid}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {new Date(account.created).toDateString()}
                          </StyledTableCell>
                          <StyledTableCell>
                            {
                              //cant delete self or super admin account type
                              account.accountGuid !== accountId &&
                              account.role !== Role.SuperAdmin ? (
                                <ThreeDotsMenu
                                  data={account.accountGuid}
                                  handleAction={handleDeleteAccount}
                                />
                              ) : null
                            }
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        ) : (
          <NoData message="You have no patient accounts to manage right now" />
        )
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          height="100px"
          justifyContent="center"
          data-testid="loading-indicator"
        >
          <PathCircularProgress />
        </Box>
      )}
    </TableDetailContainer>
  );
};

export default PatientAccountsComponent;
