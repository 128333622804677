import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Box, CardContent } from "@mui/material";
import { ParameterCard } from "../../../documented/ParameterCard";
import { AverageAsymmetryCardData } from "./averageAsymmetryCardData";
import IconLoader from "../../../IconLoader";
import { isEmpty } from "../../../../helpers/utilityFunctions/utilityFunctions";

interface AverageAsymmetryCardProps {
  data?: AverageAsymmetryCardData;
  onClick: (arg: string) => void;
}

const CardDetailTypography = styled(Typography)(() => ({
  fontSize: 14,
  padding: "0px 0px 0px 16px !important",
}));

const AverageAsymmetryCardMedia = styled(CardContent)(() => ({
  display: "flex !important",
  flexDirection: "column",
  flexGrow: "1 !important",
}));

const PanelDisplayBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
}));

const AverageAsymmetryCard: React.FC<AverageAsymmetryCardProps> = ({
  data,
  onClick,
}: AverageAsymmetryCardProps) => {
  return (
    <ParameterCard
      title="Average Asymmetry"
      iconName="AverageAsymmetry"
      popoverContent="A percentage close to 0 indicates perfectly symmetrical gait. Any value deviating away from this is indicative of an increasingly asymmetrical gait.*
      *some asymmetry is normal. Studies suggest that a 10% asymmetry or greater may be a cause for concern."
      onClick={() => onClick("_10MWT")}
      enabled={!isEmpty(data)}
    >
      <CardDetailTypography>
        This measures the level of difference between the left and right sides
        of the body as they walk
      </CardDetailTypography>
      <AverageAsymmetryCardMedia>
        <PanelDisplayBox>
          <IconLoader name="DailyParameterCardFeetSymmetry" />
          <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
            {data ? data.value : "0"}
            {data ? data.unit : "%"}
          </Typography>
        </PanelDisplayBox>
      </AverageAsymmetryCardMedia>
    </ParameterCard>
  );
};

export default AverageAsymmetryCard;
