import { v4 as uuidv4 } from "uuid";

import { ReactComponent as WalkWithPathLogo } from "../assets/svg/walkWithPathLogo.svg";
import { ReactComponent as PatientsIcon } from "../assets/icons/patients.svg";
import { ReactComponent as AdminMan } from "../assets/icons/adminman.svg";
import { ReactComponent as SupportIcon } from "../assets/icons/support.svg";
import { ReactComponent as _3DPathLength } from "../assets/icons/3dPathLength.svg";
import { ReactComponent as Cadence } from "../assets/icons/cadance.svg";
import { ReactComponent as CycleDuration } from "../assets/icons/cycleDuration.svg";
import { ReactComponent as DoubleSupport } from "../assets/icons/doubleSupport.svg";
import { ReactComponent as FootFlat } from "../assets/icons/footFlat.svg";
import { ReactComponent as LiftOffAngle } from "../assets/icons/liftOffAngle.svg";
import { ReactComponent as Loading } from "../assets/icons/loading.svg";
import { ReactComponent as MaxHeel } from "../assets/icons/maxHeel.svg";
import { ReactComponent as MaxToe1 } from "../assets/icons/maxToe1.svg";
import { ReactComponent as MaxToe2 } from "../assets/icons/maxToe2.svg";
import { ReactComponent as MinToe } from "../assets/icons/minToe.svg";
import { ReactComponent as PeakAngleVelocity } from "../assets/icons/peakAngVelocity.svg";
//Pushing icon is missing from design file - using Stance as a replacement for now
import { ReactComponent as Pushing } from "../assets/icons/stance.svg";
import { ReactComponent as Stance } from "../assets/icons/stance.svg";
import { ReactComponent as StepLength } from "../assets/icons/stepLength.svg";
import { ReactComponent as StrideLength } from "../assets/icons/strideLength.svg";
import { ReactComponent as StrideVelocity } from "../assets/icons/strideVelocity.svg";
import { ReactComponent as StrikeAngle } from "../assets/icons/strikeAngle.svg";
import { ReactComponent as Swing } from "../assets/icons/swing.svg";
import { ReactComponent as SwingSpeed } from "../assets/icons/swingSpeed.svg";
import { ReactComponent as TurningAngle } from "../assets/icons/turningAngle.svg";

//daily metrics
import { ReactComponent as TemperatureDistribution } from "../assets/icons/dailyMetrics/temperature.svg";
import { ReactComponent as PressureDistribution } from "../assets/icons/dailyMetrics/pressure.svg";
import { ReactComponent as TemperatureDistributionHeatmap } from "../assets/icons/dailyMetrics/temperatureHeatmap.svg";
import { ReactComponent as PressureDistributionHeatmap } from "../assets/icons/dailyMetrics/pressureHeatmap.svg";
import { ReactComponent as AlertGreenFeet } from "../assets/icons/dailyMetrics/alertGreenFeet.svg";
import { ReactComponent as DailyParameters } from "../assets/icons/dailyMetrics/dailyParameters.svg";
import { ReactComponent as AverageCadence } from "../assets/icons/dailyMetrics/cadence.svg";
import { ReactComponent as AverageSymmetry } from "../assets/icons/dailyMetrics/symmetry.svg";
import { ReactComponent as AverageWalkingSpeed } from "../assets/icons/dailyMetrics/walkingSpeed.svg";
import { ReactComponent as AverageStrideLength } from "../assets/icons/dailyMetrics/strideLength.svg";
import { ReactComponent as ActivityTracker } from "../assets/icons/dailyMetrics/activityTracker.svg";

//dailyParameters
import { ReactComponent as TotalDistanceCardFeet } from "../assets/icons/dailyParameters/totalDistanceCardFeet.svg";
import { ReactComponent as TotalStepsCardFeet } from "../assets/icons/dailyParameters/totalStepsCardFeet.svg";
import { ReactComponent as DailyParameterCardFeetSymmetry } from "../assets/icons/dailyParameters/dailyParameterCardFeet_symmetry.svg";
import { ReactComponent as AverageCycleDurationBackground } from "../assets/icons/dailyParameters/averageCycleDurationBackground.svg";
import { ReactComponent as AverageStrideAngles } from "../assets/icons/dailyParameters/averageStrideAngles.svg";
import { ReactComponent as TotalDistance } from "../assets/icons/dailyParameters/totalDistance.svg";
import { ReactComponent as AverageWalkingSpeedParam } from "../assets/icons/dailyParameters/averageWalkingSpeed.svg";
import { ReactComponent as AverageAsymmetry } from "../assets/icons/dailyParameters/averageAsymmetry.svg";
import { ReactComponent as TotalSteps } from "../assets/icons/dailyParameters/totalSteps.svg";
import { ReactComponent as AverageCycleDuration } from "../assets/icons/dailyParameters/averageCycleDuration.svg";
import { ReactComponent as AverageSwingSpeed } from "../assets/icons/dailyParameters/averageSwingSpeed.svg";
import { ReactComponent as AveragePeakAngleVelocity } from "../assets/icons/dailyParameters/averagePeakAngleVelocity.svg";
import { ReactComponent as AverageSwingAverageStance } from "../assets/icons/dailyParameters/averageSwingAverageStance.svg";

//active tests
import { ReactComponent as TENMWT } from "../assets/icons/activeTests/10MWT.svg";
import { ReactComponent as TUGT } from "../assets/icons/activeTests/TUGT.svg";

//general
import { ReactComponent as WarningIcon } from "../assets/icons/warning.svg";
import { ReactComponent as NoPatients } from "../assets/icons/noPatients.svg";

const Icons = {
  WalkWithPathLogo: WalkWithPathLogo,
  PatientsIcon: PatientsIcon,
  AdminMan: AdminMan,
  SupportIcon: SupportIcon,
  "3D Path Length": _3DPathLength,
  "Cycle Duration": CycleDuration,
  Cadence: Cadence,
  "Double Support": DoubleSupport,
  "Foot-Flat": FootFlat,
  "Lift-off Angle": LiftOffAngle,
  Loading: Loading,
  "Max. Heel": MaxHeel,
  "Max. Toe 1": MaxToe1,
  "Max. Toe 2": MaxToe2,
  "Min. Toe": MinToe,
  "Peak Ang. Velocity": PeakAngleVelocity,
  Pushing: Pushing,
  Stance: Stance,
  "Step Length": StepLength,
  "Stride Length": StrideLength,
  "Stride Velocity": StrideVelocity,
  "Strike Angle": StrikeAngle,
  Swing: Swing,
  "Swing Speed": SwingSpeed,
  "Turning Angle": TurningAngle,
  Temperature: TemperatureDistribution,
  Pressure: PressureDistribution,
  TemperatureHeatmap: TemperatureDistributionHeatmap,
  PressureHeatmap: PressureDistributionHeatmap,
  AlertGreenFeet: AlertGreenFeet,
  _10MWT: TENMWT,
  _TUGT: TUGT,
  WarningIcon: WarningIcon,
  NoPatients: NoPatients,
  DailyParameters: DailyParameters,
  AverageCadence: AverageCadence,
  AverageSymmetry: AverageSymmetry,
  AverageWalkingSpeed: AverageWalkingSpeed,
  AverageStrideLength: AverageStrideLength,
  AverageStrideAngles: AverageStrideAngles,
  TotalDistance: TotalDistance,
  AverageWalkingSpeedParam: AverageWalkingSpeedParam,
  AverageAsymmetry: AverageAsymmetry,
  TotalSteps: TotalSteps,
  AverageCycleDuration: AverageCycleDuration,
  AverageSwingSpeed: AverageSwingSpeed,
  AveragePeakAngleVelocity: AveragePeakAngleVelocity,
  AverageSwingAverageStance: AverageSwingAverageStance,
  TotalDistanceCardFeet: TotalDistanceCardFeet,
  TotalStepsCardFeet: TotalStepsCardFeet,
  DailyParameterCardFeetSymmetry: DailyParameterCardFeetSymmetry,
  AverageCycleDurationBackground: AverageCycleDurationBackground,
  ActivityTracker: ActivityTracker,
};

const IconLoader = (props) => {
  const IconComponent = Icons[props.name];

  if (!IconComponent) {
    // Return a default one
    return null;
  }

  return (
    <div id={uuidv4()}>
      <IconComponent
        fill={props.fill ? props.fill : "current"}
        stroke={props.stroke ? props.stroke : "current"}
      />
    </div>
  );
};

export default IconLoader;
