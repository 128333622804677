import {
  FETCH_HCP_ACCOUNTS,
  FETCH_HCP_ACCOUNTS_SUCCESS,
  FETCH_HCP_ACCOUNTS_FAILURE,
  FETCH_PATIENT_ACCOUNTS,
  FETCH_PATIENT_ACCOUNTS_SUCCESS,
  FETCH_PATIENT_ACCOUNTS_FAILURE,
  CHANGE_ACCOUNT_ROLE,
  CLEAR_ACCOUNTS,
  FETCH_INVITES,
  FETCH_INVITES_SUCCESS,
  FETCH_INVITES_FAILURE,
  TOGGLE_ACCOUNT_2FA_ENABLED,
  TOGGLE_ACCOUNT_2FA_ENABLED_SUCCESS,
} from "../constants";
import { AdminActionTypes } from "../types/adminActionTypes";
import { Dispatch } from "redux";
import accountService from "../../services/AccountService";
import { NewRole } from "../../model/admin/newRole";
import { inviteService } from "../../services/inviteService";
import { ToggleAccount2fa } from "../../model/admin/toggleAccount2fa";

export function fetchHcpAccounts(): AdminActionTypes {
  return {
    type: FETCH_HCP_ACCOUNTS,
  };
}

export function fetchHcpAccountsSuccess(data: any) {
  return {
    type: FETCH_HCP_ACCOUNTS_SUCCESS,
    payload: data,
  };
}

export function fetchHcpAccountsError(error: string): AdminActionTypes {
  return {
    type: FETCH_HCP_ACCOUNTS_FAILURE,
    payload: error,
  };
}

export function getAllHcpAccounts() {
  return function action(dispatch: Dispatch) {
    dispatch(fetchHcpAccounts());
    return accountService.getAllHCPs().then(
      (response: any) => dispatch(fetchHcpAccountsSuccess(response))
      //error => dispatch(someerror('woody', buzz, error))
    );
  };
}

export function fetchPatientAccounts(): AdminActionTypes {
  return {
    type: FETCH_PATIENT_ACCOUNTS,
  };
}

export function fetchPatientAccountsSuccess(data: any) {
  return {
    type: FETCH_PATIENT_ACCOUNTS_SUCCESS,
    payload: data,
  };
}

export function fetchPatientAccountsError(error: string): AdminActionTypes {
  return {
    type: FETCH_PATIENT_ACCOUNTS_FAILURE,
    payload: error,
  };
}

export function getAllPatientAccounts() {
  return function action(dispatch: Dispatch) {
    dispatch(fetchPatientAccounts());
    return accountService.getAllPatients().then(
      (response: any) => dispatch(fetchPatientAccountsSuccess(response))
      //error => dispatch(someerror('woody', buzz, error))
    );
  };
}

export function changeAccountRole(data: NewRole): AdminActionTypes {
  return {
    type: CHANGE_ACCOUNT_ROLE,
    payload: data,
  };
}

export function clearAccounts(): AdminActionTypes {
  return {
    type: CLEAR_ACCOUNTS,
  };
}

export function fetchInvites(): AdminActionTypes {
  return {
    type: FETCH_INVITES,
  };
}

export function fetchInvitesSuccess(data: any) {
  return {
    type: FETCH_INVITES_SUCCESS,
    payload: data,
  };
}

export function fetchInvitesError(error: string): AdminActionTypes {
  return {
    type: FETCH_INVITES_FAILURE,
    payload: error,
  };
}

export function getActiveInvites() {
  return function action(dispatch: Dispatch) {
    dispatch(fetchInvites());
    return inviteService.getAllActive().then(
      (response: any) => dispatch(fetchInvitesSuccess(response))
      //error => dispatch(someerror('woody', buzz, error))
    );
  };
}

export function toggleUser2faEnabled(): AdminActionTypes {
  return {
    type: TOGGLE_ACCOUNT_2FA_ENABLED,
  };
}

export function toggleUser2faEnabledSuccess(
  data: ToggleAccount2fa
): AdminActionTypes {
  return {
    type: TOGGLE_ACCOUNT_2FA_ENABLED_SUCCESS,
    payload: data,
  };
}

export function toggleUser2fa(accountId: string, isEnabled: boolean) {
  return async function action(dispatch: Dispatch) {
    dispatch(toggleUser2faEnabled());
    if (isEnabled) {
      return accountService.enableTwoFactorAdmin(accountId).then(
        (response: any) => {
          const data: ToggleAccount2fa = {
            accountGuid: accountId,
            isEnabled: true,
          };
          dispatch(toggleUser2faEnabledSuccess(data));
        }
        //error => dispatch(someerror('woody', buzz, error))
      );
    }
    return accountService.disableTwoFactor(accountId).then(
      (response: any) => {
        const data: ToggleAccount2fa = {
          accountGuid: accountId,
          isEnabled: false,
        };
        dispatch(toggleUser2faEnabledSuccess(data));
      }
      //error => dispatch(someerror('woody', buzz, error))
    );
  };
}
