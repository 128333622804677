import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  AppBar,
  Toolbar,
  Drawer,
  ListItemText,
  ListItemIcon,
  Box,
  List,
  ListItem,
  Theme,
  Typography,
} from "@mui/material";
import Routes from "../DashboardRoutes";
import IconLoader from "../../../components/IconLoader";
import AccountPanel from "../../../components/Account/AccountPanel";
import Restricted from "../../../components/Permissions/Restricted";
import { useClientTheme } from "../../../helpers/hooks/useClientTheme";

const drawerWidth = 200;

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    color: "#707070",
  },
  selected: {
    borderRight: `5px solid ${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.main} !important`,
  },
  textSelected: {
    color: theme.palette.primary.main,
  },
  footer: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    height: 60,
    textAlign: "center",
  },
}));

const NavigationBar: React.FC = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const activeRoute = (routeName: any) => {
    return pathname.includes(routeName) ? true : false;
  };
  const { isDefaultTheme, theme } = useClientTheme();
  const LogoComponent = theme.images.mainLogo;

  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "row",
          //if bringing the SearchBar back, change below to space-between
          justifyContent: "flex-end",
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
        }}
      >
        {/*         <SearchBar />
         */}
        <Toolbar>
          <AccountPanel />
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            borderRight: "0px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: drawerWidth - 10,
            ml: "10px",
            mt: "5px",
          }}
        >
          <LogoComponent />
        </Box>
        <Box>
          <List>
            {Routes.map((prop, key) => {
              return (
                <Restricted
                  key={key}
                  to={prop.permissions}
                  fallback={undefined}
                >
                  <ListItem
                    classes={{ selected: classes.selected }}
                    sx={{
                      mt: 3,
                    }}
                    button
                    key={key}
                    component={NavLink}
                    to={prop.path}
                    selected={activeRoute(prop.path)}
                  >
                    <ListItemIcon>
                      <IconLoader
                        name={prop.icon}
                        fill={
                          activeRoute(prop.path)
                            ? `${theme.palette.primary.main}`
                            : "#707070"
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.textSelected }}
                      primary={prop.sidebarName}
                    />
                  </ListItem>
                </Restricted>
              );
            })}
          </List>
        </Box>
        <Box
          sx={{
            mt: `auto`,
            ml: 2,
            mb: 3,
          }}
        >
          {isDefaultTheme ? null : (
            <Typography
              sx={{
                mb: 1,
              }}
            >
              Powered By
            </Typography>
          )}

          <IconLoader name="WalkWithPathLogo" />
        </Box>
      </Drawer>
    </>
  );
};

export default NavigationBar;
