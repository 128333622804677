import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { capitaliseFirstLetter } from "../../../helpers/utilityFunctions/utilityFunctions";

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  details: {
    textAlign: "left",
  },
}));

interface Props {
  patientId?: string;
  alertType: string;
}

export const AlertDetailHeader: React.FC<Props> = ({
  patientId,
  alertType,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.details}>
        <Typography variant="h5" component="h4" sx={{ mt: 1, mb: 1, pr: 2 }}>
          <strong>{capitaliseFirstLetter(alertType)} Distribution</strong>
        </Typography>
      </div>
    </div>
  );
};

export default AlertDetailHeader;
