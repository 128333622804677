import "./App.css";

import React, { FC, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { Theme } from "@mui/material/styles";

import ForgotPasswordComponent from "./components/Account/ForgotPasswordComponent";
import RedeemInviteComponent from "./components/Account/RedeemInviteComponent";
import ResetPasswordComponent from "./components/Account/ResetPasswordComponent";
import VerifyEmailComponent from "./components/Account/VerifyEmailComponent";
import ErrorDialog from "./components/ErrorHandling/ErrorDialog";
import LoginComponent from "./components/Login/LoginComponent";
import PermissionProvider from "./components/Permissions/PermissionProvider";
import DashboardPage from "./pages/DashboardPage";
import AddPatientPage from "./pages/DashboardPage/AddPatientPage";
import AdminPanelPage from "./pages/DashboardPage/AdminPanelPage";
import AlertDetail from "./pages/DashboardPage/AlertDetail";
import AverageWalkingSpeedDetail from "./pages/DashboardPage/DailyParameters/AverageWalkingSpeedDetail";
import DailyParametersHomePage from "./pages/DashboardPage/DailyParameters/DailyParametersHome";
import PatientDetail from "./pages/DashboardPage/PatientDetailPage";
import PatientsPage from "./pages/DashboardPage/PatientsPage";
import SettingsPage from "./pages/DashboardPage/SettingsPage";
import SupportPage from "./pages/DashboardPage/SupportPage";
import TestDetail from "./pages/DashboardPage/TestDetail";
import TenMetreWalkDetail from "./pages/DashboardPage/TestDetail/TenMetreWalkDetail";
import HomePage from "./pages/HomePage";
import NotFound from "./pages/NotFound";
import { PrivateRoute } from "./PrivateRoute";
import { Role } from "./model/account/role";
import { Temperature } from "./model/temperature";
import { useClientTheme } from "./helpers/hooks/useClientTheme";
import { ToastProvider } from "./components/ToastList/ToastContext";
import { logout } from "./helpers/api/api";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {
    clientId?: string;
    productNames: {
      clientName: string;
      clientCode: string;
      insoles: string;
      dashboard: string;
      app: string;
    };
    images: {
      mainLogo?: any;
    };
    palette: {
      alertLevel1: {
        main: string;
        contrastText: string;
      };
      alertLevel2: {
        main: string;
        contrastText: string;
      };
      alertLevel3: {
        main: string;
        contrastText: string;
      };
    };
    metrics: {
      temperature: {
        unit: Temperature;
        symbol: string;
      };
    };
  }
}
const App: FC = () => {
  const { theme } = useClientTheme();

  useEffect(() => {
    document.title = `${theme.productNames.dashboard}: Dashboard`;
  });

  return (
    <ThemeProvider theme={theme}>
      <PermissionProvider>
        <ToastProvider>
          <div className="App">
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<HomePage />}>
                  <Route index element={<LoginComponent />} />
                  <Route
                    path="/invite/redeem"
                    element={<RedeemInviteComponent />}
                  />
                  <Route
                    path="/account/verify-email"
                    element={<VerifyEmailComponent />}
                  />
                  <Route
                    path="forgot-password"
                    element={<ForgotPasswordComponent />}
                  />
                  <Route
                    path="/account/reset-password"
                    element={<ResetPasswordComponent />}
                  />
                </Route>

                <Route
                  path="dashboard"
                  element={
                    <PrivateRoute
                      roles={[Role.User, Role.Admin, Role.SuperAdmin]}
                      component={DashboardPage}
                    />
                  }
                >
                  <Route path="patients" element={<PatientsPage />} />
                  <Route path="patients/add" element={<AddPatientPage />} />
                  <Route
                    path="patients/:patientId"
                    element={<PatientDetail />}
                  />
                  <Route
                    path="patients/:patientId/passive/:alertType"
                    element={<AlertDetail />}
                  />
                  <Route
                    path="patients/:patientId/active/:testType"
                    element={<TestDetail />}
                  />
                  <Route
                    path="patients/active/:testType/detail"
                    element={<TenMetreWalkDetail />}
                  />
                  <Route
                    path="patients/:patientId/dailyparameters"
                    element={<DailyParametersHomePage />}
                  />
                  <Route
                    path="patients/:patientId/dailyparameters/AverageWalkingSpeed"
                    element={<AverageWalkingSpeedDetail />}
                  ></Route>
                  <Route
                    path="admin"
                    element={
                      <PrivateRoute
                        roles={[Role.Admin, Role.SuperAdmin]}
                        component={AdminPanelPage}
                      />
                    }
                  />
                  <Route path="support" element={<SupportPage />} />
                  <Route path="settings" element={<SettingsPage />} />
                </Route>
                <Route path="*" element={<NotFound />} />
              </Routes>
            </BrowserRouter>
            <ErrorDialog handleClose={() => logout()} />
          </div>
        </ToastProvider>
      </PermissionProvider>
    </ThemeProvider>
  );
};

export default App;
